/* eslint-disable*/
import { REQUEST_URL } from "../app/constants";
import { RequestUtil } from "../utils";

export const AuthService = {
  signIn: (data: { code: string; password: string }) => {
    const url = REQUEST_URL.LOGIN;
    return RequestUtil.post({ url, data });
  },

  authProfile: () => {
    const url = REQUEST_URL.AUTH_PROFILE;
    return RequestUtil.get({ url });
  },

  grantNewPassword: (data: { email: any }) => {
    const url = REQUEST_URL.RESET_PASSWORD;
    return RequestUtil.put({ url, data });
  },

  onLogout: (data: { authorization: string | undefined }) => {
    const url = REQUEST_URL.LOG_OUT;
    return RequestUtil.post({ url, data });
  },

  destroyToken: (data: { authorization: string | undefined }) => {
    const url = REQUEST_URL.DESTROY_TOKEN;
    return RequestUtil.post({ url, data });
  },

  changePassword: (data: { password: string; oldPassword: string }) => {
    const url = REQUEST_URL.CHANGE_PASSWORD;
    return RequestUtil.post({ url, data });
  },

  forgotPassword: (data: { email: string }) => {
    const url = REQUEST_URL.FORGOT_PASSWORD;
    return RequestUtil.put({ url, data });
  },

  verifyForgotPassword: (data: { token: string; otp: string }) => {
    const url = REQUEST_URL.VERIFY_FORGOT_PASSWORD;
    return RequestUtil.put({ url, data });
  },

  updatePassword: (data: { token: string; password: string }) => {
    const url = REQUEST_URL.UPDATE_PASSWORD;
    return RequestUtil.put({ url, data });
  },

  getTime: () => {
    const url = REQUEST_URL.GET_TIME;
    return RequestUtil.get({ url });
  },

  setTime: (data: { minute: number; hour: number }) => {
    const url = REQUEST_URL.SET_TIME;
    return RequestUtil.post({ url, data });
  },
};
