/* eslint-disable */
import { ClearRounded as ClearRoundedIcon } from "@mui/icons-material";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import Loading from "../app/components/Loading";
import NotificationModal from "../app/components/NotificationModal";
import { APP_ROUTES } from "../app/constants/module";
import { ErrorMessage } from "../models";
import { AuthService } from "../services";

export function withProfile<T>(Component: any) {
  return (props: Omit<T, "profile">) => {
    const [profile, setProfile] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [checkActivated, setCheckActivated] = useState<{
      check: boolean;
      message: string;
    }>({
      check: false,
      message: "",
    });

    useEffect(() => {
      const fetchData = async () => {
        setLoading(true);
        await AuthService.authProfile()
          .then((res) => {
            if ((res && res.status === 200) || res.status === 204) {
              setProfile(res.data.data);
            } else if (res && res.status === 401) {
              if (res?.data.message === ErrorMessage.SESSION_EXPIRED) {
                setCheckActivated({ check: true, message: "Phiên đăng nhập đã hết hạn" });
              } else if (res?.data.message === ErrorMessage.TOKEN_DOES_NOT_MATCH_ANY_SESSION) {
                setCheckActivated({ check: true, message: "Đăng nhập lại" });
              } else if (res?.data.message === ErrorMessage.TOKEN_IS_NO_LONGER_AVAILABLE) {
                setCheckActivated({ check: true, message: "Phiên đăng nhập đã hết hạn" });
              } else if (res?.data.message === ErrorMessage.INVALID_TOKEN) {
                setCheckActivated({ check: true, message: "Phiên đăng nhập đã hết hạn" });
              } else if (res?.data.message === ErrorMessage.ACCOUNT_HAS_BEEN_BLOCKED_OR_DELETED) {
                setCheckActivated({ check: true, message: "Tài khoản đã bị vô hiệu hóa" });
              } else if (res?.data.message === ErrorMessage.ACCOUNT_IS_PENDING_APPROVAL) {
                setCheckActivated({ check: true, message: "Tài khoản đang chờ duyệt" });
              } else if (res?.data.message === ErrorMessage.UNAUTHORIZED) {
                setCheckActivated({ check: true, message: "Chưa đăng nhập tài khoản" });
              } else {
                setCheckActivated({ check: true, message: "Phiên đăng nhập đã hết hạn" });
              }
            }
          })
          .catch((err: AxiosError) => {
            console.log(err);
          });
        setLoading(false);
      };
      fetchData();
    }, []);

    const reSignIn = () => {
      localStorage.clear();
      window.location.href = `${APP_ROUTES.LOGIN}`;
    };

    return (
      <>
        {!loading ? (
          profile !== null ? (
            <Component {...props} profile={profile} />
          ) : (
            <NotificationModal
              visible={checkActivated.check}
              title={checkActivated.message}
              subTitle={<span>Quý khách vui lòng đăng nhập lại</span>}
              icon={
                <div className={`notification-icon-box red`}>
                  <ClearRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
                </div>
              }
              actions={
                <button
                  className="btn-confirm-style"
                  onClick={() => {
                    reSignIn();
                    setCheckActivated({ check: false, message: "" });
                  }}>
                  Đóng
                </button>
              }
              onClose={() => {
                reSignIn();
                setCheckActivated({ check: false, message: "" });
              }}
            />
          )
        ) : (
          <Loading style={{ margin: "2rem 0", height: "100vh" }} />
        )}
      </>
    );
  };
}
