import { Popover } from "@mui/material";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AuthService } from "../../../../services";
import { loadState, RequestUtil } from "../../../../utils";
import { checkLinkImage, renderErrorImage } from "../../../../utils/other.util";
import { LogoutIcon } from "../../../components/Icons/LogoutIcon";
import { SettingsIcon } from "../../../components/Icons/SettingsIcon";
import { UserIcon } from "../../../components/Icons/UserIcon";
import { APP_ROUTES } from "../../../constants/module";
import { TEXT_HOLDER } from "../../../constants/utilities";
import { useAppDispatch, useAppSelector } from "../../../core/store/hook";
import { LoadMe, loadProfile } from "../../../core/store/profile";
import "./index.scss";

type P_Props = {
  onClose: () => void;
  anchorEl: HTMLElement | null;
  /*eslint-disable-next-line */
  avatarImg: string;
};
const SignOut: React.FC<P_Props> = (props) => {
  const history = useHistory();
  const token = loadState();
  const dispatchProfile = useAppDispatch();
  const { data: profile } = useAppSelector(LoadMe);

  useEffect(() => {
    dispatchProfile(loadProfile());
  }, [dispatchProfile]);

  const deActiveToken = async () => {
    await AuthService.onLogout({
      authorization: `Bearer ${token}`,
    });
  };

  const onLogout = () => {
    if (token) {
      deActiveToken();
      localStorage.removeItem("accessToken");
      RequestUtil.removeAuthHeader();
    }

    window.location.href = `${APP_ROUTES.LOGIN}`;
  };

  return (
    <Popover
      open={Boolean(props.anchorEl)}
      anchorEl={props.anchorEl}
      onClose={props.onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}>
      <div className="app-toolbar-right-profile">
        <div className="name">
          <img
            src={checkLinkImage(profile?.avatar as string) || props.avatarImg}
            alt="avt"
            onError={renderErrorImage("avatar")}
          />
          <div>
            {profile?.name || TEXT_HOLDER}
            <div className="email">{profile?.email || TEXT_HOLDER}</div>
          </div>
        </div>
        <div className="menu-list">
          <button
            onClick={() => {
              history.push(APP_ROUTES.PROFILE);
              props.onClose();
            }}>
            <UserIcon color={"#200E32"} size={[28, 28]} viewBox={[28, 18]} />
            Hồ sơ cá nhân
          </button>
          <button
            onClick={() => {
              history.push(APP_ROUTES.PROFILE_REPORT);
              props.onClose();
            }}>
            <SettingsIcon color={"#200E32"} size={[28, 28]} viewBox={[28, 18]} />
            Cài đặt gửi báo cáo
          </button>
          <button onClick={onLogout}>
            <LogoutIcon color={"#200E32"} size={[28, 28]} viewBox={[28, 18]} />
            Đăng xuất
          </button>
        </div>
      </div>
    </Popover>
  );
};

export default SignOut;
