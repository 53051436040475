import { REQUEST_URL } from "../app/constants";
import { PaginationQueryModel } from "../models";
import { RequestUtil } from "../utils";

export const OPCTagService = {
  getAllOPCTag: (
    query: PaginationQueryModel & {
      searchString?: string;
      code?: string;
    }
  ) => {
    const url = REQUEST_URL.OPC_TAG;
    return RequestUtil.get({ url, query: query });
  },
};
