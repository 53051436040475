import { IconBase, IconComponent } from "../../core/Icons";

export const LogoutIcon: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <path
        d="M8 14.5H5.33333C4.97971 14.5 4.64057 14.3595 4.39052 14.1095C4.14048 13.8594 4 13.5203 4 13.1667V3.83333C4 3.47971 4.14048 3.14057 4.39052 2.89052C4.64057 2.64048 4.97971 2.5 5.33333 2.5H8"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.667 11.8327L16.0003 8.49935L12.667 5.16602"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 8.5H8"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconBase>
  );
};
