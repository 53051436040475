/*eslint-disable */
import { PriorityHigh as PriorityHighIcon } from "@mui/icons-material";
import { Dialog, Grid, Tooltip } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { AxiosError } from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Pig from "../../../../assets/images/pig.png";
import { useFetchWithoutPagination } from "../../../../hooks/useFetchWithoutPagination";
import useNoti from "../../../../hooks/useNoti";
import {
  AppNotifications,
  OPCTagOutPutModal,
  SiteDetailOuputModel,
  SiteStatus,
} from "../../../../models";
import { Nullable } from "../../../../models/shared";
import { DeviceService, OPCTagService } from "../../../../services";
import { SiteService } from "../../../../services/site.service";
import { theme } from "../../../../utils";
import { checkLinkImage, renderErrorImage } from "../../../../utils/other.util";
import Loading from "../../../components/Loading";
import NotificationModal from "../../../components/NotificationModal";
import SimpleModalHeader from "../../../components/SimpleModalHeader";
import { APP_ROUTES } from "../../../constants/module";
import { TEXT_HOLDER } from "../../../constants/utilities";
import SiteModal from "../SiteModal";
import "./index.scss";

const SiteDetail: React.FC = () => {
  const [pushNoti] = useNoti();
  const mounted = useRef(false);
  const history = useHistory();
  const { siteId }: { siteId: string } = useParams();
  const [siteData, setSiteData] = useState<Nullable<SiteDetailOuputModel>>(null);
  const [openSiteModal, setOpenSiteModal] = useState<boolean>(false);
  const [notiDelete, setNotiDelete] = useState<boolean>(false);
  const [tab, setTab] = useState<boolean>(true);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const { data: deviceTypeData } = useFetchWithoutPagination(DeviceService.getTypeDevice);

  const [OPCTag, setOPCTag] = useState<Nullable<OPCTagOutPutModal[]>>(null);

  const fetchDataOPCTag = async (query: any) => {
    try {
      const result = await OPCTagService.getAllOPCTag(query);
      if (mounted.current) {
        setOPCTag(result.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchDataOPCTag({
      page: 1,
      limit: 500,
    });
    // eslint-disable-next-line
  }, []);

  const fetchData: () => Promise<void> = async () => {
    try {
      const result = await SiteService.getSiteDetailById(siteId);
      if (mounted.current) {
        setSiteData(result.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const totalAreas = siteData?.areas.length;

  const deleteSite = async (id: number) => {
    await SiteService.deleteSite(id)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          pushNoti(AppNotifications.SUCCESS, "Xóa trại thành công");
          history.push(APP_ROUTES.SITE);
        } else {
          pushNoti(AppNotifications.ERROR, "Xóa trại thất bại");
        }
      })
      .catch((err: AxiosError) => {
        pushNoti(AppNotifications.ERROR, "Xóa trại thất bại");
      });
  };

  return (
    <Dialog fullScreen open={true}>
      <div className="site-detail">
        <SimpleModalHeader title={"Chi tiết trại"} onClose={() => history.push(APP_ROUTES.SITE)} />
        {!!siteData ? (
          <div className="content">
            <Grid container>
              <Grid item xs={3}>
                <div className="left">
                  <div className="left-image">
                    <img
                      src={checkLinkImage(siteData?.avatar as string) || Pig}
                      alt="avatar"
                      onError={renderErrorImage()}
                    />
                  </div>
                  <div className="name">{siteData.name || TEXT_HOLDER}</div>
                  {siteData.status === SiteStatus.OFFICIAL ? (
                    <span className="status" style={{ color: "#41AD49" }}>
                      &#9679;&ensp;Chính thức
                    </span>
                  ) : (
                    <span className="status" style={{ color: "#B8292F" }}>
                      &#9679;&ensp;Chưa chính thức
                    </span>
                  )}
                  <button className="btn-confirm-style" onClick={() => setOpenSiteModal(true)}>
                    Chỉnh sửa
                  </button>
                  <button
                    className="btn-confirm-style bord-red"
                    onClick={() => setNotiDelete(true)}>
                    Xóa trại
                  </button>
                </div>
              </Grid>
              <Grid item xs={8}>
                <div className="box" style={{ marginBottom: "1.6rem" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}>
                    <div className="title">Thông tin trại</div>
                  </div>
                  <Grid container>
                    <Grid item xs={4}>
                      <div className="label">OPC ID</div>
                      <div className="address">{siteData.code || TEXT_HOLDER}</div>
                      <div className="label">Loại vật nuôi</div>
                      {siteData.animalType?.name || TEXT_HOLDER}
                    </Grid>
                    <Grid item xs={4}>
                      <div className="label">Số lượng</div>
                      <div className="address">{siteData.volume || TEXT_HOLDER}</div>
                      <div className="label">Người quản lý</div>
                      {siteData.personInCharge || TEXT_HOLDER}
                    </Grid>
                    <Grid item xs={4}>
                      <div className="label">Địa chỉ</div>
                      <div className="address" style={{ cursor: "pointer" }}>
                        <ThemeProvider theme={theme}>
                          <Tooltip title={<span>{siteData.address || TEXT_HOLDER}</span>}>
                            <span>{siteData.address || TEXT_HOLDER}</span>
                          </Tooltip>
                        </ThemeProvider>
                      </div>
                      <div className="label">Liên hệ</div>
                      {siteData.contact || TEXT_HOLDER}
                    </Grid>
                  </Grid>
                </div>
                <div className="box settings" style={{ marginBottom: "1.6rem" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}>
                    <div className="title">Cài đặt thông số</div>
                  </div>
                  <div className="settings-tab">
                    <button className={tab ? "tab tab-active" : "tab"} onClick={() => setTab(true)}>
                      Khai báo lỗi thiết bị
                    </button>
                    <button
                      className={!tab ? "tab tab-active" : "tab"}
                      onClick={() => setTab(false)}>
                      Khai báo lỗi hệ thống
                    </button>
                  </div>
                  <Grid container className="settings-header">
                    <Grid item xs={2}>
                      {tab ? "Loại thiết bị" : "Loại lỗi"}
                    </Grid>
                    <Grid item xs={5} display="flex" justifyContent="flex-end">
                      Số lượng lỗi tối đa cho phép
                    </Grid>
                    <Grid item xs={5} display="flex" justifyContent="flex-end">
                      Thời gian xử lý lỗi tối đa cho phép
                    </Grid>
                  </Grid>
                  {tab
                    ? siteData.parameterSettings.map((p, idx: number) => {
                        return (
                          <Grid container className="settings-item" key={idx}>
                            <Grid item xs={2}>
                              {(deviceTypeData &&
                                deviceTypeData.find((f: any) => f.id === p.deviceType?.id)?.name) ||
                                TEXT_HOLDER}
                            </Grid>
                            <Grid item xs={5} display="flex" justifyContent="flex-end">
                              {p.maxCount} lỗi
                            </Grid>
                            <Grid item xs={5} display="flex" justifyContent="flex-end">
                              {p.maxTime} giây
                            </Grid>
                          </Grid>
                        );
                      })
                    : siteData.errorSettings.map((p) => {
                        return (
                          <Grid container className="settings-item">
                            <Grid item xs={2}>
                              {(OPCTag &&
                                OPCTag?.find((f: any) => f.id === p.opcTag?.id)?.description) ||
                                TEXT_HOLDER}
                            </Grid>
                            <Grid item xs={5} display="flex" justifyContent="flex-end">
                              {p.maxCount} lỗi
                            </Grid>
                            <Grid item xs={5} display="flex" justifyContent="flex-end">
                              {p.maxTime} giây
                            </Grid>
                          </Grid>
                        );
                      })}
                </div>
                {totalAreas !== 0 && (
                  <div className="box list">
                    <div className="list-header">
                      <div className="title" style={{ paddingBottom: "3rem" }}>
                        Danh sách chuồng ({totalAreas})
                      </div>
                      <Grid container className="areas-header">
                        <Grid item xs={2}>
                          Mã chuồng
                        </Grid>
                        <Grid item xs={3}>
                          Tên chuồng
                        </Grid>
                        <Grid item xs={4}>
                          Số lượng thiết bị
                        </Grid>
                        <Grid item xs={3}>
                          Tình trạng
                        </Grid>
                      </Grid>
                    </div>
                    {siteData.areas.map((areas, index: number) => {
                      return (
                        <Grid
                          container
                          className="areas-item"
                          key={index}
                          onClick={() => {
                            history.push({
                              pathname: APP_ROUTES.AREA_DETAIL.replace(
                                ":areaId",
                                areas.id.toString()
                              ),
                              state: {
                                siteIdDetail: siteId,
                              },
                            });
                          }}>
                          <Grid item xs={2} className="code">
                            {areas.code}
                          </Grid>
                          <Grid item xs={3}>
                            {areas.name}
                          </Grid>
                          <Grid item xs={4}>
                            {areas.devices}
                          </Grid>
                          <Grid item xs={3}>
                            {areas.status === "active" ? (
                              <span className="status" style={{ color: "#41AD49" }}>
                                Đang hoạt động
                              </span>
                            ) : (
                              <span className="status" style={{ color: "#B8292F" }}>
                                Ngưng hoạt động
                              </span>
                            )}
                          </Grid>
                        </Grid>
                      );
                    })}
                  </div>
                )}
              </Grid>
            </Grid>
          </div>
        ) : (
          <Loading style={{ margin: "2rem 0", height: "80vh" }} />
        )}
      </div>

      <Dialog fullScreen open={openSiteModal}>
        <SiteModal closeForm={() => setOpenSiteModal(false)} data={siteData} />
      </Dialog>

      <NotificationModal
        visible={notiDelete}
        title={"Xoá trại"}
        subTitle={"Bạn có chắc muốn xóa trại?"}
        icon={
          <div className={`notification-icon-box red`}>
            <PriorityHighIcon style={{ fontSize: "7rem", color: "#fff" }} />
          </div>
        }
        actions={
          <>
            <button
              className="btn-confirm-style"
              style={{
                background: "#fff",
                color: "#0A6836",
                border: "0.1rem solid #0A6836",
                width: "20.6rem",
                marginRight: "2rem",
              }}
              onClick={() => {
                setNotiDelete(false);
              }}>
              Huỷ
            </button>
            <button
              className="btn-confirm-style"
              style={{
                width: "20.6rem",
                marginRight: "2rem",
              }}
              onClick={() => {
                deleteSite(+siteId);
              }}>
              Đồng ý
            </button>
          </>
        }
        onClose={() => {
          setNotiDelete(false);
        }}
      />
    </Dialog>
  );
};

export default SiteDetail;
