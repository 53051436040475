/* eslint-disable react-hooks/exhaustive-deps */
import { Clear as ClearIcon } from "@mui/icons-material";
import { Dialog, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Field, Form } from "react-final-form";
import { useHistory } from "react-router-dom";
import useNoti from "../../../../hooks/useNoti";
import { AppNotifications } from "../../../../models";
import { AuthService } from "../../../../services";
import "./index.scss";

const Report: React.FC = () => {
  const mounted = useRef(false);
  const [pushNoti] = useNoti();
  const history = useHistory();
  const [update, setUpdate] = useState<boolean>(false);
  // eslint-disable-next-line
  const [getTime, setGetTime] = useState<any>(null);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const fetchData: () => Promise<void> = async () => {
    try {
      const result = await AuthService.getTime();
      if (mounted.current) {
        setGetTime(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // eslint-disable-next-line
  const submitForm = async (values: any) => {
    AuthService.setTime({
      hour: values.hour ? +values.hour : 0,
      minute: values.minute ? +values.minute : 0,
    })
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          pushNoti(AppNotifications.SUCCESS, "Cài đặt thời gian gửi báo cáo thành công!");
          setTimeout(() => {
            fetchData();
          });
          setUpdate(false);
        } else if (res && res.status === 400) {
          pushNoti(AppNotifications.ERROR, "Cài đặt thời gian gửi báo cáo thất bại!");
        }
      })
      .catch((err) => {
        pushNoti(AppNotifications.ERROR, "Cài đặt thời gian gửi báo cáo thất bại!");
        console.log(err);
      });
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={true}>
      <div className="profile-report">
        <ClearIcon
          style={{
            fontSize: "2.5rem",
            cursor: "pointer",
            position: "absolute",
            top: "3rem",
            right: "3rem",
            color: "#272B2F",
          }}
          onClick={() => history.goBack()}
        />
        <div className="title">Cài đặt thời gian gửi báo cáo</div>
        {update ? (
          <Form onSubmit={async (values) => submitForm(values)} initialValues={getTime}>
            {({ handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit} id="reset-pw">
                  <div
                    style={{
                      display: "flex",
                      fontSize: "4rem",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    <Field name="hour">
                      {({ input, meta, ...rest }) => {
                        return (
                          <TextField
                            {...input}
                            {...rest}
                            type="number"
                            color="success"
                            className={"form-text-field"}
                            inputProps={{ className: "input" }}
                            onChange={(e) => {
                              let value = parseInt(e.target.value);
                              if (value > 23) value = 23;
                              if (value < 0) value = 0;
                              input.onChange(value);
                            }}
                          />
                        );
                      }}
                    </Field>
                    :
                    <Field name="minute">
                      {({ input, meta, ...rest }) => {
                        return (
                          <TextField
                            {...input}
                            {...rest}
                            type="number"
                            color="success"
                            className={"form-text-field"}
                            inputProps={{ className: "input" }}
                            onChange={(e) => {
                              let value = parseInt(e.target.value);
                              if (value > 59) value = 59;
                              if (value < 0) value = 0;
                              input.onChange(value);
                            }}
                          />
                        );
                      }}
                    </Field>
                  </div>
                  <div>
                    <button className="btn-cancel-style " onClick={() => setUpdate(false)}>
                      Huỷ
                    </button>
                    <button className="btn-confirm-style " type="submit">
                      Lưu
                    </button>
                  </div>
                </form>
              );
            }}
          </Form>
        ) : (
          <>
            <div className="time">
              {getTime?.hour?.length === 1 ? "0" + getTime?.hour : getTime?.hour}&ensp;:&ensp;
              {getTime?.minute?.length === 1 ? "0" + getTime?.minute : getTime?.minute}
            </div>
            <button className="btn-confirm-style" onClick={() => setUpdate(true)}>
              Chỉnh sửa
            </button>
          </>
        )}
      </div>
    </Dialog>
  );
};

export default Report;
