import { REQUEST_URL } from "../app/constants";
import { PaginationQueryModel } from "../models";
import { DeviceInputModal } from "../models/device.model";
import { RequestUtil } from "../utils";

export const DeviceService = {
  getAllDevice: (
    query: PaginationQueryModel & {
      searchString?: string;
      deviceTypeId?: number;
    }
  ) => {
    const url = REQUEST_URL.DEVICE;

    return RequestUtil.get({ url, query: query });
  },

  getTypeDevice: () => {
    const url = REQUEST_URL.TYPE_DEVICE;
    return RequestUtil.get({ url });
  },

  getWithoutArea: (
    query: PaginationQueryModel & {
      searchString?: string;
      id?: number;
    }
  ) => {
    const url = REQUEST_URL.DEVICE_WITHOUT_AREA;

    return RequestUtil.get({ url, query: query });
  },

  createDevice: (
    data: DeviceInputModal | { code: string; deviceTypeId: number; status: string }
  ) => {
    const url = REQUEST_URL.DEVICE;
    return RequestUtil.post({ url, data });
  },

  updateDevice: (data: DeviceInputModal) => {
    const url = REQUEST_URL.DEVICE;
    return RequestUtil.put({ url, data });
  },

  deleteDevice: (id: number) => {
    const url = REQUEST_URL.DELETE_DEVICE.replace("{id}", id.toString());

    return RequestUtil.delete({ url, query: id });
  },
};
