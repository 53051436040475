import { AxiosError } from "axios";
import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import { useHistory, useLocation } from "react-router-dom";
import Logo from "../../../../assets/images/logo.png";
import OtpInput from "react-otp-input";
import LeftImage from "../../../../assets/images/signin/left-login.png";
import RightImage from "../../../../assets/images/signin/right-login.png";
import { AuthService } from "../../../../services";
import { loadCallback } from "../../../../utils/other.util";
import "./index.scss";
import { APP_ROUTES } from "../../../constants/module";
import useNoti from "../../../../hooks/useNoti";
import { AppNotifications, ErrorMessage } from "../../../../models";

const OTPInput: React.FC = () => {
  const [pushNoti] = useNoti();
  const [checkOtp, setCheckOtp] = useState<boolean>(false);
  // eslint-disable-next-line
  const location = useLocation<any>();
  const history = useHistory();
  // eslint-disable-next-line
  let submit: any;

  const submitForm = React.useCallback((values) => {
    setCheckOtp(false);
    AuthService.verifyForgotPassword({
      token: localStorage.getItem("forgot-password-token") || "",
      otp: values.otp,
    })
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          localStorage.setItem("verify-forgot-password-token", res.data.data.token);
          setCheckOtp(false);
          setTimeout(() => history.push(APP_ROUTES.RESET_PASSWORD));
        } else {
          if (res?.data.message === ErrorMessage.INVALID_TOKEN) {
            history.push(APP_ROUTES.FORGET_PASSWORD);
          } else if (res?.data.message === ErrorMessage.INCORRECT_OTP) {
            pushNoti(AppNotifications.ERROR, "OTP không chính sách");
            setCheckOtp(true);
          } else if (res?.data.message === ErrorMessage.EXPIRED_OTP) {
            history.push(APP_ROUTES.FORGET_PASSWORD);
          } else if (res?.data.message === ErrorMessage.OTP_HAS_BEEN_VERIFIED) {
            pushNoti(AppNotifications.ERROR, "OTP đã được xác thực");
            setTimeout(() => history.push(APP_ROUTES.FORGET_PASSWORD), 2000);
          } else if (res?.data.message === ErrorMessage.ACCOUNT_NOT_MATCH_WITH_TOKEN) {
            pushNoti(AppNotifications.ERROR, "Tài khoản không chính xác");
            setTimeout(() => history.push(APP_ROUTES.FORGET_PASSWORD), 2000);
          } else {
            pushNoti(AppNotifications.ERROR, "Lỗi hệ thống");
          }
        }
      })
      .catch((error: AxiosError) => {
        console.log(error);
      });
    // eslint-disable-next-line
  }, []);

  return (
    <div className="otp-form">
      <img className="img-left" src={LeftImage} alt="left" />
      <div className="otp-form__content">
        <div className="otp-form__logo">
          <img style={{ width: "30rem" }} src={Logo} alt="greenfeed-logo" />
        </div>
        <div className="title">Nhập mã xác thực OTP</div>
        <div className="description">
          Vui lòng điền mã xác thực đã được gửi qua email {location.state?.email || ""}
        </div>
        <Form
          initialValuesEqual={() => true}
          onSubmit={submitForm}
          render={({ handleSubmit }) => {
            submit = handleSubmit;
            return (
              <>
                <Field name="otp">
                  {({ input, meta, ...rest }) => {
                    return (
                      <React.Fragment>
                        <OtpInput
                          {...input}
                          {...rest}
                          numInputs={6}
                          isInputNum={true}
                          containerStyle={{ justifyContent: "center" }}
                          focusStyle={{ border: "3px solid #0a6836" }}
                          inputStyle={{
                            width: "5rem",
                            height: "6rem",
                            margin: "2rem 1rem",
                            fontSize: "2rem",
                            borderRadius: 5,
                            border: checkOtp && "1px solid #E93C3C ",
                          }}
                        />

                        {checkOtp ? (
                          <div className="otp-form__wrong">Mã xác thực không đúng</div>
                        ) : (
                          <div>&nbsp;</div>
                        )}
                      </React.Fragment>
                    );
                  }}
                </Field>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <button
                    type="submit"
                    className="otp-form__submit-btn"
                    onClick={(e) => {
                      e.preventDefault();
                      loadCallback(submit, e);
                    }}>
                    Hoàn tất
                  </button>
                </div>
              </>
            );
          }}></Form>

        <div className="otp-form__text">
          Điều khoản sử dụng và bảo mật
          <br />
          Phiên bản 1.2.21 • Bản quyền thuộc về
          <a
            style={{ color: "#0A6836", fontWeight: 700 }}
            rel="noreferrer"
            href={"https://www.greenfeed.com.vn/"}
            target="_blank">
            {" "}
            GREENFEED VIETNAM
          </a>
        </div>
      </div>
      <img className="img-right" src={RightImage} alt="right" />
    </div>
  );
};

export default OTPInput;
