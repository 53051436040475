import { REQUEST_URL } from "../app/constants";
import { PaginationQueryModel } from "../models";
import { RequestUtil } from "../utils";

export const ReportService = {
  getType: () => {
    const url = REQUEST_URL.REPORT_TYPE;
    return RequestUtil.get({ url });
  },

  getAllReport: (
    query: PaginationQueryModel & {
      type: string;
      hasFile: boolean;
    }
  ) => {
    const url = REQUEST_URL.REPORT_SITE_TEMPLATES;
    return RequestUtil.get({ url, query: query });
  },

  importTemplate: (formData: FormData) => {
    const url = REQUEST_URL.REPORT_IMPORT_TEMPLATE;
    return RequestUtil.post({ url, data: formData });
  },

  exportReport: (data: {
    isExportSample: boolean;
    isMonthly: boolean;
    siteId: number;
    types: string[];
  }) => {
    const url = REQUEST_URL.REPORT_SEND_MAIL;
    return RequestUtil.post({ url, data: data });
  },

  checkExport: (query: { siteId: number; type: string }) => {
    const url = REQUEST_URL.REPORT_CHECK_SAMPLE;
    return RequestUtil.get({ url, query: query });
  },
};
