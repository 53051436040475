// backend don't have staging yet
const BACKEND_API_ADDRESS = process.env.REACT_APP_BACKEND_API;
// we don't have staging for cms page yet

export const BASE_API_DOMAIN = BACKEND_API_ADDRESS;
export const BASE_IMG_DOMAIN = BACKEND_API_ADDRESS;

/*eslint-disable*/
const LINK_IMG: any = process.env.REACT_APP_LINK_IMAGE;
export const BASE_LINK_IMAGE = LINK_IMG;
