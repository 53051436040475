/*eslint-disable*/
import {
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import useFetch from "../../../../../hooks/useFetch";
import { useFetchWithoutPagination } from "../../../../../hooks/useFetchWithoutPagination";
import {
  AreaInputModel,
  FormUploadImageModel,
  SiteOutputModel,
  TypeArea,
  UploadedImageModel,
} from "../../../../../models";
import { AreaService } from "../../../../../services";
import { SiteService } from "../../../../../services/site.service";
import { FormUIUtil, FormUtil } from "../../../../../utils";
import ImageUpload from "../../../../components/ImageUpload";
import DefaultSwitch from "../../../../components/Switch";
import { APP_ROUTES } from "../../../../constants/module";
import "./index.scss";

type P_Props = {
  setValStep: () => void;
  dataDetail: any;
};

const AreaInfoUpdate: React.FC<P_Props> = ({ setValStep, dataDetail }) => {
  const localStorageData = localStorage.getItem("dataUpdate");
  const data = JSON.parse(localStorageData as any);
  const history = useHistory();
  const [siteId, setSiteId] = useState<string>("");
  const [areaTypeId, setAreaTypeId] = useState<string>(
    data ? data.areaTypeId : dataDetail ? dataDetail.areaType?.id : ""
  );
  const [avatar, setAvatar] = useState<Array<FormUploadImageModel & { file: any }>>([]);
  const [status, setStatus] = useState<boolean>(
    ((data && data?.status) || dataDetail?.status) === "inactive" ? false : true
  );
  const [submitErrorAreaTypeId, setSubmitErrorAreaTypeId] = useState<boolean>(false);
  const [submitErrorOPCSlot, setSubmitErrorOPCSlot] = useState<boolean>(false);

  //site
  const resultSite = useFetch(SiteService.getAllSite, {
    page: 1,
    //default limit 500
    limit: 500,
  });

  const { data: typeAreaData } = useFetchWithoutPagination(AreaService.getAreaType);

  const siteCode =
    resultSite.data.find((s: SiteOutputModel) => s.id === +siteId || s.id === dataDetail?.site?.id)
      ?.code + ".";

  const [OPCSlot, setOPCSlot] = useState<string>(
    dataDetail
      ? dataDetail.OPCSlot.slice((dataDetail.site?.code + ".").length, dataDetail.OPCSlot.length)
      : ""
  );

  useEffect(() => {
    data && setOPCSlot(data.OPCSlot?.slice(siteCode.length, data.OPCSlot.length));
  }, [siteCode]);

  const deviceMap = Array.from(Array(32)).map((_, index: number) => {
    const devices = dataDetail.devices.find((d: any) => +d.position - 1 === index);

    if (devices) {
      return {
        id: devices.id,
        position: devices.position,
      };
    }
    return { position: index + 1 };
  });

  const localStorageDevice = localStorage.getItem("devicesUpdate");
  const dataDevice = JSON.parse(localStorageDevice as any);

  useEffect(() => {
    if (dataDevice === null) {
      return localStorage.setItem("devicesUpdate", JSON.stringify(deviceMap));
    }
  }, [dataDevice]);

  const submitForm = async (values: any) => {
    if (areaTypeId === "") {
      return;
    }

    if (OPCSlot === "") {
      return;
    }

    let val = {};
    val = {
      id: dataDetail ? dataDetail?.id : undefined,
      code: values.code,
      OPCSlot: siteCode !== "undefined." ? siteCode + OPCSlot : "",
      avatar: avatar[0] && avatar[0].file ? avatar[0].file : undefined,
      size: undefined,
      volume: +values.volume,
      status: status === true ? "active" : "inactive",
      siteId: siteId !== "" ? +siteId : dataDetail.siteId,
      areaTypeId: +areaTypeId,
      refTemperature: values.refTemperature,
      name: values.name,
    } as any;

    setValStep();
    localStorage.setItem("dataUpdate", JSON.stringify(val));
  };

  return (
    <div className="area-info">
      <Form
        onSubmit={async (values: AreaInputModel) => submitForm(values)}
        initialValues={data ? data : dataDetail}>
        {({ handleSubmit }) => {
          return (
            <form
              onSubmit={handleSubmit}
              id="area-form"
              className="area-info-form"
              style={{ width: "84.3rem", paddingTop: "5rem" }}>
              <ImageUpload
                className="product-form-avatar"
                fileList={avatar}
                max={1}
                onChange={(data: UploadedImageModel) => {
                  setAvatar([
                    {
                      id: uuidv4(),
                      src: data.fileStr,
                      alt: data.alt || "",
                      file: data.file,
                    },
                  ]);
                }}
                onDelete={() => {
                  setAvatar([]);
                }}
                config={{ uploadModal: true }}
              />
              <Grid container columnSpacing={6}>
                <Grid item xs={6} className="title">
                  Thông tin chung
                </Grid>
                <Grid
                  item
                  xs={6}
                  className={"status"}
                  style={{ color: status === true ? "#41AD49" : "#ADACAC" }}>
                  <DefaultSwitch
                    checked={status}
                    onChange={() => {
                      setStatus(!status);
                    }}
                  />
                  {status === true ? "Đang hoạt động" : "Ngưng hoạt động"}
                </Grid>
                <Grid item xs={6}>
                  <Field name="code" validate={FormUtil.Rule.required("Xin nhập Mã chuồng")}>
                    {({ input, meta, ...rest }) => {
                      return FormUIUtil.renderFormItem(
                        "Mã chuồng",
                        <TextField
                          {...input}
                          {...rest}
                          fullWidth
                          placeholder={"0"}
                          color="success"
                          className="form-text-field"
                          inputProps={{ className: "input" }}
                          variant="outlined"
                          onChange={(e) => input.onChange(e.target.value)}
                          helperText={meta.touched ? meta.error : ""}
                          error={meta.error && meta.touched}
                        />
                      );
                    }}
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  <Field
                    name="volume"
                    validate={FormUtil.Rule.required("Xin nhập Số lượng vật nuôi")}>
                    {({ input, meta, ...rest }) => {
                      return FormUIUtil.renderFormItem(
                        "Số lượng vật nuôi",
                        <TextField
                          {...input}
                          {...rest}
                          fullWidth
                          placeholder={"0"}
                          color="success"
                          className="form-text-field"
                          inputProps={{ className: "input" }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end" style={{ fontFamily: "SVN-Gotham" }}>
                                con
                              </InputAdornment>
                            ),
                          }}
                          type="number"
                          variant="outlined"
                          onChange={(e) => input.onChange(e.target.value)}
                          helperText={meta.touched ? meta.error : ""}
                          error={meta.error && meta.touched}
                        />
                      );
                    }}
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  {FormUIUtil.renderFormItem(
                    "Loại chuồng",
                    <FormControl fullWidth>
                      <Select
                        fullWidth
                        value={areaTypeId}
                        onChange={(e) => {
                          setAreaTypeId(e.target.value as string);
                        }}
                        input={<OutlinedInput className="form-text-field" color="success" />}
                        renderValue={
                          areaTypeId !== ""
                            ? undefined
                            : () => <span className="form__placeholder">Chọn loại chuồng</span>
                        }
                        onBlur={(e) => {
                          setSubmitErrorAreaTypeId(areaTypeId === "");
                        }}
                        variant="outlined"
                        className="form-select-field"
                        error={submitErrorAreaTypeId}
                        displayEmpty
                        MenuProps={{
                          className: "select-menu-list select-menu-list-border-item",
                        }}>
                        {typeAreaData?.length &&
                          typeAreaData.map((r: TypeArea) => {
                            return (
                              <MenuItem key={r.id} value={r.id}>
                                {r.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                      <FormHelperText>{submitErrorAreaTypeId && "Chọn loại chuồng"}</FormHelperText>
                    </FormControl>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <Field name="name" validate={FormUtil.Rule.required("Xin nhập Tên chuồng")}>
                    {({ input, meta, ...rest }) => {
                      return FormUIUtil.renderFormItem(
                        "Tên chuồng",
                        <TextField
                          {...input}
                          {...rest}
                          fullWidth
                          placeholder={"Nhập tên chuồng..."}
                          color="success"
                          className="form-text-field"
                          inputProps={{ className: "input" }}
                          variant="outlined"
                          onChange={(e) => input.onChange(e.target.value)}
                          helperText={meta.touched ? meta.error : ""}
                          error={meta.error && meta.touched}
                        />
                      );
                    }}
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  {FormUIUtil.renderFormItem(
                    "Trại",
                    <FormControl fullWidth>
                      <Select
                        fullWidth
                        value={siteId}
                        onChange={(e) => {
                          setSiteId(e.target.value as string);
                        }}
                        disabled={dataDetail?.site?.name ? true : false}
                        input={<OutlinedInput className="form-text-field" color="success" />}
                        renderValue={
                          siteId !== ""
                            ? undefined
                            : () =>
                                dataDetail?.site?.name ? (
                                  <>{dataDetail?.site?.name}</>
                                ) : (
                                  <span className="form__placeholder">Chọn trại</span>
                                )
                        }
                        variant="outlined"
                        className="form-select-field"
                        displayEmpty
                        MenuProps={{
                          className: "select-menu-list select-menu-list-border-item",
                        }}>
                        {resultSite.data?.length &&
                          resultSite.data.map((site: SiteOutputModel) => {
                            return (
                              <MenuItem key={site.id} value={site.id}>
                                {site.code}&ensp;&#9679;&ensp;{site.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  )}
                </Grid>

                {(dataDetail ? dataDetail?.site?.id : siteId !== "") && (
                  <Grid item xs={6}>
                    {FormUIUtil.renderFormItem(
                      "OPC Slot",
                      <TextField
                        value={OPCSlot}
                        fullWidth
                        placeholder={"Nhập OPC Slot..."}
                        color="success"
                        className="form-text-field form-opc"
                        inputProps={{ className: "input" }}
                        variant="outlined"
                        onChange={(e) => {
                          setOPCSlot(e.target.value);
                          setSubmitErrorOPCSlot(e.target.value === "" ? true : false);
                        }}
                        onBlur={() => {
                          setSubmitErrorOPCSlot(OPCSlot === "" ? true : false);
                        }}
                        helperText={submitErrorOPCSlot && "Xin nhập OPC Slot"}
                        error={submitErrorOPCSlot}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start" style={{ fontFamily: "SVN-Gotham" }}>
                              {siteCode}
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  </Grid>
                )}
                <Grid item xs={6}>
                  <Field name="refTemperature">
                    {({ input, meta, ...rest }) => {
                      return FormUIUtil.renderFormItem(
                        "Nhiệt độ tham chiếu",
                        <TextField
                          {...input}
                          {...rest}
                          fullWidth
                          placeholder={"Nhiệt độ tham chiếu"}
                          type="number"
                          color="success"
                          className="form-text-field"
                          inputProps={{ className: "input" }}
                          variant="outlined"
                          onChange={(e) => input.onChange(e.target.value)}
                          helperText={meta.touched ? meta.error : ""}
                          error={meta.error && meta.touched}
                        />
                      );
                    }}
                  </Field>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Form>
      <div className="modal-footer">
        <button
          type="button"
          className="btn-cancel"
          onClick={() => {
            localStorage.removeItem("dataUpdate");
            localStorage.removeItem("devicesUpdate");
            localStorage.removeItem("settingUpdate");
            history.push(APP_ROUTES.AREA);
          }}>
          Hủy
        </button>
        <input
          value={"Tiếp tục"}
          form="area-form"
          type="submit"
          aria-label="submit supplier form"
          onClick={() => {
            setSubmitErrorOPCSlot(OPCSlot === "");
            setSubmitErrorAreaTypeId(areaTypeId === "");
          }}
        />
      </div>
    </div>
  );
};

export default AreaInfoUpdate;
