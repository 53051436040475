import { PriorityHigh as PriorityHighIcon } from "@mui/icons-material";
import { Dialog, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { withProfile } from "../../../hoc/withProfile";
import useNoti from "../../../hooks/useNoti";
import {
  AccountStatus,
  AppNotifications,
  AppState,
  FormUploadImageModel,
  UploadedImageModel,
} from "../../../models";
import { AccountService, AuthService, StatisticService } from "../../../services";
import { checkLinkImage, StatusUIUtil } from "../../../utils";
import { PermissionUtil } from "../../../utils/permission.util";
import ImageUpload from "../../components/ImageUpload";
import Loading from "../../components/Loading";
import NotificationModal from "../../components/NotificationModal";
import SimpleModalHeader from "../../components/SimpleModalHeader";
import DefaultSwitch from "../../components/Switch";
import { StatisticPermission } from "../../constants";
import { TEXT_HOLDER } from "../../constants/utilities";
import ChangePassword from "./ChangePassword";
import "./index.scss";
import ProfileForm from "./ProfileForm";

//eslint-disable-next-line
const Profile: React.FC = (props: any) => {
  const history = useHistory();
  const [pushNoti] = useNoti();
  // const profile = props.profile as AppState.ProfileOutputModal;
  const [profile, setProfile] = useState<AppState.ProfileOutputModal>(props.profile);
  const [status, setStatus] = useState<boolean>(props.profile?.status);
  const [sendEmailStatus, setSendEmailStatus] = useState<boolean>(props.profile?.receiveNotify);
  const [openAccountModal, setOpenAccountModal] = useState<boolean>(false);
  const [notiStatus, setNotiStatus] = useState<{
    visible: boolean;
    status: boolean;
  }>({
    visible: false,
    status: false,
  });
  const [notiSendEmailStatus, setNotiSendEmailStatus] = useState<{
    visible: boolean;
    status: boolean;
  }>({
    visible: false,
    status: false,
  });

  const [openChangePw, setOpenChangePw] = useState<boolean>(false);
  // eslint-disable-next-line
  const [avatars, setAvatars] = useState<Array<FormUploadImageModel & { file: any }>>([]);

  useEffect(() => {
    setAvatars(
      profile?.avatar
        ? [
            {
              id: "",
              src: `${checkLinkImage(profile?.avatar)}`,
              alt: "",
              file: null,
            },
          ]
        : []
    );
  }, [profile]);

  const checkPermissionSendEmail =
    profile &&
    PermissionUtil.check(profile?.role?.permissions, StatisticPermission.STATISTIC_RECEIVE, true);

  const receiveStatitic = async (id: number, status: boolean) => {
    try {
      const res = await StatisticService.receiveStatistic({
        enable: status,
        accountId: id,
      });
      if (res.status === 200 || res.status === 201) {
        pushNoti(
          AppNotifications.SUCCESS,
          status ? "Kích hoạt gửi email thành công" : "Vô hiệu hóa gửi email tự động thành công"
        );
        const result = await AuthService.authProfile();
        setProfile(result.data.data);
      }
    } catch (error) {
      pushNoti(
        AppNotifications.ERROR,
        status ? "Kích hoạt gửi email thất bại" : "Vô hiệu hóa gửi email tự động thất bại"
      );
    }
  };

  const recallOrderList = async (id: number, type: string, isActive: boolean) => {
    try {
      const res = await AccountService.updateAccountActiveNotify({
        id: id,
        type: type,
        isActive: isActive,
      });
      if (res.status === 200 || res.status === 201) {
        const result = await AuthService.authProfile();
        setProfile(result.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Dialog fullScreen open={true}>
      <div className="profile">
        <SimpleModalHeader
          title={"Hồ sơ cá nhân"}
          onClose={() => {
            history.goBack();
          }}
        />
        {!!profile ? (
          <div className="content">
            <Grid container>
              <Grid item xs={3}>
                <div className="left">
                  <div className="left-image">
                    {/* <img src={Avatar} alt="avt" /> */}
                    <ImageUpload
                      title="Đổi ảnh đại diện"
                      max={1}
                      fileList={avatars}
                      onChange={(data: UploadedImageModel) => {
                        setAvatars([
                          {
                            id: uuidv4(),
                            src: data.fileStr,
                            alt: data.alt || "",
                            file: data.file,
                          },
                        ]);
                        // uploadAvatar();
                      }}
                      onDelete={() => {
                        setAvatars([]);
                      }}
                      config={{ uploadModal: true }}
                      name={profile?.name}
                    />
                  </div>
                  <div className="name">{profile?.name || TEXT_HOLDER}</div>
                  {StatusUIUtil.renderStatusAccount(profile.status)}
                  <button className="btn-confirm-style" onClick={() => setOpenAccountModal(true)}>
                    Chỉnh sửa
                  </button>
                  <button
                    className="btn-confirm-style bord-green"
                    onClick={() => setOpenChangePw(true)}>
                    Đổi mật khẩu
                  </button>
                  {/* <button
                    className="btn-confirm-style bord-red"
                    onClick={() =>
                      pushNoti(AppNotifications.WARNING, "Tính năng đang được cập nhật")
                    }>
                    Xóa tài khoản
                  </button> */}
                </div>
              </Grid>
              <Grid item xs={8}>
                <div className="box" style={{ marginBottom: "1.6rem" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}>
                    <div className="title">Thông tin tài khoản</div>
                  </div>
                  <Grid container>
                    <Grid item xs={4}>
                      <div className="label">Mã nhân viên</div>
                      {profile.code || TEXT_HOLDER}
                      <div className="label">Email</div>
                      {profile.email || TEXT_HOLDER}
                    </Grid>
                    <Grid item xs={3}>
                      <div className="label">Số điện thoại</div>
                      {profile.phoneNumber || TEXT_HOLDER}
                      <div className="label">Vai trò</div>
                      {profile.role?.name || TEXT_HOLDER}
                    </Grid>
                    <Grid item xs={5}>
                      {/*Kich hoat tai khoan */}
                      <div className="label"></div>
                      <div className="status" style={{ color: status ? "#41AD49" : "#ADACAC" }}>
                        <DefaultSwitch
                          checked={status}
                          onChange={() => {
                            setStatus(!status);
                            profile?.status === AccountStatus.ACTIVE
                              ? setNotiStatus({ visible: true, status: false })
                              : setNotiStatus({ visible: true, status: true });
                          }}
                        />
                        {status ? "Kích hoạt tài khoản" : "Vô hiệu hóa tài khoản"}
                      </div>
                      {/*Gui email*/}
                      <div className="label"></div>
                      {checkPermissionSendEmail && (
                        <div
                          className="status"
                          style={{ color: sendEmailStatus ? "#41AD49" : "#ADACAC" }}>
                          <DefaultSwitch
                            checked={sendEmailStatus}
                            onChange={() => {
                              setSendEmailStatus(!sendEmailStatus);
                              profile?.receiveNotify
                                ? setNotiSendEmailStatus({ visible: true, status: false })
                                : setNotiSendEmailStatus({ visible: true, status: true });
                            }}
                          />
                          {sendEmailStatus === true
                            ? "Gửi email báo cáo ngày tự động"
                            : "Vô hiệu hóa gửi email báo cáo ngày tự động"}
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </div>
                <div className="box" style={{ marginBottom: "1.6rem" }}>
                  <div className="title" style={{ paddingBottom: "3rem" }}>
                    Trại được phép truy cập
                  </div>
                  {profile &&
                    profile?.dataAccesses?.map((access) => {
                      return (
                        <Grid container className="site-item" key={access.id}>
                          <Grid item xs={3}>
                            {access.name}
                          </Grid>
                          <Grid item xs={3}>
                            <DefaultSwitch
                              checked={access.notiImportant}
                              onChange={() => {
                                recallOrderList(access.id, "notiImportant", !access.notiImportant);
                              }}
                            />
                            Khẩn cấp
                          </Grid>
                          <Grid item xs={3}>
                            <DefaultSwitch
                              checked={access.notiOperation}
                              onChange={() => {
                                recallOrderList(access.id, "notiOperation", !access.notiOperation);
                              }}
                            />
                            Vận hành
                          </Grid>
                          <Grid item xs={3}>
                            <DefaultSwitch
                              checked={access.notiEnvironment}
                              onChange={() => {
                                recallOrderList(
                                  access.id,
                                  "notiEnvironment",
                                  !access.notiEnvironment
                                );
                              }}
                            />
                            Môi trường
                          </Grid>
                        </Grid>
                      );
                    })}
                </div>
                {/* <div className="box">
                  <Accordion defaultExpanded={true} className="user-logins">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon style={{ fontSize: "3rem", color: "#272B2F" }} />}
                      className="title">
                      Lịch sử truy cập
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container className="site-header">
                        <Grid item xs={4}>
                          Thời gian
                        </Grid>
                        <Grid item xs={3}>
                          IP
                        </Grid>
                        <Grid item xs={3}>
                          Trạng thái
                        </Grid>
                        <Grid item xs={2}>
                          Thiết bị
                        </Grid>
                      </Grid>
                      {profile.userLogins.map((user) => {
                        return (
                          <Grid container className="site-item" key={user.id}>
                            <Grid item xs={4}>
                              {moment(user.timestamp).format("DD/MM/YYYY \\lúc HH:mm") ||
                                TEXT_HOLDER}
                            </Grid>
                            <Grid item xs={3}>
                              {user.ip || TEXT_HOLDER}
                            </Grid>
                            <Grid item xs={3}>
                              {TEXT_HOLDER}
                            </Grid>
                            <Grid item xs={2}>
                              {user.type || TEXT_HOLDER}
                            </Grid>
                          </Grid>
                        );
                      })}
                    </AccordionDetails>
                  </Accordion>
                </div> */}
              </Grid>
            </Grid>
          </div>
        ) : (
          <Loading style={{ margin: "2rem 0", height: "80vh" }} />
        )}
      </div>

      <NotificationModal
        visible={notiStatus.visible}
        title={notiStatus.status ? "Kích hoạt tài khoản" : "Vô hiệu hóa tài khoản"}
        subTitle={
          notiStatus.status ? (
            <span>
              Tài khoản đang vô hiệu hóa
              <br />
              Bạn có chắc muốn kích hoạt tài khoản này ?
            </span>
          ) : (
            <span>
              Tài khoản đang hoạt động
              <br />
              Bạn có chắc muốn vô hiệu hóa tài khoản này ?
            </span>
          )
        }
        icon={
          <div className={`notification-icon-box ${notiStatus.status ? "yellow" : "red"}`}>
            <PriorityHighIcon style={{ fontSize: "7rem", color: "#fff" }} />
          </div>
        }
        actions={
          <>
            <button
              className="btn-confirm-style"
              style={{
                background: "#fff",
                color: "#0A6836",
                border: "0.1rem solid #0A6836",
                width: "20.6rem",
                marginRight: "2rem",
              }}
              onClick={() => {
                setStatus(profile?.status === AccountStatus.ACTIVE ? true : false);
                notiStatus.status
                  ? setNotiStatus({ visible: false, status: true })
                  : setNotiStatus({ visible: false, status: false });
              }}>
              Không
            </button>
            <button
              className="btn-confirm-style"
              style={{
                width: "20.6rem",
                marginRight: "2rem",
              }}
              onClick={() => {
                // updateAccountActive();
                notiStatus.status
                  ? setNotiStatus({ visible: false, status: true })
                  : setNotiStatus({ visible: false, status: false });
              }}>
              Xác nhận
            </button>
          </>
        }
        onClose={() => {
          setStatus(profile?.status === AccountStatus.ACTIVE ? true : false);
          notiStatus.status
            ? setNotiStatus({ visible: false, status: true })
            : setNotiStatus({ visible: false, status: false });
        }}
      />

      <NotificationModal
        visible={notiSendEmailStatus.visible}
        title={
          notiSendEmailStatus.status
            ? "Kích hoạt gửi email tự động"
            : "Vô hiệu hóa gửi email tự động"
        }
        subTitle={
          notiSendEmailStatus.status ? (
            <span>
              Tài khoản đang vô hiệu hóa nhận email báo cáo tự động
              <br />
              Bạn có chắc muốn kích hoạt tính năng này ?
            </span>
          ) : (
            <span>
              Tài khoản được kích hoạt nhận email báo cáo tự động
              <br />
              Bạn có chắc muốn vô hiệu hóa tính năng này ?
            </span>
          )
        }
        icon={
          <div className={`notification-icon-box ${notiSendEmailStatus.status ? "yellow" : "red"}`}>
            <PriorityHighIcon style={{ fontSize: "7rem", color: "#fff" }} />
          </div>
        }
        actions={
          <>
            <button
              className="btn-confirm-style"
              style={{
                background: "#fff",
                color: "#0A6836",
                border: "0.1rem solid #0A6836",
                width: "20.6rem",
                marginRight: "2rem",
              }}
              onClick={() => {
                setStatus(profile?.status === AccountStatus.ACTIVE ? true : false);
                notiSendEmailStatus.status
                  ? setNotiSendEmailStatus({ visible: false, status: true })
                  : setNotiSendEmailStatus({ visible: false, status: false });
              }}>
              Không
            </button>
            <button
              className="btn-confirm-style"
              style={{
                width: "20.6rem",
                marginRight: "2rem",
              }}
              onClick={() => {
                receiveStatitic(profile.id, sendEmailStatus);
                notiSendEmailStatus.status
                  ? setNotiSendEmailStatus({ visible: false, status: true })
                  : setNotiSendEmailStatus({ visible: false, status: false });
              }}>
              Xác nhận
            </button>
          </>
        }
        onClose={() => {
          setStatus(profile?.status === AccountStatus.ACTIVE ? true : false);
          notiSendEmailStatus.status
            ? setNotiSendEmailStatus({ visible: false, status: true })
            : setNotiSendEmailStatus({ visible: false, status: false });
        }}
      />

      <Dialog fullWidth maxWidth="sm" open={openChangePw}>
        <ChangePassword onClose={() => setOpenChangePw(false)} />
      </Dialog>

      <Dialog fullScreen open={openAccountModal}>
        <ProfileForm closeForm={() => setOpenAccountModal(false)} data={profile} />
      </Dialog>
    </Dialog>
  );
};

export default withProfile(Profile);
