import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { Nullable } from "../../../../models/shared";
import { AuthService } from "../../../../services";

export const loadProfile = createAsyncThunk("profile/loadProfile", async (_arg) => {
  const response = await AuthService.authProfile();

  return {
    data: response.data.data,
  };
});

type ProfileState = {
  data: Nullable<{
    avatar: string;
    id: number;
    code: string;
    email: string;
    name: string;
    status: string;
    phoneNumber: string;
    receiveNotify: boolean;
    dataAccesses: [
      {
        accountId: number;
        id: number;
        name: string;
        notiEnvironment: boolean;
        notiImportant: boolean;
        notiOperation: boolean;
        siteId: number;
        status: string;
      }
    ];
    site: [
      {
        id: number;
        code: string;
        status: string;
      }
    ];
    role: {
      id: number;
      name: string;
      permission: string[];
    };
    userLogins: [{ device: string; id: number; ip: string; timestamp: string; type: string }];
  }>;
  loading: boolean;
};

const initialState: ProfileState = {
  data: null,
  loading: false,
};

export const getProfile = (builder: ActionReducerMapBuilder<ProfileState>) => {
  const { pending, fulfilled, rejected } = loadProfile;

  builder.addCase(pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(fulfilled, (state, action) => {
    state.loading = false;
    state.data = action.payload.data;
  });
  builder.addCase(rejected, (state, action) => {
    state.loading = false;
  });
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getProfile(builder);
  },
});

// Selectors
export const LoadMe = (state: RootState) => state.profile;

//Reducers
const profileReducer = profileSlice.reducer;
export default profileReducer;
