import { AccountStatus, AlarmType, SendOption, SiteStatus } from "../../models";

export const NavHeaderAccount = [
  {
    id: "0",
    label: "Tất cả",
    value: undefined,
    title: "all",
  },
  {
    id: "1",
    label: "Kích hoạt",
    value: AccountStatus.ACTIVE,
    title: AccountStatus.ACTIVE,
  },
  {
    id: "2",
    label: "Vô hiệu hoá",
    value: AccountStatus.INACTIVE,
    title: AccountStatus.INACTIVE,
  },
  {
    id: "3",
    label: "Chờ phê duyệt",
    value: AccountStatus.PENDING,
    title: AccountStatus.PENDING,
  },
  {
    id: "4",
    label: "Đã hủy",
    value: AccountStatus.DECLINE,
    title: AccountStatus.DECLINE,
  },
];

export const RoleFilter = [
  {
    id: "0",
    label: "Admin",
    value: 1,
  },
  {
    id: "1",
    label: "Manager",
    value: 2,
  },
  {
    id: "2",
    label: "Site Manager",
    value: 3,
  },
  {
    id: "3",
    label: "Guest",
    value: 4,
  },
];

export const SiteFilterStatus = [
  {
    id: "0",
    label: "Chính thức",
    value: SiteStatus.OFFICIAL,
  },
  {
    id: "1",
    label: "Chưa chính thức",
    value: SiteStatus.UNOFFICIAL,
  },
];

export const AlarmFilterSendOption = [
  {
    id: "0",
    label: "Gửi thông báo",
    value: SendOption.NOTIFICATION,
  },
  {
    id: "1",
    label: "Gửi thông báo & SMS",
    value: SendOption.BOTH,
  },
  {
    id: "2",
    label: "Không gửi thông báo",
    value: SendOption.NONE,
  },
];

export const AlarmFilterSendOptionCreate = [
  {
    id: "0",
    label: "Gửi thông báo",
    value: [SendOption.NOTIFICATION],
  },
  {
    id: "1",
    label: "Gửi thông báo & SMS",
    value: [SendOption.SMS, SendOption.NOTIFICATION],
  },
  {
    id: "2",
    label: "Không gửi thông báo",
    value: [],
  },
];

export const AlarmFilterAlarmType = [
  {
    id: "0",
    label: "Môi trường",
    value: AlarmType.MEDIUM,
  },
  {
    id: "1",
    label: "Khẩn cấp",
    value: AlarmType.IMPORTANCE,
  },
  {
    id: "2",
    label: "Vận hành",
    value: AlarmType.OPERATION,
  },
];

export const ConditionAlarm = [
  {
    id: 0,
    label: "Lớn hơn hoặc bằng:",
    text: "X ≥",
    conditionValue: ">=",
  },
  {
    id: 1,
    label: "Trong khoảng cao:",
    text: " ≤ X < ",
    conditionValue: "<=&&<",
  },
  {
    id: 2,
    label: "Trong khoảng thấp:",
    text: " < X ≤ ",
    conditionValue: "<&&<=",
  },
  {
    id: 3,
    label: "Bé hơn hoặc bằng:",
    text: "X ≤",
    conditionValue: "<=",
  },
];

export const NavHeaderAlarm = [
  {
    id: "0",
    label: "Tất cả",
    value: undefined,
    quan: "totalRecords",
  },
  {
    id: "1",
    label: "Hoạt động",
    value: AccountStatus.ACTIVE,
    quan: AccountStatus.ACTIVE,
  },
  {
    id: "2",
    label: "Ngừng hoạt động",
    value: AccountStatus.INACTIVE,
    quan: "inActive",
  },
];

export const NavHeaderReport = [
  {
    id: "0",
    label: "Có mẫu",
    value: true,
    quan: "have",
  },
  {
    id: "1",
    label: "Chưa có mẫu",
    value: false,
    quan: "notHave",
  },
];
