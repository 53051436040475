import React from "react";
import { IconBase, IconComponent } from "../../core/Icons";

export const SelectedStarIcon: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <circle
        cx="9.19608"
        cy="9.19608"
        r="8.53922"
        fill={props.color}
        stroke={props.color}
        strokeWidth="1.31373"
      />
      <path
        d="M8.61301 5.06536C8.85812 4.5928 9.53409 4.5928 9.7792 5.06536L10.7717 6.97886C10.8638 7.15637 11.0317 7.28228 11.2279 7.32091L13.316 7.73207C13.8162 7.83057 14.0184 8.43662 13.6776 8.81575L12.1813 10.4798C12.0542 10.6212 11.9946 10.8107 12.0179 10.9994L12.2628 12.984C12.3325 13.5489 11.6997 13.9291 11.2337 13.6022L9.57329 12.4377C9.3469 12.2789 9.04531 12.2789 8.81892 12.4377L7.15853 13.6022C6.69253 13.9291 6.05972 13.5489 6.12943 12.984L6.37434 10.9994C6.39763 10.8107 6.33801 10.6212 6.21087 10.4798L4.71465 8.81575C4.37376 8.43662 4.57596 7.83057 5.0762 7.73207L7.16433 7.32091C7.36053 7.28228 7.52845 7.15637 7.62052 6.97886L8.61301 5.06536Z"
        fill="white"
      />
    </IconBase>
  );
};
