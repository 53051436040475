/* eslint-disable max-lines */
import { PriorityHigh as PriorityHighIcon } from "@mui/icons-material";
import { Dialog, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Avatar from "../../../../assets/images/avt.png";
import useNoti from "../../../../hooks/useNoti";
import { AccountDetailOutputModel, AccountStatus, AppNotifications } from "../../../../models";
import { Nullable } from "../../../../models/shared";
import { AccountService, AuthService, StatisticService } from "../../../../services";
import { StatusUIUtil } from "../../../../utils";
import { checkLinkImage, loadCallback, renderErrorImage } from "../../../../utils/other.util";
import Loading from "../../../components/Loading";
import NotificationModal from "../../../components/NotificationModal";
import SimpleModalHeader from "../../../components/SimpleModalHeader";
import DefaultSwitch from "../../../components/Switch";
import { StatisticPermission } from "../../../constants";
import { APP_ROUTES } from "../../../constants/module";
import { TEXT_HOLDER } from "../../../constants/utilities";
import AccountModal from "../AccountModal";
import "./index.scss";

const AccountDetail: React.FC = () => {
  const [pushNoti] = useNoti();
  // eslint-disable-next-line
  const location = useLocation<any>();
  const history = useHistory();
  const mounted = useRef(false);
  const { accountId }: { accountId: string } = useParams();
  const [accountData, setAccountData] = useState<Nullable<AccountDetailOutputModel>>(null);
  const [openAccountModal, setOpenAccountModal] = useState<boolean>(false);
  const [noti, setNoti] = useState<boolean>(false);
  const [status, setStatus] = useState<boolean>(false);
  const [notiStatus, setNotiStatus] = useState<{
    visible: boolean;
    status: boolean;
  }>({
    visible: false,
    status: false,
  });
  const [notiSendEmailStatus, setNotiSendEmailStatus] = useState<{
    visible: boolean;
    status: boolean;
  }>({
    visible: false,
    status: false,
  });
  const [sendEmailStatus, setSendEmailStatus] = useState<boolean>(false);

  const fetchData: () => Promise<void> = async () => {
    try {
      const result = await AccountService.getAccountDetailById(+accountId);
      if (mounted.current) {
        setAccountData(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    accountData && setStatus(accountData?.status === AccountStatus.ACTIVE ? true : false);
  }, [accountData]);
  useEffect(() => {
    accountData && setSendEmailStatus(accountData?.receiveNotify);
  }, [accountData]);

  const updateAccountActive = () => {
    AccountService.updateAccountActive({
      accountId: +accountId,
      status: !status ? AccountStatus.INACTIVE : AccountStatus.ACTIVE,
    })
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          notiStatus.status
            ? pushNoti(AppNotifications.SUCCESS, "Kích hoạt tài khoản thành công!")
            : pushNoti(AppNotifications.SUCCESS, "Vô hiệu hoá tài khoản thành công!");
          setTimeout(() => {
            fetchData();
          });
        } else {
          notiStatus.status
            ? pushNoti(AppNotifications.ERROR, "Kích hoạt tài khoản thất bại!")
            : pushNoti(AppNotifications.ERROR, "Vô hiệu hoá tài khoản thất bại!");
        }
      })
      .catch((err) => {
        notiStatus.status
          ? pushNoti(AppNotifications.ERROR, "Kích hoạt tài khoản thất bại!")
          : pushNoti(AppNotifications.ERROR, "Vô hiệu hoá tài khoản thất bại!");
        console.log(err);
      });
  };

  const recallOrderList = async (id: number, type: string, isActive: boolean) => {
    try {
      await AccountService.updateAccountActiveNotify({
        id: id,
        type: type,
        isActive: isActive,
      });
      setTimeout(() => {
        fetchData();
      });
    } catch (error) {
      console.log(error);
    }
  };

  const grantPassword = () => {
    AuthService.grantNewPassword({ email: accountData?.email })
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          pushNoti(AppNotifications.SUCCESS, "Cấp mật khẩu thành công");
          setNoti(false);
          loadCallback();
        }
      })
      .catch((err) => {
        console.log(err);
        pushNoti(AppNotifications.ERROR, "Cấp mật khẩu thất bại");
      });
  };

  const deleteAccount = async (id: number) => {
    try {
      const res = await AccountService.deleteAccount(id);
      if (res.status === 200 || res.status === 201) {
        pushNoti(AppNotifications.SUCCESS, "Xóa tài khoản thành công");
        setTimeout(() => history.push(APP_ROUTES.ACCOUNT), 200);
      }
    } catch (error) {
      pushNoti(AppNotifications.ERROR, "Xóa tài khoản thất bại");
      console.log(error);
    }
  };
  const checkPermissionSendEmail = accountData?.role?.permissions?.some((permission) =>
    permission?.code.includes(StatisticPermission.STATISTIC_RECEIVE)
  );
  const receiveStatitic = async (id: number, status: boolean) => {
    try {
      const res = await StatisticService.receiveStatistic({
        enable: status,
        accountId: id,
      });
      if (res.status === 200 || res.status === 201) {
        pushNoti(AppNotifications.SUCCESS, "Vô hiệu hóa gửi email thành công");
      }
    } catch (error) {
      console.log(error);
      pushNoti(AppNotifications.WARNING, "Vô hiệu hóa gửi email thất bại");
    }
  };

  const recoverAccount = async (id: number) => {
    try {
      const res = await AccountService.recoverAccount({ id: id });
      if (res.status === 200 || res.status === 201) {
        pushNoti(AppNotifications.SUCCESS, "Khôi phục tài khoản thành công");
        setTimeout(() => fetchData(), 200);
      }
    } catch (error) {
      pushNoti(AppNotifications.ERROR, "Khôi phục tài khoản thất bại");
      console.log(error);
    }
  };
  return (
    <Dialog fullScreen open={true}>
      <div className="account-detail">
        <SimpleModalHeader
          title={"Chi tiết tài khoản"}
          onClose={() => {
            history.push({
              pathname: APP_ROUTES.ACCOUNT,
              state: {
                status: location.state?.status,
              },
            });
          }}
        />
        {!!accountData ? (
          <div className="content">
            <Grid container>
              <Grid item xs={3}>
                <div className="left">
                  <div className="left-image">
                    <img
                      src={checkLinkImage(accountData.avatar as string) || Avatar}
                      alt="avt"
                      onError={renderErrorImage("avatar")}
                    />
                  </div>
                  <div className="name">{accountData?.name || TEXT_HOLDER}</div>
                  {StatusUIUtil.renderStatusAccount(accountData.status)}
                  {accountData.status !== AccountStatus.DECLINE ? (
                    <>
                      <button
                        className="btn-confirm-style"
                        onClick={() => setOpenAccountModal(true)}>
                        Chỉnh sửa
                      </button>
                      <button
                        className="btn-confirm-style bord-green"
                        onClick={() => setNoti(true)}>
                        Cấp lại mật khẩu
                      </button>
                      <button
                        className="btn-confirm-style bord-red"
                        onClick={() => deleteAccount(accountData?.id)}>
                        Xóa tài khoản
                      </button>
                    </>
                  ) : (
                    <button
                      className="btn-confirm-style"
                      onClick={() => recoverAccount(accountData?.id)}>
                      Khôi phục tài khoản
                    </button>
                  )}
                </div>
              </Grid>
              <Grid item xs={8}>
                <div className="box" style={{ marginBottom: "1.6rem" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}>
                    <div className="title">Thông tin tài khoản</div>
                  </div>
                  <Grid container>
                    <Grid item xs={4}>
                      <div className="label">Mã nhân viên</div>
                      {accountData.code || TEXT_HOLDER}
                      <div className="label">Email</div>
                      {accountData.email || TEXT_HOLDER}
                    </Grid>
                    <Grid item xs={3}>
                      <div className="label">Số điện thoại</div>
                      {accountData.phoneNumber || TEXT_HOLDER}
                      <div className="label">Vai trò</div>
                      {accountData.role?.name || TEXT_HOLDER}
                    </Grid>
                    <Grid item xs={5}>
                      <div className="label"></div>
                      <div
                        className="status"
                        style={{ color: status === true ? "#41AD49" : "#ADACAC" }}>
                        <DefaultSwitch
                          disabled={accountData.status === AccountStatus.DECLINE}
                          checked={status}
                          onChange={() => {
                            setStatus(!status);
                            accountData?.status === AccountStatus.ACTIVE
                              ? setNotiStatus({ visible: true, status: false })
                              : setNotiStatus({ visible: true, status: true });
                          }}
                        />
                        {status === true ? "Kích hoạt" : "Vô hiệu hóa"}
                      </div>
                      <div className="label"></div>
                      {checkPermissionSendEmail && (
                        <div
                          className="status"
                          style={{ color: sendEmailStatus ? "#41AD49" : "#ADACAC" }}>
                          <DefaultSwitch
                            disabled={accountData.status === AccountStatus.DECLINE}
                            checked={sendEmailStatus}
                            onChange={() => {
                              setSendEmailStatus(!sendEmailStatus);
                              accountData?.receiveNotify
                                ? setNotiSendEmailStatus({ visible: true, status: false })
                                : setNotiSendEmailStatus({ visible: true, status: true });
                            }}
                          />
                          {sendEmailStatus === true
                            ? "Gửi email báo cáo ngày tự động"
                            : "Vô hiệu hóa gửi email báo cáo ngày tự động"}
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </div>
                <div className="box">
                  <div className="title" style={{ paddingBottom: "3rem" }}>
                    Trại được phép truy cập
                  </div>
                  {accountData.dataAccesses.map((access) => {
                    return (
                      <Grid container className="site-item" key={access.id}>
                        <Grid item xs={3}>
                          {access.name}
                        </Grid>
                        <Grid item xs={3}>
                          <DefaultSwitch
                            disabled={accountData.status === AccountStatus.DECLINE}
                            checked={access.notiImportant}
                            onChange={() => {
                              recallOrderList(access.id, "notiImportant", !access.notiImportant);
                            }}
                          />
                          Khẩn cấp
                        </Grid>
                        <Grid item xs={3}>
                          <DefaultSwitch
                            disabled={accountData.status === AccountStatus.DECLINE}
                            checked={access.notiOperation}
                            onChange={() => {
                              recallOrderList(access.id, "notiOperation", !access.notiOperation);
                            }}
                          />
                          Vận hành
                        </Grid>
                        <Grid item xs={3}>
                          <DefaultSwitch
                            disabled={accountData.status === AccountStatus.DECLINE}
                            checked={access.notiEnvironment}
                            onChange={() => {
                              recallOrderList(
                                access.id,
                                "notiEnvironment",
                                !access.notiEnvironment
                              );
                            }}
                          />
                          Môi trường
                        </Grid>
                      </Grid>
                    );
                  })}
                </div>
              </Grid>
            </Grid>
          </div>
        ) : (
          <Loading style={{ margin: "2rem 0", height: "80vh" }} />
        )}
      </div>
      <Dialog fullScreen open={openAccountModal}>
        <AccountModal closeForm={() => setOpenAccountModal(false)} data={accountData} />
      </Dialog>
      <NotificationModal
        visible={notiSendEmailStatus.visible}
        title={
          notiSendEmailStatus.status
            ? "Kích hoạt gửi email tự động"
            : "Vô hiệu hóa gửi email tự động"
        }
        subTitle={
          notiSendEmailStatus.status ? (
            <span>
              Tài khoản đang vô hiệu hóa nhận email báo cáo tự động
              <br />
              Bạn có chắc muốn kích hoạt tính năng này ?
            </span>
          ) : (
            <span>
              Tài khoản được kích hoạt nhận email báo cáo tự động
              <br />
              Bạn có chắc muốn vô hiệu hóa tính năng này ?
            </span>
          )
        }
        icon={
          <div className={`notification-icon-box ${notiSendEmailStatus.status ? "yellow" : "red"}`}>
            <PriorityHighIcon style={{ fontSize: "7rem", color: "#fff" }} />
          </div>
        }
        actions={
          <>
            <button
              className="btn-confirm-style"
              style={{
                background: "#fff",
                color: "#0A6836",
                border: "0.1rem solid #0A6836",
                width: "20.6rem",
                marginRight: "2rem",
              }}
              onClick={() => {
                setStatus(accountData?.status === AccountStatus.ACTIVE ? true : false);
                notiSendEmailStatus.status
                  ? setNotiSendEmailStatus({ visible: false, status: true })
                  : setNotiSendEmailStatus({ visible: false, status: false });
              }}>
              Không
            </button>
            <button
              className="btn-confirm-style"
              style={{
                width: "20.6rem",
                marginRight: "2rem",
              }}
              onClick={() => {
                receiveStatitic(accountData?.id as number, sendEmailStatus);
                notiSendEmailStatus.status
                  ? setNotiSendEmailStatus({ visible: false, status: true })
                  : setNotiSendEmailStatus({ visible: false, status: false });
              }}>
              Xác nhận
            </button>
          </>
        }
        onClose={() => {
          setStatus(accountData?.status === AccountStatus.ACTIVE ? true : false);
          notiSendEmailStatus.status
            ? setNotiSendEmailStatus({ visible: false, status: true })
            : setNotiSendEmailStatus({ visible: false, status: false });
        }}
      />
      <NotificationModal
        visible={notiStatus.visible}
        title={notiStatus.status ? "Kích hoạt tài khoản" : "Vô hiệu hóa tài khoản"}
        subTitle={
          notiStatus.status ? (
            <span>
              Tài khoản đang vô hiệu hóa
              <br />
              Bạn có chắc muốn kích hoạt tài khoản này ?
            </span>
          ) : (
            <span>
              Tài khoản đang hoạt động
              <br />
              Bạn có chắc muốn vô hiệu hóa tài khoản này ?
            </span>
          )
        }
        icon={
          <div className={`notification-icon-box ${notiStatus.status ? "yellow" : "red"}`}>
            <PriorityHighIcon style={{ fontSize: "7rem", color: "#fff" }} />
          </div>
        }
        actions={
          <>
            <button
              className="btn-confirm-style"
              style={{
                background: "#fff",
                color: "#0A6836",
                border: "0.1rem solid #0A6836",
                width: "20.6rem",
                marginRight: "2rem",
              }}
              onClick={() => {
                setStatus(accountData?.status === AccountStatus.ACTIVE ? true : false);
                notiStatus.status
                  ? setNotiStatus({ visible: false, status: true })
                  : setNotiStatus({ visible: false, status: false });
              }}>
              Không
            </button>
            <button
              className="btn-confirm-style"
              style={{
                width: "20.6rem",
                marginRight: "2rem",
              }}
              onClick={() => {
                updateAccountActive();
                notiStatus.status
                  ? setNotiStatus({ visible: false, status: true })
                  : setNotiStatus({ visible: false, status: false });
              }}>
              Xác nhận
            </button>
          </>
        }
        onClose={() => {
          setStatus(accountData?.status === AccountStatus.ACTIVE ? true : false);
          notiStatus.status
            ? setNotiStatus({ visible: false, status: true })
            : setNotiStatus({ visible: false, status: false });
        }}
      />
      <NotificationModal
        visible={noti}
        title={"Cấp lại mật khẩu"}
        subTitle={"Bạn có chắc muốn cấp lại mật khẩu cho tài khoản này?"}
        icon={
          <div className={"notification-icon-box yellow"}>
            <PriorityHighIcon style={{ fontSize: "7rem", color: "#fff" }} />
          </div>
        }
        actions={
          <>
            <button
              className="btn-confirm-style"
              style={{
                background: "#fff",
                color: "#0A6836",
                border: "0.1rem solid #0A6836",
                width: "20.6rem",
                marginRight: "2rem",
              }}
              onClick={() => setNoti(false)}>
              Không
            </button>
            <button
              className="btn-confirm-style"
              style={{
                width: "20.6rem",
                marginRight: "2rem",
              }}
              onClick={() => {
                grantPassword();
              }}>
              Xác nhận
            </button>
          </>
        }
        onClose={() => {
          setNoti(false);
        }}
      />
    </Dialog>
  );
};

export default AccountDetail;
