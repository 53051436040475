/* eslint-disable */
import { Avatar, Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { SiteOutputModel } from "../../../../models";
import { SiteService } from "../../../../services/site.service";
import { autoLoadingPagination, StatusUIUtil } from "../../../../utils";
import "./index.scss";

type Props = {
  notAll?: boolean;
  siteId: number | undefined;
  onChangeSite: (siteId: number | undefined) => void;
};

const LeftSite: React.FC<Props> = ({ notAll = false, onChangeSite, siteId }) => {
  //eslint-disable-next-line
  const [dataSite, setDataSite] = useState<any>();

  const loadData = async (page: number, limit: number) => {
    // eslint-disable-next-line
    const resultAccount: any = await autoLoadingPagination(
      SiteService.getAllSite,
      {
        filter: {},
      },
      page,
      limit,
      dataSite && dataSite?.returnData
    );

    if (resultAccount?.returnData?.length) setDataSite(resultAccount);
    if (resultAccount?.returnData?.length === 0) setDataSite({ ...resultAccount });
  };

  const hasMore =
    dataSite?.payload?.data?.length < dataSite?.payload?.totalRecords &&
    dataSite?.payload?.page < dataSite?.payload?.totalPages;

  useEffect(() => {
    loadData(1, 10);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="left-site">
      <div className="left-site-header">
        <div className="title">Trại</div>
        {!notAll && (
          <div
            className={siteId === undefined ? "active item" : "item"}
            onClick={() => {
              onChangeSite(undefined);
            }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Avatar
                alt="All"
                src=""
                style={{ background: "#41AD49", fontSize: "1.6rem", fontWeight: "bold" }}>
                T
              </Avatar>
              <div className="name">Tất cả</div>
            </div>
          </div>
        )}
        <Divider style={{ width: "100%", margin: "0.8rem 0" }} />
      </div>
      <div className="left-site-body">
        {dataSite?.returnData?.length > 0 && (
          <InfiniteScroll
            useWindow={false}
            pageStart={0}
            initialLoad={false}
            hasMore={hasMore}
            loadMore={() => loadData(dataSite.payload.page + 1, 10)}>
            {!notAll && (
              <div
                className={siteId === 0 ? "active item" : "item"}
                onClick={() => {
                  onChangeSite(0);
                }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Avatar
                    alt="c"
                    src=""
                    style={{ background: "#41AD49", fontSize: "1.6rem", fontWeight: "bold" }}>
                    C
                  </Avatar>
                  <div className="name">Chưa phân bổ</div>
                </div>
              </div>
            )}
            {dataSite?.returnData.map((s: SiteOutputModel, index: number) => {
              return (
                <div
                  className={siteId === s.id ? "active item" : "item"}
                  key={index}
                  onClick={() => {
                    onChangeSite(s.id);
                  }}>
                  <Avatar alt={s.name as string} src="">
                    <Avatar {...StatusUIUtil.stringAvatarSite(s.name as string)} />
                  </Avatar>
                  <div className="name">{s.name}</div>
                </div>
              );
            })}
          </InfiniteScroll>
        )}
      </div>
    </div>
  );
};

export default LeftSite;
