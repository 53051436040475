/*eslint-disable */
import {
  CheckRounded as CheckRoundedIcon,
  Clear as ClearIcon,
  DeleteOutline as DeleteOutlineIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import { Dialog, InputAdornment, TextField } from "@mui/material";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import exporting from "../../../assets/images/exporting.gif";
import IconImport from "../../../assets/images/ic_import.png";
import Search from "../../../assets/images/img-search.png";
import Ord from "../../../assets/images/ordnull.png";
import Pig from "../../../assets/images/pig.png";
import useNoti from "../../../hooks/useNoti";
import { AppNotifications, SiteOutputModel } from "../../../models";
import { ReportService } from "../../../services";
import { SiteService } from "../../../services/site.service";
import {
  autoLoadingPagination,
  checkLinkImage,
  FormUIUtil,
  renderErrorImage,
} from "../../../utils";
import EmptyList from "../../components/EmptyList";
import FloatingBtn from "../../components/FloatingBtn";
import { ReportNullIcon } from "../../components/Icons/ReportNullIcon";
import { ReportSuccessIcon } from "../../components/Icons/ReportSuccessIcon";
import Loading from "../../components/Loading";
import NotificationModal from "../../components/NotificationModal";
import SelectInput from "../../components/SelectInput";
import { BASE_LINK_IMAGE } from "../../constants";
import { NavHeaderReport } from "../../constants/mock";
import { TEXT_HOLDER } from "../../constants/utilities";
import "./index.scss";
import LeftReportFilter from "./LeftReportFilter";

const Report: React.FC = () => {
  const mounted = useRef(false);
  const checkExportRef = useRef<any>(null);
  const [pushNoti] = useNoti();
  const [selectTab, setSelectTab] = useState<boolean>(true);
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const [visibleSearch, setVisibleSearch] = useState<boolean>(false);
  const [openReportModal, setOpenReportModal] = useState<boolean>(false);
  const [type, setType] = useState<string>("");
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [siteId, setSiteId] = useState<string>("");
  const [checkErr, setCheckErr] = useState<{ file: boolean | string; siteId: boolean | string }>({
    file: false,
    siteId: false,
  });
  const [dataReport, setDataReport] = useState<any>();

  const [notiExport, setNotiExport] = useState<string>("");
  const [success, setSuccess] = useState<any>();

  const loadData = async (page: number, limit: number) => {
    setLoading(true);
    const resultAccount: any = await autoLoadingPagination(
      ReportService.getAllReport,
      {
        filter: {
          searchString: searchValue,
          type: type,
          hasFile: selectTab,
        },
      },
      page,
      limit,
      dataReport && dataReport?.returnData
    );

    if (resultAccount?.returnData?.length) setDataReport(resultAccount);
    if (resultAccount?.returnData?.length === 0) setDataReport({ ...resultAccount });
    setLoading(false);
  };

  const hasMore =
    dataReport?.payload?.data?.length < dataReport?.payload?.totalRecords &&
    dataReport?.payload?.page < dataReport?.payload?.totalPages;

  useEffect(() => {
    type !== "" && loadData(1, 10);
    // eslint-disable-next-line
  }, [searchValue, type, selectTab]);

  const onSearch = _.debounce((value) => {
    setSearchValue(value);

    value ? setVisibleSearch(true) : setVisibleSearch(false);
  }, 300);

  const onFileChange = (event: any) => {
    const file: File = event.target.files[0];
    setSelectedFile(file);
  };

  const checkFile = () => {
    const file = selectedFile?.name.slice(
      selectedFile?.name.lastIndexOf("."),
      selectedFile?.name.length
    );
    if (file !== ".xls") {
      if (file !== ".xlsx" && file !== ".xlsm") {
        return setCheckErr({
          file: "Tập tin tải lên phải có định dạng .xls, .xlsx, .xlsm",
          siteId: checkErr.siteId,
        });
      }
    }
    if (siteId === "") {
      return setCheckErr({
        file: checkErr.file,
        siteId: "Bạn chưa chọn trại",
      });
    }
    return setCheckErr({ file: true, siteId: true });
  };

  useEffect(() => {
    (selectedFile || siteId) && checkFile();
  }, [selectedFile, siteId]);

  const importReport = async () => {
    if (selectedFile === null) {
      return setCheckErr({ file: "Bạn chưa chọn tập tin để tải lên", siteId: checkErr.siteId });
    } else if (siteId === "") {
      return setCheckErr({ file: checkErr.file, siteId: "Bạn chưa chọn trại" });
    }
    if (checkErr.file === true && checkErr.siteId === true) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.set("type", type);
      formData.set("siteId", siteId);
      await ReportService.importTemplate(formData)
        .then((res) => {
          if (res && (res.status === 200 || res.status === 201)) {
            setSelectedFile(null);
            setSiteId("");
            setOpenReportModal(false);
            loadData(1, 10);
            pushNoti(AppNotifications.SUCCESS, "Import báo cáo thành công");
          } else {
            pushNoti(AppNotifications.ERROR, "Import báo cáo thất bại!");
          }
        })
        .catch((err) => {
          pushNoti(AppNotifications.ERROR, "Import báo cáo thất bại!");
        });
    }
  };

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const onCheckExport = async (siteId: number) => {
    try {
      const response = await ReportService.checkExport({ siteId: +siteId, type: type });
      if (mounted.current) {
        if (response.data.complete === true) {
          setSuccess(() => response.data.url);
          clearInterval(checkExportRef.current);
        } else {
        }
      }
    } catch (error) {
      clearInterval(checkExportRef.current);
      pushNoti(AppNotifications.ERROR, "Xuất báo cáo thất bại!");
      setNotiExport("");
    }
  };

  const exportReport = async (siteId: number) => {
    ReportService.exportReport({
      isExportSample: true,
      isMonthly: true,
      siteId: siteId,
      types: [type],
    })
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          checkExportRef.current = setInterval(() => {
            onCheckExport(siteId);
          }, 5000);
        } else {
          pushNoti(AppNotifications.ERROR, "Xuất báo cáo thất bại!");
          setNotiExport("");
        }
      })
      .catch((err) => {});
  };

  return (
    <div className="report">
      <div className="report-content">
        <LeftReportFilter onChangeTab={(tab: string) => setType(tab)} />
        <div className="content">
          <div className="action">
            <div className="alarm__nav-header">
              {NavHeaderReport.map((o, idx: number) => {
                return (
                  <div
                    key={o.id + idx}
                    className={
                      o.value === selectTab
                        ? "tab tab-active btn--shiny"
                        : `tab tab-hover ${o.value}`
                    }
                    onClick={() => {
                      setSelectTab(o.value);
                    }}>
                    {o.label}
                    <span>{dataReport?.payload?.count[`${o.quan}`]}</span>
                  </div>
                );
              })}
            </div>
            <div style={{ display: "flex" }}>
              <TextField
                placeholder="Tìm theo tên, mã trại..."
                variant="outlined"
                className={"search-style"}
                inputProps={{ className: "input" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="large" />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  onSearch(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="list">
            <div className="list-header">
              <div className="site">Trại</div>
              <div className="time">Thời gian cập nhật</div>
              <div className="report">Báo cáo</div>
            </div>
            {loading ? (
              <Loading style={{ margin: "2rem 0", height: "60vh" }} />
            ) : dataReport?.returnData ? (
              dataReport?.returnData?.length > 0 ? (
                <InfiniteScroll
                  useWindow={false}
                  pageStart={0}
                  initialLoad={false}
                  hasMore={hasMore}
                  loadMore={() => loadData(dataReport.payload.page + 1, 10)}>
                  {dataReport.fetching && searchValue && <Loading />}
                  {dataReport.returnData?.map((rep: any, index: number) => {
                    return (
                      <div className={`item-list-page`} key={index}>
                        <div className="site">
                          <img
                            src={checkLinkImage(rep.avatar as string) || Pig}
                            alt="avt-pig"
                            onError={renderErrorImage()}
                          />
                          <div>
                            {rep.name}
                            <div className="code">{rep.code || TEXT_HOLDER}</div>
                          </div>
                        </div>
                        <div className="time">
                          {rep.postingDate
                            ? moment(rep.postingDate).format("HH:mm - DD/MM/YYYY")
                            : TEXT_HOLDER}
                        </div>
                        <div className="report">
                          {rep.fileName ? (
                            <span
                              onClick={() => {
                                exportReport(rep.id);
                                setNotiExport(rep.name);
                                setSuccess(undefined);
                              }}>
                              <ReportSuccessIcon
                                color="#41AD49"
                                size={[28, 28]}
                                viewBox={[28, 28]}
                              />
                            </span>
                          ) : (
                            <ReportNullIcon color="#ADACAC" size={[28, 28]} viewBox={[28, 28]} />
                          )}
                        </div>
                      </div>
                    );
                  })}
                </InfiniteScroll>
              ) : visibleSearch === true ? (
                <EmptyList img={Search} title="Không tìm thấy kết quả phù hợp" />
              ) : (
                <EmptyList img={Ord} title="Chưa có dữ liệu" />
              )
            ) : (
              <EmptyList img={Ord} title="Chưa có dữ liệu" />
            )}
          </div>
        </div>
      </div>

      <FloatingBtn
        visible={false}
        onClickPrimary={() => {
          setOpenReportModal(true);
        }}
        btns={[]}
      />

      <Dialog maxWidth="lg" open={openReportModal}>
        <div className="import-report">
          <div className="title">
            <div>
              Báo cáo thống kê{" "}
              {type === "ambient" ? " dữ liệu môi trường theo tháng " : " lỗi theo tháng "}
            </div>
            <div
              onClick={() => {
                setOpenReportModal(false);
                setSelectedFile(null);
              }}>
              <ClearIcon style={{ fontSize: "3rem", cursor: "pointer", marginLeft: "3rem" }} />
            </div>
          </div>
          {FormUIUtil.renderFormItem(
            "Chọn Trại",
            <SelectInput<SiteOutputModel>
              className={"item-textfield"}
              iconEnd={<SearchIcon fontSize="large" />}
              onChange={(value: any) => {
                setSiteId(value?.id?.toString());
              }}
              placeholder="Chọn trại"
              getOptionLabel={(opt: SiteOutputModel) => opt.name as string}
              renderOption={(option: SiteOutputModel) => (
                <button key={option.id} className="site-item-menu-list">
                  <img
                    src={checkLinkImage(option?.avatar as string) || Pig}
                    alt="avt"
                    onError={renderErrorImage()}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      fontFamily: "SVN-Gotham",
                    }}>
                    {option.name}
                    <div className="code">{option.code}</div>
                  </div>
                </button>
              )}
              actionConfig={{
                action: SiteService.getAllSite,
              }}
            />
          )}
          {typeof checkErr.siteId === "string" && <span className="err">{checkErr.siteId}</span>}
          <div>
            <button
              className="file-import"
              style={{ marginBottom: typeof checkErr.file === "string" ? "0.1rem" : "4rem" }}>
              {selectedFile !== null ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                  }}>
                  {selectedFile.name}
                  <button
                    className="ic-click"
                    onClick={(e) => {
                      setSelectedFile(null);
                      setCheckErr({ file: false, siteId: false });
                    }}>
                    <DeleteOutlineIcon style={{ fontSize: "2rem", color: "#fff" }} />
                  </button>
                </div>
              ) : (
                <>
                  <img src={IconImport} alt="ic_import" />
                  Kéo thả hoặc lựa chọn file .xls để tải lên
                  <input type="file" onChange={onFileChange} />
                </>
              )}
            </button>
            {typeof checkErr.file === "string" && <span className="err">{checkErr.file}</span>}
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <button
              className="btn-cancel-style"
              onClick={() => {
                setOpenReportModal(false);
                setSelectedFile(null);
              }}>
              Huỷ
            </button>
            <button
              disabled={!selectedFile}
              onClick={() => {
                importReport();
              }}
              style={{ opacity: selectedFile ? 1 : 0.6 }}
              className="btn-confirm-style">
              Xác nhận
            </button>
          </div>
        </div>
      </Dialog>

      <NotificationModal
        visible={notiExport !== ""}
        title={success ? "Xuất dữ liệu thành công!" : "Xuất dữ liệu"}
        subTitle={
          success ? (
            <span>
              Báo cáo thống kê
              {type === "ambient" ? " dữ liệu môi trường theo tháng " : " lỗi theo tháng "}
              trại&nbsp;
              {notiExport}
              <br />
              đã được xuất thành công. Nhấn vào nút “Tải về" để tải file.
            </span>
          ) : (
            <span>
              Xuất báo cáo thống kê
              {type === "ambient" ? " dữ liệu môi trường theo tháng " : " lỗi theo tháng "}
              trại&nbsp;
              {notiExport}
              <br />
              đang được thực hiện. Vui lòng chờ trong giây lát!
            </span>
          )
        }
        icon={
          <div className={`notification-icon-box green`}>
            {success ? (
              <CheckRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
            ) : (
              <img src={exporting} alt="exporting" style={{ width: "12rem", height: "12rem" }} />
            )}
          </div>
        }
        actions={
          <>
            <button
              className="btn-confirm-style"
              style={{
                background: "#fff",
                color: "#0A6836",
                border: "0.1rem solid #0A6836",
                width: "20.6rem",
                marginRight: "2rem",
                borderRadius: "4rem",
              }}
              onClick={() => {
                setNotiExport("");
                clearInterval(checkExportRef.current);
              }}>
              Đóng
            </button>
            {success ? (
              <a
                className="btn-confirm-style"
                style={{
                  width: "20.6rem",
                  borderRadius: "4rem",
                }}
                rel="noreferrer"
                href={`${BASE_LINK_IMAGE + success}`}
                onClick={() => {
                  setTimeout(() => {
                    setNotiExport("");
                    clearInterval(checkExportRef.current);
                  }, 3000);
                }}>
                Tải về
              </a>
            ) : (
              <button
                disabled
                className="btn-confirm-style"
                style={{
                  width: "20.6rem",
                  borderRadius: "4rem",
                  opacity: "0.4",
                  cursor: "not-allowed",
                }}>
                Tải về
              </button>
            )}
          </>
        }
        onClose={() => {
          setNotiExport("");
          clearInterval(checkExportRef.current);
        }}
      />
    </div>
  );
};

export default Report;
