/* eslint-disable */
export enum AppNotifications {
  SUCCESS = "success",
  ERROR = "error",
  WARNING = "warning",
  INFO = "info",
}

export type NotiModel = {
  type: AppNotifications;
  message: string;
};

export type AppNotiModel = {
  id: string;
} & NotiModel;

// export type AppRouteModel = {
//   sidebarTitle: string;
//   appbarTitle: string;
//   path: string;
//   withSubPaths: string[];
//   icons: { active: any; inactive: any };
//   renderComponent: () => any;
//   module: keyof typeof MAIN_MODULES;
//   common?: boolean;
// };
// export type AppRouteMenuModel = {
//   title: string;
//   path: string;
//   images?: { active: any; inactive: any };
//   icons: { active: any; inactive: any };
//   renderComponent: () => any;
// };

// export type RootState = ReturnType<typeof RootReducer>;

export declare module AppState {
  // export type AllCategoryListState = FetchingDataModel<CategoryOutputModel[]>;
  export type AppNotifications = {
    list: AppNotiModel[];
  };

  export type Authentication = {
    loading: boolean;
    data: {
      token: string;
    } | null;
  };

  export type Profile = {
    loading: boolean;
    data: {
      id: number;
      code: string;
      name: string;
      status: string;
      receiveNotify: boolean;
      site: [
        {
          id: number;
          code: string;
          status: string;
        }
      ];
      role: {
        id: number;
        name: string;
        permission: string[];
      };
    } | null;
  };

  export type ProfileOutputModal = {
    avatar: string;
    id: number;
    code: string;
    name: string;
    status: string;
    receiveNotify: boolean;
    email: string;
    phoneNumber: string;
    dataAccesses: [
      {
        accountId: number;
        id: number;
        name: string;
        notiEnvironment: boolean;
        notiImportant: boolean;
        notiOperation: boolean;
        siteId: number;
        status: string;
      }
    ];
    site: [
      {
        id: number;
        code: string;
        status: string;
      }
    ];
    role: {
      id: number;
      name: string;
      permissions: string[];
    };
    userLogins: [
      {
        device: string | null;
        id: number;
        ip: string;
        timestamp: string;
      }
    ];
  };

  // export type AllLanguageListState = FetchingDataModel<LanguageOutputModel[]>;
}
