import { TextField } from "@mui/material";
import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import Logo from "../../../../assets/images/mock/logo-title-white.png";
import Background from "../../../../assets/images/mock/image-forget.png";
import { useHistory } from "react-router-dom";
import "./index.scss";
import { AxiosError } from "axios";
import { FormUtil } from "../../../../utils";
import { loadCallback } from "../../../../utils/other.util";
import { APP_ROUTES } from "../../../constants/module";
import { AuthService } from "../../../../services";
import useNoti from "../../../../hooks/useNoti";
import { AppNotifications, ErrorMessage } from "../../../../models";
import { REGEX } from "../../../constants";

const ForgetPassword: React.FC = () => {
  const [pushNoti] = useNoti();
  const history = useHistory();
  const [submitError, setSubmitError] = useState<boolean>(false);

  //eslint-disable-next-line
  let submit: any;
  const submitForm = React.useCallback(async (values) => {
    try {
      await AuthService.forgotPassword({
        email: values.email,
      })
        .then((res) => {
          if (res && (res.status === 200 || res.status === 201)) {
            localStorage.setItem("forgot-password-token", res.data.data.token);

            setTimeout(() =>
              history.push({
                pathname: APP_ROUTES.OTP,
                state: {
                  email: values.email,
                },
              })
            );
          } else {
            if (res?.data.message === ErrorMessage.ACCOUNT_NOT_FOUND) {
              pushNoti(AppNotifications.ERROR, "Không tìm thấy tài khoản");
            } else if (res?.data.message === ErrorMessage.AN_ERROR_OCCURRED_WHILE_SENDING_MAIL) {
              pushNoti(AppNotifications.ERROR, "Đã xảy ra lỗi khi gửi mail");
            } else {
              pushNoti(AppNotifications.ERROR, "Lỗi hệ thống");
            }
          }
        })
        .catch((err: AxiosError) => {
          console.log(err);
        });
      //eslint-disable-next-line
    } catch (error: any) {
      const errors: Record<string, unknown> = {};
      if (error.message) errors.user = "Tài khoản không tồn tại";
      return errors;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="forgetpw-form">
      <img src={Background} alt="greenfeed-img" />
      <div className="forgetpw-form__content">
        <div className="forgetpw-form__content__logo">
          <img src={Logo} alt="greenfeed-logo" />
        </div>
        <div className="title">Quên mật khẩu</div>
        <div className="description">
          Vui lòng nhập tài khoản <br /> chúng tôi sẽ giúp quý khách đặt lại mật khẩu.
        </div>
        <Form
          initialValuesEqual={() => true}
          onSubmit={submitForm}
          render={({ handleSubmit }) => {
            submit = handleSubmit;
            return (
              <form onSubmit={handleSubmit} className="forgetpw-form__content__form">
                <div className="forgetpw-form__label">Nhập tài khoản</div>
                <Field
                  name="email"
                  validate={FormUtil.composeValidators([
                    FormUtil.Rule.required("Nhập email"),
                    FormUtil.Rule.pattern(REGEX.EMAIL, {
                      errorMessage: "Email không đúng định dạng",
                    }),
                  ])}>
                  {({ input, meta, ...rest }) => {
                    return (
                      <TextField
                        {...input}
                        {...rest}
                        fullWidth
                        type="text"
                        autoComplete="off"
                        placeholder={"Nhập địa chỉ email"}
                        className={"text-border-style"}
                        inputProps={{ className: "input" }}
                        variant="outlined"
                        helperText={submitError === true ? meta.error || meta.submitError : ""}
                        error={submitError === true ? meta.error || meta.submitError : ""}
                        onChange={(e) => {
                          input.onChange(e.target.value);
                          setSubmitError(true);
                        }}
                        onBlur={(e) => {
                          input.onChange(e.target.value.replace(/\s/g, ""));
                          setSubmitError(true);
                        }}
                      />
                    );
                  }}
                </Field>
                <button
                  className="btn-confirm-style"
                  onClick={(e) => {
                    e.preventDefault();
                    loadCallback(submit, e);
                  }}>
                  Đặt lại mật khẩu
                </button>
              </form>
            );
          }}
        />
        <div className="forgetpw-form__auth">
          <a href={"!#"}>Đăng ký thông tin</a>
          &#160;hoặc&#160;
          <a href={APP_ROUTES.LOGIN}>Đăng nhập</a>
        </div>
        <div className="text">
          Điều khoản sử dụng và bảo mật
          <br />
          Phiên bản 1 • Bản quyền thuộc về
          <a
            style={{ fontWeight: 700 }}
            rel="noreferrer"
            href={"https://www.greenfeed.com.vn/"}
            target="_blank">
            {" "}
            GREENFEED VIETNAM
          </a>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
