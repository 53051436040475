/* eslint-disable */
import React from "react";
import "./index.scss";

type Props = {
  title: string;
  formId: string;
  showDeleteButton: boolean;
  handleDeleteAction: (...args: any[]) => void;
  handleCancelAction: (...args: any[]) => void;
  handleSubmit: (...args: any[]) => void;
};

const ModalFooter: React.FC<Props> = ({
  handleCancelAction,
  showDeleteButton,
  handleDeleteAction,
  formId,
  title,
  handleSubmit,
}) => {
  return (
    <div className="modal-footer">
      {showDeleteButton && (
        <button
          type="button"
          className="btn-delete"
          onClick={() => {
            handleDeleteAction();
          }}>
          Xóa
        </button>
      )}
      <button
        type="button"
        className="btn-cancel"
        onClick={() => {
          handleCancelAction();
        }}>
        Hủy
      </button>
      <input
        value={showDeleteButton ? "Cập nhật" : title ? title : "Tạo"}
        aria-label="submit supplier form"
        form={formId}
        type="submit"
        onClick={() => handleSubmit()}
      />
    </div>
  );
};

export default React.memo(ModalFooter);
