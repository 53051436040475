/*eslint-disable */
import { Clear as ClearIcon, Search as SearchIcon } from "@mui/icons-material";
import {
  Dialog,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Field, Form } from "react-final-form";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Bolt from "../../../../assets/images/mock/settings/bolt.jpg";
import Bulb from "../../../../assets/images/mock/settings/bulb.jpg";
import Fan from "../../../../assets/images/mock/settings/fan.jpg";
import WaterWell from "../../../../assets/images/mock/settings/water-well.jpg";
import Well from "../../../../assets/images/mock/settings/well.jpg";
import Wifi from "../../../../assets/images/mock/settings/wifi.jpg";
import { useFetchWithoutPagination } from "../../../../hooks/useFetchWithoutPagination";
import useNoti from "../../../../hooks/useNoti";
import {
  AppNotifications,
  AreaOutputModel,
  ErrorMessage,
  FormUploadImageModel,
  OPCTagOutPutModal,
  SiteInputModel,
  SiteStatus,
  TypeAnimal,
  UploadedImageModel,
} from "../../../../models";
import { Nullable } from "../../../../models/shared";
import { AreaService, DeviceService, OPCTagService } from "../../../../services";
import { SiteService } from "../../../../services/site.service";
import { FormUtil } from "../../../../utils/form.util";
import { FormUIUtil } from "../../../../utils/formUI.util";
import { checkLinkImage } from "../../../../utils/other.util";
import ImageUpload from "../../../components/ImageUpload";
import ModalFooter from "../../../components/ModalFooter";
import SelectInput from "../../../components/SelectInput";
import SimpleModalHeader from "../../../components/SimpleModalHeader";
import DefaultSwitch from "../../../components/Switch";
import { REGEX } from "../../../constants";
import { APP_ROUTES } from "../../../constants/module";
import "./index.scss";

type P_Props = {
  closeForm: () => void;
  data?: any;
};

const SiteModal: React.FC<P_Props> = ({ closeForm, data }) => {
  const [pushNoti] = useNoti();
  const history = useHistory();
  const mounted = useRef(false);
  const [status, setStatus] = useState<boolean>(
    data && data.status === SiteStatus.UNOFFICIAL ? false : true
  );
  const [animal, setAnimal] = useState<string>(data ? data.animalType?.id : "");
  const [areaData, setAreaData] = useState<Nullable<any>>(null);
  const [areDataListValue, setAreDataListValue] = useState<number[]>(
    // Map from API detail
    data
      ? data.areas.map((area: AreaOutputModel) => {
          return area.id;
        })
      : []
  );
  const [submitErrorAnimalType, setSubmitErrorAnimalType] = useState<boolean>(false);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const fetchDataAreaWithoutSiteList = async (query: any) => {
    try {
      const result = await AreaService.getAreaWithoutSite(query);
      if (mounted.current) {
        setAreaData(result.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchDataAreaWithoutSiteList({
      page: 1,
      limit: 500,
      id: data ? data.id : data,
    });
    // eslint-disable-next-line
  }, []);

  const { data: animalType } = useFetchWithoutPagination(SiteService.getAnimalType);

  const { data: deviceTypeData } = useFetchWithoutPagination(DeviceService.getTypeDevice);

  const [OPCTag, setOPCTag] = useState<Nullable<OPCTagOutPutModal[]>>(null);

  const fetchDataOPCTag = async (query: any) => {
    try {
      const result = await OPCTagService.getAllOPCTag(query);
      if (mounted.current) {
        setOPCTag(result.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchDataOPCTag({
      page: 1,
      limit: 500,
    });
    // eslint-disable-next-line
  }, []);

  const [avatar, setAvatar] = useState<Array<FormUploadImageModel & { file: any }>>([]);

  /* Update Site */
  useEffect(() => {
    setAvatar(
      data?.avatar
        ? [
            {
              id: "",
              src: `${checkLinkImage(data?.avatar)}`,
              alt: "",
              file: null,
            },
          ]
        : []
    );
  }, [data]);

  const createSite = (data: any) => {
    SiteService.createSite(data)
      .then(async (res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          pushNoti(AppNotifications.SUCCESS, "Tạo trại thành công!");
          history.push(APP_ROUTES.SITE);

          //OPC server
          try {
            await fetch(`https://api-pigfarm.exceltech.vn/opc-api/monitor-item`, {
              method: "post",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
            });
          } catch (error) {
            console.log(error);
          }
        } else if (res && res.status === 400) {
          if (res?.data.message === ErrorMessage.SITE_ALREADY_EXISTS) {
            pushNoti(AppNotifications.ERROR, "Trại đã tồn tại với code");
          } else if (res?.data.message === ErrorMessage.ANIMAL_TYPE_NOT_FOUND) {
            pushNoti(AppNotifications.ERROR, "Không tìm thấy loại vật nuôi");
          } else if (res?.data.message === ErrorMessage.AREA_NOT_FOUND) {
            pushNoti(AppNotifications.ERROR, "Không tìm thấy chuồng");
          } else if (res?.data.message === ErrorMessage.THE_AREA_BELONGS_TO_ANOTHER_SITE) {
            pushNoti(AppNotifications.ERROR, "Chuồng được phân bổ đã thuộc trại khác");
          } else if (
            res?.data.message ===
            ErrorMessage.CANNOT_ASSIGN_MULTIPLE_AREA_WITH_THE_SAME_CODE_AND_TYPE_TO_ONE_SITE
          ) {
            pushNoti(
              AppNotifications.ERROR,
              " Không được phép phân bổ nhiều chuồng có cùng mã và cùng loại vào cùng một trại "
            );
          } else {
            pushNoti(AppNotifications.ERROR, "Tạo tài khoản thất bại!");
          }
        }
      })
      .catch((err) => {
        pushNoti(AppNotifications.ERROR, "Tạo trại thất bại!");
        console.log(err);
      });
  };

  const updateSite = (data: any) => {
    SiteService.updateSite(data)
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          pushNoti(AppNotifications.SUCCESS, "Chỉnh sửa trại thành công!");
          history.push(APP_ROUTES.SITE);
        } else if (res && res.status === 400) {
          if (res?.data.message === ErrorMessage.SITE_ALREADY_EXISTS) {
            pushNoti(AppNotifications.ERROR, "Trại đã tồn tại với code");
          } else if (res?.data.message === ErrorMessage.ANIMAL_TYPE_NOT_FOUND) {
            pushNoti(AppNotifications.ERROR, "Không tìm thấy loại vật nuôi");
          } else if (res?.data.message === ErrorMessage.AREA_NOT_FOUND) {
            pushNoti(AppNotifications.ERROR, "Không tìm thấy chuồng");
          } else if (res?.data.message === ErrorMessage.THE_AREA_BELONGS_TO_ANOTHER_SITE) {
            pushNoti(AppNotifications.ERROR, "Chuồng được phân bổ đã thuộc trại khác");
          } else if (
            res?.data.message ===
            ErrorMessage.CANNOT_ASSIGN_MULTIPLE_AREA_WITH_THE_SAME_CODE_AND_TYPE_TO_ONE_SITE
          ) {
            pushNoti(
              AppNotifications.ERROR,
              "Không được phép phân bổ nhiều chuồng có cùng mã và cùng loại vào cùng một trại"
            );
          } else {
            pushNoti(AppNotifications.ERROR, "Chỉnh sửa trại thất bại!");
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const submitForm = async (values: any) => {
    if (animal === "") {
      return;
    }
    const val = {
      id: data ? data?.id : undefined,
      avatar: avatar[0] && avatar[0].file ? avatar[0].file : undefined,
      name: values.name,
      volume: values.volume ? values.volume : 0,
      code: values.code,
      animalTypeId: animal,
      address: values.address,
      personInCharge: values.personInCharge ? values.personInCharge : "",
      contact: values.contact ? values.contact : "",
      status: !status ? SiteStatus.UNOFFICIAL : SiteStatus.OFFICIAL,
      areaIds: areDataListValue,
      /*code dỏm */
      parameterSettings: [
        {
          maxCount: values.maxCountFan,
          maxTime: values.maxTimeFan,
          deviceTypeId: deviceTypeData && deviceTypeData?.find((f: any) => f.name === "Quạt")?.id,
        },
        {
          maxCount: values.maxCountBulb,
          maxTime: values.maxTimeBulb,
          deviceTypeId: deviceTypeData && deviceTypeData?.find((f: any) => f.name === "Đèn")?.id,
        },
        {
          maxCount: values.maxCountWaterWell,
          maxTime: values.maxTimeWaterWell,
          deviceTypeId: deviceTypeData && deviceTypeData?.find((f: any) => f.name === "Bơm")?.id,
        },
        {
          maxCount: values.maxCountWell,
          maxTime: values.maxTimeWell,
          deviceTypeId: deviceTypeData && deviceTypeData?.find((f: any) => f.name === "Cho ăn")?.id,
        },
      ],
      errorSettings: [
        {
          maxCount: values.maxCountBolt,
          maxTime: values.maxTimeBolt,
          opcTagId: OPCTag && OPCTag?.find((f: any) => f.code === "Alarm_PowerStatus")?.id,
        },
        {
          maxCount: values.maxCountWifi,
          maxTime: values.maxTimeWifi,
          opcTagId: OPCTag && OPCTag?.find((f: any) => f.code === "Alarm_CommStatus")?.id,
        },
      ],
    } as any;

    const formData = new FormData();
    Object.keys(val).forEach((key) => {
      if (key === "avatar") {
        // console.log(key, val[key], val[key] instanceof File);
      }
      let value;
      if (typeof val[key] === "string") {
        value = val[key];
      } else if (typeof val[key] === "number") {
        value = +val[key];
      } else if (Array.isArray(val[key])) {
        value = JSON.stringify(val[key]);
      } else if (val[key] instanceof File) {
        value = val[key];
      }
      if (value !== undefined) {
        formData.append(key, value);
      }
    });

    if (data && data?.id) return updateSite(formData);

    return createSite(formData);
  };

  /*code dỏm */
  const initialValues = data && {
    ...data,
    maxCountFan: data.parameterSettings?.[0]?.maxCount,
    maxTimeFan: data.parameterSettings?.[0]?.maxTime,
    maxCountBulb: data.parameterSettings?.[1]?.maxCount,
    maxTimeBulb: data.parameterSettings?.[1]?.maxTime,
    maxCountWaterWell: data.parameterSettings?.[2]?.maxCount,
    maxTimeWaterWell: data.parameterSettings?.[2]?.maxTime,
    maxCountWell: data.parameterSettings?.[3]?.maxCount,
    maxTimeWell: data.parameterSettings?.[3]?.maxTime,
    maxCountBolt: data.errorSettings?.[0]?.maxCount,
    maxTimeBolt: data.errorSettings?.[0]?.maxTime,
    maxCountWifi: data.errorSettings?.[1]?.maxCount,
    maxTimeWifi: data.errorSettings?.[1]?.maxTime,
  };

  return (
    <Dialog fullScreen open={true}>
      <div className="site-modal">
        <SimpleModalHeader
          title={data ? "Chỉnh sửa trại" : "Tạo mới trại"}
          onClose={() => (data ? closeForm() : history.push(APP_ROUTES.SITE))}
        />
        <div className="content">
          <Form
            onSubmit={async (values: SiteInputModel) => submitForm(values)}
            initialValues={initialValues}>
            {({ handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit} id="site-form" className="site-modal-form">
                  <Grid
                    container
                    columnSpacing={6}
                    style={{
                      marginTop: "6rem",
                      paddingLeft: data ? undefined : "8.6rem",
                      maxWidth: data ? "100rem" : undefined,
                    }}>
                    <Grid item xs={12} display="flex" justifyContent="center">
                      <ImageUpload
                        className="product-form-avatar"
                        fileList={avatar}
                        max={1}
                        onChange={(data: UploadedImageModel) => {
                          setAvatar([
                            {
                              id: uuidv4(),
                              src: data.fileStr,
                              alt: data.alt || "",
                              file: data.file,
                            },
                          ]);
                        }}
                        onDelete={() => {
                          setAvatar([]);
                        }}
                        config={{ uploadModal: true }}
                      />
                    </Grid>
                    <Grid item xs={6} className="title">
                      Thông tin trại
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: "end" }}>
                      <DefaultSwitch
                        checked={status}
                        onChange={() => {
                          setStatus(!status);
                        }}
                      />
                      {status ? (
                        <span style={{ fontSize: "1.6rem", color: "#41AD49" }}>
                          Trại chính thức
                        </span>
                      ) : (
                        <span style={{ fontSize: "1.6rem", color: "#ADACAC" }}>
                          Trại chưa chính thức
                        </span>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <Field name="code" validate={FormUtil.Rule.required("Nhập Mã trại")}>
                        {({ input, meta, ...rest }) => {
                          return FormUIUtil.renderFormItem(
                            "OPC ID",
                            <TextField
                              {...input}
                              {...rest}
                              fullWidth
                              disabled={data ? true : false}
                              placeholder={"Nhập mã trại"}
                              color="success"
                              className="form-text-field"
                              inputProps={{ className: "input" }}
                              variant="outlined"
                              onChange={(e) => input.onChange(e.target.value)}
                              helperText={meta.touched ? meta.error : ""}
                              error={meta.error && meta.touched}
                            />
                          );
                        }}
                      </Field>
                    </Grid>
                    <Grid item xs={6}>
                      <Field name="name" validate={FormUtil.Rule.required("Xin nhập Tên trại ")}>
                        {({ input, meta, ...rest }) => {
                          return FormUIUtil.renderFormItem(
                            "Tên trại ",
                            <TextField
                              {...input}
                              {...rest}
                              fullWidth
                              placeholder={"Nhập tên trại"}
                              color="success"
                              className="form-text-field"
                              inputProps={{ className: "input" }}
                              variant="outlined"
                              onChange={(e) => input.onChange(e.target.value)}
                              helperText={meta.touched ? meta.error : ""}
                              error={meta.error && meta.touched}
                            />
                          );
                        }}
                      </Field>
                    </Grid>
                    <Grid item xs={6}>
                      {FormUIUtil.renderFormItem(
                        "Loại vật nuôi",
                        <FormControl fullWidth>
                          <Select
                            fullWidth
                            value={animal}
                            onChange={(e) => {
                              setAnimal(e.target.value as string);
                            }}
                            input={<OutlinedInput className="form-text-field" color="success" />}
                            renderValue={
                              animal !== ""
                                ? undefined
                                : () => (
                                    <span className="form__placeholder">Chọn loại vật nuôi</span>
                                  )
                            }
                            onBlur={(e) => {
                              setSubmitErrorAnimalType(animal === "");
                            }}
                            variant="outlined"
                            className="form-select-field"
                            error={submitErrorAnimalType}
                            displayEmpty
                            MenuProps={{
                              className: "select-menu-list",
                            }}>
                            {animalType?.length &&
                              animalType.map((r: TypeAnimal) => {
                                return (
                                  <MenuItem key={r.id} value={r.id}>
                                    {r.name}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                          <FormHelperText>
                            {submitErrorAnimalType && "Chọn loại vật nuôi"}
                          </FormHelperText>
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        name="volume"
                        validate={FormUtil.composeValidators([
                          // FormUtil.Rule.required("Xin nhập số lượng"),
                          FormUtil.Rule.pattern(REGEX.NUMBER_ONLY, {
                            errorMessage: "Số lượng không đúng định dạng",
                          }),
                        ])}>
                        {({ input, meta, ...rest }) => {
                          return FormUIUtil.renderFormItem(
                            "Số lượng",
                            <TextField
                              {...input}
                              {...rest}
                              fullWidth
                              placeholder="Số vật nuôi ở mỗi chuồng"
                              color="success"
                              className="form-text-field"
                              inputProps={{ className: "input" }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <div style={{ fontFamily: "SVN-Gotham", fontSize: "1.6rem" }}>
                                      con
                                    </div>
                                  </InputAdornment>
                                ),
                              }}
                              variant="outlined"
                              onChange={(e) => input.onChange(e.target.value)}
                              helperText={meta.touched ? meta.error : ""}
                              error={meta.error && meta.touched}
                            />
                          );
                        }}
                      </Field>
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        name="personInCharge"
                        // validate={FormUtil.Rule.required("Xin nhập người quản lý")}
                      >
                        {({ input, meta, ...rest }) => {
                          return FormUIUtil.renderFormItem(
                            "Người quản lý ",
                            <TextField
                              {...input}
                              {...rest}
                              fullWidth
                              placeholder={"Nhập người quản lý.."}
                              color="success"
                              className="form-text-field"
                              inputProps={{ className: "input" }}
                              variant="outlined"
                              onChange={(e) => input.onChange(e.target.value)}
                              helperText={meta.touched ? meta.error : ""}
                              error={meta.error && meta.touched}
                            />
                          );
                        }}
                      </Field>
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        name="contact"
                        // validate={FormUtil.Rule.required("Xin nhập SĐT liên lạc ")}
                      >
                        {({ input, meta, ...rest }) => {
                          return FormUIUtil.renderFormItem(
                            "Liên lạc ",
                            <TextField
                              {...input}
                              {...rest}
                              fullWidth
                              placeholder={"Nhập số điện thoại liên hệ"}
                              color="success"
                              className="form-text-field"
                              inputProps={{ className: "input" }}
                              variant="outlined"
                              onChange={(e) => input.onChange(e.target.value)}
                              helperText={meta.touched ? meta.error : ""}
                              error={meta.error && meta.touched}
                            />
                          );
                        }}
                      </Field>
                    </Grid>
                    <Grid item xs={6}>
                      {FormUIUtil.renderFormItem(
                        "Chuồng",
                        <SelectInput<AreaOutputModel>
                          className={"item-textfield"}
                          icon={<SearchIcon />}
                          // onClick={() => setSubmitErrorArea(areDataListValue?.length === 0)}
                          placeholder="Chọn chuồng"
                          getOptionLabel={(opt: AreaOutputModel) => opt.name}
                          renderOption={(option: AreaOutputModel) => (
                            <>
                              <button
                                disabled={
                                  areDataListValue.some((e) => e === option.id) ||
                                  option.OPCSlot === ""
                                }
                                key={option.id}
                                className="area-item-menu-list"
                                onClick={() => {
                                  setAreDataListValue([...areDataListValue, option.id]);
                                }}>
                                <div
                                  style={{
                                    display: "flex",
                                    fontFamily: "SVN-Gotham",
                                  }}>
                                  <span style={{ color: "#B4B9C3" }}>
                                    {option.code} &#9679;&ensp;
                                  </span>
                                  {option.name}
                                </div>
                                {option.OPCSlot === "" && (
                                  <div
                                    style={{
                                      fontFamily: "SVN-Gotham",
                                      color: "#B8292F",
                                      fontSize: "1.2rem",
                                      fontWeight: 400,
                                      paddingTop: "1rem",
                                    }}>
                                    * Chưa khai báo OPC slot
                                  </div>
                                )}
                              </button>
                              <Divider style={{ margin: "1rem 0" }} />
                            </>
                          )}
                          actionConfig={{
                            action: AreaService.getAreaWithoutSite,
                          }}
                          id={data?.id}
                        />
                      )}
                      {areDataListValue.map((area: number) => {
                        const areaVal =
                          !!areaData &&
                          areaData.find((value: AreaOutputModel) => value.id === area);
                        const dataAreaVal =
                          data && data.areas.find((value: AreaOutputModel) => value.id === area);
                        return (
                          <div key={area} className="area-item">
                            <div className="name">
                              <span
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "flex-start",
                                  fontFamily: "SVN-Gotham",
                                  color: "#B4B9C3",
                                }}>
                                {data ? dataAreaVal?.code || areaVal?.code : areaVal?.code}
                                &ensp;&#9679;&ensp;
                              </span>
                              {data ? dataAreaVal?.name || areaVal?.name : areaVal?.name}
                            </div>
                            {!dataAreaVal && (
                              <ClearIcon
                                onClick={() =>
                                  setAreDataListValue(
                                    areDataListValue.filter((e) => e !== areaVal?.id)
                                  )
                                }
                                style={{
                                  fontSize: "1.8rem",
                                  fontWeight: "bold",
                                  cursor: "pointer",
                                }}
                              />
                            )}
                          </div>
                        );
                      })}
                    </Grid>

                    <Grid item xs={6}>
                      <Field name="address" validate={FormUtil.Rule.required("Xin nhập địa chỉ ")}>
                        {({ input, meta, ...rest }) => {
                          return FormUIUtil.renderFormItem(
                            "Địa chỉ ",
                            <TextField
                              {...input}
                              {...rest}
                              fullWidth
                              placeholder={"Nhập địa chỉ"}
                              color="success"
                              className="form-text-field"
                              inputProps={{ className: "input" }}
                              variant="outlined"
                              onChange={(e) => input.onChange(e.target.value)}
                              helperText={meta.touched ? meta.error : ""}
                              error={meta.error && meta.touched}
                            />
                          );
                        }}
                      </Field>
                    </Grid>
                  </Grid>
                  {!data && (
                    <>
                      {" "}
                      <Divider orientation="vertical" style={{ margin: "0 9rem" }} />
                      <Grid
                        container
                        columnSpacing={6}
                        style={{
                          maxHeight: "calc(100vh - 25rem)",
                          overflow: "auto",
                          marginTop: "6rem",
                          paddingRight: "8.6rem",
                        }}>
                        <Grid item xs={12} className="title" style={{ paddingBottom: "2.4rem" }}>
                          Khai báo lỗi thiết bị
                        </Grid>
                        <Grid item xs={12} className="label">
                          <img src={Fan} alt="fan" /> Quạt
                        </Grid>
                        <Grid item xs={6}>
                          <Field
                            name="maxCountFan"
                            validate={FormUtil.Rule.required("Nhập số lượng lỗi tối đa cho phép")}>
                            {({ input, meta, ...rest }) => {
                              return FormUIUtil.renderFormItem(
                                "Số lượng lỗi tối đa cho phép",
                                <TextField
                                  {...input}
                                  {...rest}
                                  fullWidth
                                  placeholder={"Nhập số lỗi"}
                                  type="number"
                                  color="success"
                                  className="form-text-field"
                                  inputProps={{ className: "input" }}
                                  variant="outlined"
                                  onChange={(e) => input.onChange(e.target.value)}
                                  helperText={meta.touched ? meta.error : ""}
                                  error={meta.error && meta.touched}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment
                                        position="end"
                                        style={{ fontFamily: "SVN-Gotham" }}>
                                        lỗi
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              );
                            }}
                          </Field>
                        </Grid>
                        <Grid item xs={6}>
                          <Field
                            name="maxTimeFan"
                            validate={FormUtil.Rule.required(
                              "Nhập thời gian xử lý lỗi tối đa cho phép"
                            )}>
                            {({ input, meta, ...rest }) => {
                              return FormUIUtil.renderFormItem(
                                "Thời gian xử lý lỗi tối đa cho phép",
                                <TextField
                                  {...input}
                                  {...rest}
                                  fullWidth
                                  placeholder={"Nhập thời gian"}
                                  type="number"
                                  color="success"
                                  className="form-text-field"
                                  inputProps={{ className: "input" }}
                                  variant="outlined"
                                  onChange={(e) => input.onChange(e.target.value)}
                                  helperText={meta.touched ? meta.error : ""}
                                  error={meta.error && meta.touched}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment
                                        position="end"
                                        style={{ fontFamily: "SVN-Gotham" }}>
                                        giây
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              );
                            }}
                          </Field>
                        </Grid>
                        <Grid item xs={12} className="label">
                          <img src={Bulb} alt="fan" /> Đèn
                        </Grid>
                        <Grid item xs={6}>
                          <Field
                            name="maxCountBulb"
                            validate={FormUtil.Rule.required("Nhập số lượng lỗi tối đa cho phép")}>
                            {({ input, meta, ...rest }) => {
                              return FormUIUtil.renderFormItem(
                                "Số lượng lỗi tối đa cho phép",
                                <TextField
                                  {...input}
                                  {...rest}
                                  fullWidth
                                  placeholder={"Nhập số lỗi"}
                                  type="number"
                                  color="success"
                                  className="form-text-field"
                                  inputProps={{ className: "input" }}
                                  variant="outlined"
                                  onChange={(e) => input.onChange(e.target.value)}
                                  helperText={meta.touched ? meta.error : ""}
                                  error={meta.error && meta.touched}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment
                                        position="end"
                                        style={{ fontFamily: "SVN-Gotham" }}>
                                        lỗi
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              );
                            }}
                          </Field>
                        </Grid>
                        <Grid item xs={6}>
                          <Field
                            name="maxTimeBulb"
                            validate={FormUtil.Rule.required(
                              "Nhập thời gian xử lý lỗi tối đa cho phép"
                            )}>
                            {({ input, meta, ...rest }) => {
                              return FormUIUtil.renderFormItem(
                                "Thời gian xử lý lỗi tối đa cho phép",
                                <TextField
                                  {...input}
                                  {...rest}
                                  fullWidth
                                  placeholder={"Nhập thời gian"}
                                  type="number"
                                  color="success"
                                  className="form-text-field"
                                  inputProps={{ className: "input" }}
                                  variant="outlined"
                                  onChange={(e) => input.onChange(e.target.value)}
                                  helperText={meta.touched ? meta.error : ""}
                                  error={meta.error && meta.touched}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment
                                        position="end"
                                        style={{ fontFamily: "SVN-Gotham" }}>
                                        giây
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              );
                            }}
                          </Field>
                        </Grid>
                        <Grid item xs={12} className="label">
                          <img src={WaterWell} alt="fan" /> Bơm
                        </Grid>
                        <Grid item xs={6}>
                          <Field
                            name="maxCountWaterWell"
                            validate={FormUtil.Rule.required("Nhập số lượng lỗi tối đa cho phép")}>
                            {({ input, meta, ...rest }) => {
                              return FormUIUtil.renderFormItem(
                                "Số lượng lỗi tối đa cho phép",
                                <TextField
                                  {...input}
                                  {...rest}
                                  fullWidth
                                  placeholder={"Nhập số lỗi"}
                                  type="number"
                                  color="success"
                                  className="form-text-field"
                                  inputProps={{ className: "input" }}
                                  variant="outlined"
                                  onChange={(e) => input.onChange(e.target.value)}
                                  helperText={meta.touched ? meta.error : ""}
                                  error={meta.error && meta.touched}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment
                                        position="end"
                                        style={{ fontFamily: "SVN-Gotham" }}>
                                        lỗi
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              );
                            }}
                          </Field>
                        </Grid>
                        <Grid item xs={6}>
                          <Field
                            name="maxTimeWaterWell"
                            validate={FormUtil.Rule.required(
                              "Nhập thời gian xử lý lỗi tối đa cho phép"
                            )}>
                            {({ input, meta, ...rest }) => {
                              return FormUIUtil.renderFormItem(
                                "Thời gian xử lý lỗi tối đa cho phép",
                                <TextField
                                  {...input}
                                  {...rest}
                                  fullWidth
                                  placeholder={"Nhập thời gian"}
                                  type="number"
                                  color="success"
                                  className="form-text-field"
                                  inputProps={{ className: "input" }}
                                  variant="outlined"
                                  onChange={(e) => input.onChange(e.target.value)}
                                  helperText={meta.touched ? meta.error : ""}
                                  error={meta.error && meta.touched}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment
                                        position="end"
                                        style={{ fontFamily: "SVN-Gotham" }}>
                                        giây
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              );
                            }}
                          </Field>
                        </Grid>
                        <Grid item xs={12} className="label">
                          <img src={Well} alt="fan" /> Cho ăn
                        </Grid>
                        <Grid item xs={6}>
                          <Field
                            name="maxCountWell"
                            validate={FormUtil.Rule.required("Nhập số lượng lỗi tối đa cho phép")}>
                            {({ input, meta, ...rest }) => {
                              return FormUIUtil.renderFormItem(
                                "Số lượng lỗi tối đa cho phép",
                                <TextField
                                  {...input}
                                  {...rest}
                                  fullWidth
                                  placeholder={"Nhập số lỗi"}
                                  type="number"
                                  color="success"
                                  className="form-text-field"
                                  inputProps={{ className: "input" }}
                                  variant="outlined"
                                  onChange={(e) => input.onChange(e.target.value)}
                                  helperText={meta.touched ? meta.error : ""}
                                  error={meta.error && meta.touched}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment
                                        position="end"
                                        style={{ fontFamily: "SVN-Gotham" }}>
                                        lỗi
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              );
                            }}
                          </Field>
                        </Grid>
                        <Grid item xs={6}>
                          <Field
                            name="maxTimeWell"
                            validate={FormUtil.Rule.required(
                              "Nhập thời gian xử lý lỗi tối đa cho phép"
                            )}>
                            {({ input, meta, ...rest }) => {
                              return FormUIUtil.renderFormItem(
                                "Thời gian xử lý lỗi tối đa cho phép",
                                <TextField
                                  {...input}
                                  {...rest}
                                  fullWidth
                                  placeholder={"Nhập thời gian"}
                                  type="number"
                                  color="success"
                                  className="form-text-field"
                                  inputProps={{ className: "input" }}
                                  variant="outlined"
                                  onChange={(e) => input.onChange(e.target.value)}
                                  helperText={meta.touched ? meta.error : ""}
                                  error={meta.error && meta.touched}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment
                                        position="end"
                                        style={{ fontFamily: "SVN-Gotham" }}>
                                        giây
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              );
                            }}
                          </Field>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          className="title"
                          style={{ paddingBottom: "2.4rem", paddingTop: "2.6rem" }}>
                          Khai báo lỗi hệ thống
                        </Grid>
                        <Grid item xs={12} className="label">
                          <img src={Bolt} alt="fan" /> Lỗi mất điện
                        </Grid>
                        <Grid item xs={6}>
                          <Field
                            name="maxCountBolt"
                            validate={FormUtil.Rule.required("Nhập số lượng lỗi tối đa cho phép")}>
                            {({ input, meta, ...rest }) => {
                              return FormUIUtil.renderFormItem(
                                "Số lượng lỗi tối đa cho phép",
                                <TextField
                                  {...input}
                                  {...rest}
                                  fullWidth
                                  placeholder={"Nhập số lỗi"}
                                  type="number"
                                  color="success"
                                  className="form-text-field"
                                  inputProps={{ className: "input" }}
                                  variant="outlined"
                                  onChange={(e) => input.onChange(e.target.value)}
                                  helperText={meta.touched ? meta.error : ""}
                                  error={meta.error && meta.touched}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment
                                        position="end"
                                        style={{ fontFamily: "SVN-Gotham" }}>
                                        lỗi
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              );
                            }}
                          </Field>
                        </Grid>
                        <Grid item xs={6}>
                          <Field
                            name="maxTimeBolt"
                            validate={FormUtil.Rule.required(
                              "Nhập thời gian xử lý lỗi tối đa cho phép"
                            )}>
                            {({ input, meta, ...rest }) => {
                              return FormUIUtil.renderFormItem(
                                "Thời gian xử lý lỗi tối đa cho phép",
                                <TextField
                                  {...input}
                                  {...rest}
                                  fullWidth
                                  placeholder={"Nhập thời gian"}
                                  type="number"
                                  color="success"
                                  className="form-text-field"
                                  inputProps={{ className: "input" }}
                                  variant="outlined"
                                  onChange={(e) => input.onChange(e.target.value)}
                                  helperText={meta.touched ? meta.error : ""}
                                  error={meta.error && meta.touched}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment
                                        position="end"
                                        style={{ fontFamily: "SVN-Gotham" }}>
                                        giây
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              );
                            }}
                          </Field>
                        </Grid>
                        <Grid item xs={12} className="label">
                          <img src={Wifi} alt="fan" /> Lỗi kết nối
                        </Grid>
                        <Grid item xs={6}>
                          <Field
                            name="maxCountWifi"
                            validate={FormUtil.Rule.required("Nhập số lượng lỗi tối đa cho phép")}>
                            {({ input, meta, ...rest }) => {
                              return FormUIUtil.renderFormItem(
                                "Số lượng lỗi tối đa cho phép",
                                <TextField
                                  {...input}
                                  {...rest}
                                  fullWidth
                                  placeholder={"Nhập số lỗi"}
                                  type="number"
                                  color="success"
                                  className="form-text-field"
                                  inputProps={{ className: "input" }}
                                  variant="outlined"
                                  onChange={(e) => input.onChange(e.target.value)}
                                  helperText={meta.touched ? meta.error : ""}
                                  error={meta.error && meta.touched}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment
                                        position="end"
                                        style={{ fontFamily: "SVN-Gotham" }}>
                                        lỗi
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              );
                            }}
                          </Field>
                        </Grid>
                        <Grid item xs={6}>
                          <Field
                            name="maxTimeWifi"
                            validate={FormUtil.Rule.required(
                              "Nhập thời gian xử lý lỗi tối đa cho phép"
                            )}>
                            {({ input, meta, ...rest }) => {
                              return FormUIUtil.renderFormItem(
                                "Thời gian xử lý lỗi tối đa cho phép",
                                <TextField
                                  {...input}
                                  {...rest}
                                  fullWidth
                                  placeholder={"Nhập thời gian"}
                                  type="number"
                                  color="success"
                                  className="form-text-field"
                                  inputProps={{ className: "input" }}
                                  variant="outlined"
                                  onChange={(e) => input.onChange(e.target.value)}
                                  helperText={meta.touched ? meta.error : ""}
                                  error={meta.error && meta.touched}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment
                                        position="end"
                                        style={{ fontFamily: "SVN-Gotham" }}>
                                        giây
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              );
                            }}
                          </Field>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </form>
              );
            }}
          </Form>
        </div>
        <ModalFooter
          formId="site-form"
          showDeleteButton={false}
          handleDeleteAction={() => {
            // toggleDeleteModal();
          }}
          handleCancelAction={() => history.push(APP_ROUTES.SITE)}
          title={data ? "Lưu" : "Tạo mới "}
          handleSubmit={() => {
            setSubmitErrorAnimalType(animal === "");
            // setSubmitErrorArea(areDataListValue?.length === 0);
          }}
        />
      </div>
    </Dialog>
  );
};

export default SiteModal;
