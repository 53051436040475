/* eslint-disable*/
// import moment from "moment";
import { AxiosResponse } from "axios";
import { BASE_LINK_IMAGE } from "../app/constants";
import Avatar from "../assets/images/avt.png";
import Pig from "../assets/images/pig.png";

export const loadCallback = <T extends (...P: any[]) => any>(
  cb?: T,
  ...data: Parameters<T>
): ReturnType<T> => {
  return cb && cb(...data);
};

/**
 * Hiện giá trị theo định dạng tiền tệ của US
 * @param number kiểu dữ liệu number
 */
export function currencyFormat(number: number) {
  // output: 5,000,000
  // return new Intl.NumberFormat("en-US").format(number);

  // output: 5.0000.000
  return new Intl.NumberFormat("en-DE").format(Math.round(number));
}

export function getFirstChar(str: string): string {
  return str.charAt(0);
}
// export function local_date_t(
//   key: string | null | undefined,
//   opts?: {
//     lang?: any;
//     format?: string;
//   }
// ): string {
//   if (!key) return "";

//   const tempLocale = moment(key);
//   tempLocale.locale("vi");

//   return tempLocale.format(opts?.format);
// }
export function convertTagString(
  tagStr: string | undefined | null,
  config?: {
    seperator?: string;
  }
): string[] {
  if (!tagStr) return [];

  const seperator = config?.seperator || "|";
  let tags: string[] = tagStr.split(seperator);

  tags = tags.filter((tag) => {
    return !!tag.trim();
  });

  return tags;
}

export function loadToPage(path: string) {
  window.location.href = path;
}

export function capitalizeFirstLetter(str: string): string {
  return getFirstChar(str).toUpperCase() + str.slice(1);
}

// export function currencyUnitFormat(
//   value: number,
//   currency: string,
//   hideSymbol?: boolean
// ) {
//   const foundCur = CurrencyOptions.find((e) => e.id === currency);
//   return foundCur
//     ? new Intl.NumberFormat(foundCur?.locale || "en-US", {
//         style: !hideSymbol ? "currency" : "decimal",
//         currency: !hideSymbol ? currency : undefined,
//         minimumFractionDigits: value % 1 === 0 ? 0 : 2,
//       }).format(value)
//     : "0";
// }

// export function getCurrencySymbol(currency: string) {
//   const value = currencyUnitFormat(0, currency);
//   return value.replace(/\d|\.|,/g, "").trim();
// }

export const listener = (type: string, handler: Function, target: any = window) => {
  target.addEventListener(type, handler, { passive: false });
  return () => {
    target?.removeEventListener(type, handler);
  };
};

export function checkLinkImage(link: string) {
  if (link === undefined || link === null) {
    return;
  }
  return BASE_LINK_IMAGE + link;
}

export const renderErrorImage = (title?: any) => {
  const setDefaultImageAvatar = (e: any) => {
    e.target.src = Avatar;
  };

  const setDefaultImage = (e: any) => {
    e.target.src = Pig;
  };

  switch (title) {
    case "avatar":
      return setDefaultImageAvatar;
    default:
      return setDefaultImage;
  }
};

// tự động get api phân trang đến hết
// thường dùng cho export data
// input đầu tiên lúc nào cũng là filter
// input params là những params còn lại của service khi gọi api
export async function autoLoadingPagination(
  action: (...P: any[]) => Promise<void | AxiosResponse<any>>,
  input: { filter: any; params?: any },
  inputPage?: number,
  inputPageSize?: number,
  data?: any
) {
  let returnData: any[] = data || [];
  const limit = inputPageSize || 10;
  const page = inputPage || 1;
  let payload: any;

  try {
    // thêm phân trang vào input filter
    const query = [{ ...input.filter, page, limit }, input.params];
    const res = await action(...query);

    if (res && res.status && res.status === 200) {
      if (page === 1) {
        returnData.length = 0;
      }

      returnData.length > 0 && returnData.length >= res.data.totalRecords
        ? returnData
        : returnData.push(...res.data.data);

      payload = res.data;

      return { returnData, payload };
    }
  } catch (e) {
    console.log(e);
    return [];
  }
}
