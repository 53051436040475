/*eslint-disable*/
import { Divider, Grid, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Field, Form } from "react-final-form";
import { useHistory } from "react-router-dom";
import Bolt from "../../../../../assets/images/mock/settings/bolt.jpg";
import Bulb from "../../../../../assets/images/mock/settings/bulb.jpg";
import Fan from "../../../../../assets/images/mock/settings/fan.jpg";
import WaterWell from "../../../../../assets/images/mock/settings/water-well.jpg";
import Well from "../../../../../assets/images/mock/settings/well.jpg";
import Wifi from "../../../../../assets/images/mock/settings/wifi.jpg";
import { useFetchWithoutPagination } from "../../../../../hooks/useFetchWithoutPagination";
import useNoti from "../../../../../hooks/useNoti";
import {
  AppNotifications,
  AreaInputModel,
  ErrorMessage,
  OPCTagOutPutModal,
} from "../../../../../models";
import { Nullable } from "../../../../../models/shared";
import { AreaService, DeviceService, OPCTagService } from "../../../../../services";
import { FormUIUtil } from "../../../../../utils";
import "./index.scss";

type P_Props = {
  setValStep: () => void;
  dataDetail: any;
};

const AreaSettingUpdate: React.FC<P_Props> = ({ setValStep, dataDetail }) => {
  const localStorageData = localStorage.getItem("dataUpdate");
  const data = JSON.parse(localStorageData as any);
  const localStorageDevice = localStorage.getItem("devicesUpdate");
  const dataDevice = JSON.parse(localStorageDevice as any);
  const localStorageSetting = localStorage.getItem("settingUpdate");
  const dataSetting = JSON.parse(localStorageSetting as any);
  const [pushNoti] = useNoti();
  const [saveLc, setSaveLc] = useState<boolean>(true);
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const { data: deviceTypeData } = useFetchWithoutPagination(DeviceService.getTypeDevice);

  const [OPCTag, setOPCTag] = useState<Nullable<OPCTagOutPutModal[]>>(null);

  const fetchDataOPCTag = async (query: any) => {
    try {
      const result = await OPCTagService.getAllOPCTag(query);
      if (mounted.current) {
        setOPCTag(result.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchDataOPCTag({
      page: 1,
      limit: 500,
    });
    // eslint-disable-next-line
  }, []);

  const updateArea = (val: any) => {
    AreaService.updateArea(val)
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          pushNoti(AppNotifications.SUCCESS, "Chỉnh sửa chuồng thành công!");
          window.location.reload();
          localStorage.removeItem("dataUpdate");
          localStorage.removeItem("devicesUpdate");
          localStorage.removeItem("settingUpdate");
        } else if (res && res.status === 400) {
          if (
            res?.data.message ===
            ErrorMessage.CANNOT_ASSIGN_MULTIPLE_DEVICES_WITH_THE_SAME_CODE_AND_TYPE_TO_ONE_AREA
          ) {
            pushNoti(
              AppNotifications.ERROR,
              "Không được gán nhiều thiết bị có cùng code và loại thiết bị vào cùng một trại"
            );
          } else if (res?.data.message === ErrorMessage.THE_DEVICE_BELONGS_TO_ANOTHER_AREA) {
            pushNoti(AppNotifications.ERROR, "Thiết bị đã được gán vào chuồng khác");
            // setSubmitErrorDupId(res?.data?.detail);
          } else if (res?.data.message === ErrorMessage.DEVICE_NOT_FOUND) {
            pushNoti(AppNotifications.ERROR, "Không tìm thấy thiết bị");
          } else if (res?.data.message === ErrorMessage.AREA_ALREADY_EXISTS) {
            pushNoti(AppNotifications.ERROR, "Chuồng đã tồn tại");
          } else if (res?.data.message === ErrorMessage.SITE_NOT_FOUND) {
            pushNoti(AppNotifications.ERROR, "Không tìm thấy trại");
          } else if (res?.data.message === ErrorMessage.AREA_TYPE_NOT_FOUND) {
            pushNoti(AppNotifications.ERROR, "Không tìm thấy loại chuồng");
          } else {
            pushNoti(AppNotifications.ERROR, "Chỉnh sửa chuồng thất bại!");
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const saveLocalForm = async (values: any) => {
    const val = {
      parameterSettings: [
        {
          maxCount: values.maxCountFan,
          maxTime: values.maxTimeFan,
          deviceTypeId: deviceTypeData && deviceTypeData?.find((f: any) => f.name === "Quạt")?.id,
        },
        {
          maxCount: values.maxCountBulb,
          maxTime: values.maxTimeBulb,
          deviceTypeId: deviceTypeData && deviceTypeData?.find((f: any) => f.name === "Đèn")?.id,
        },
        {
          maxCount: values.maxCountWaterWell,
          maxTime: values.maxTimeWaterWell,
          deviceTypeId: deviceTypeData && deviceTypeData?.find((f: any) => f.name === "Bơm")?.id,
        },
        {
          maxCount: values.maxCountWell,
          maxTime: values.maxTimeWell,
          deviceTypeId: deviceTypeData && deviceTypeData?.find((f: any) => f.name === "Cho ăn")?.id,
        },
      ],
      errorSettings: [
        {
          maxCount: values.maxCountBolt,
          maxTime: values.maxTimeBolt,
          opcTagId: OPCTag && OPCTag?.find((f: any) => f.code === "Alarm_PowerStatus")?.id,
        },
        {
          maxCount: values.maxCountWifi,
          maxTime: values.maxTimeWifi,
          opcTagId: OPCTag && OPCTag?.find((f: any) => f.code === "Alarm_CommStatus")?.id,
        },
      ],
    } as any;
    localStorage.setItem("settingUpdate", JSON.stringify(val));
    setValStep();
  };

  const submitForm = async (values: any) => {
    const val = {
      ...data,
      devices: dataDevice,
      parameterSettings: [
        {
          maxCount: values.maxCountFan,
          maxTime: values.maxTimeFan,
          deviceTypeId: deviceTypeData && deviceTypeData?.find((f: any) => f.name === "Quạt")?.id,
        },
        {
          maxCount: values.maxCountBulb,
          maxTime: values.maxTimeBulb,
          deviceTypeId: deviceTypeData && deviceTypeData?.find((f: any) => f.name === "Đèn")?.id,
        },
        {
          maxCount: values.maxCountWaterWell,
          maxTime: values.maxTimeWaterWell,
          deviceTypeId: deviceTypeData && deviceTypeData?.find((f: any) => f.name === "Bơm")?.id,
        },
        {
          maxCount: values.maxCountWell,
          maxTime: values.maxTimeWell,
          deviceTypeId: deviceTypeData && deviceTypeData?.find((f: any) => f.name === "Cho ăn")?.id,
        },
      ],
      errorSettings: [
        {
          maxCount: values.maxCountBolt,
          maxTime: values.maxTimeBolt,
          opcTagId: OPCTag && OPCTag?.find((f: any) => f.code === "Alarm_PowerStatus")?.id,
        },
        {
          maxCount: values.maxCountWifi,
          maxTime: values.maxTimeWifi,
          opcTagId: OPCTag && OPCTag?.find((f: any) => f.code === "Alarm_CommStatus")?.id,
        },
      ],
    } as any;

    const formData = new FormData();
    Object.keys(val).forEach((key) => {
      if (key === "avatar") {
        // console.log(key, val[key], val[key] instanceof File);
      }
      let value;
      if (typeof val[key] === "string") {
        value = val[key];
      } else if (typeof val[key] === "number") {
        value = +val[key];
      } else if (Array.isArray(val[key])) {
        value = JSON.stringify(val[key]);
      } else if (val[key] instanceof File) {
        value = val[key];
      }
      if (value !== undefined) {
        formData.append(key, value);
      }
    });

    if (dataDetail && dataDetail?.id) return updateArea(formData);
  };

  const dataMap = dataSetting ? dataSetting : dataDetail;

  /*code dỏm */
  const initialValues = dataMap && {
    maxCountFan: dataMap.parameterSettings?.[0]?.maxCount,
    maxTimeFan: dataMap.parameterSettings?.[0]?.maxTime,
    maxCountBulb: dataMap.parameterSettings?.[1]?.maxCount,
    maxTimeBulb: dataMap.parameterSettings?.[1]?.maxTime,
    maxCountWaterWell: dataMap.parameterSettings?.[2]?.maxCount,
    maxTimeWaterWell: dataMap.parameterSettings?.[2]?.maxTime,
    maxCountWell: dataMap.parameterSettings?.[3]?.maxCount,
    maxTimeWell: dataMap.parameterSettings?.[3]?.maxTime,
    maxCountBolt: dataMap.errorSettings?.[0]?.maxCount,
    maxTimeBolt: dataMap.errorSettings?.[0]?.maxTime,
    maxCountWifi: dataMap.errorSettings?.[1]?.maxCount,
    maxTimeWifi: dataMap.errorSettings?.[1]?.maxTime,
  };

  return (
    <div className="area-setting">
      <Form
        onSubmit={async (values: AreaInputModel) =>
          saveLc === true ? saveLocalForm(values) : submitForm(values)
        }
        initialValues={initialValues}>
        {({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit} id="area-form" className="area-setting-form">
              <div style={{ width: "58rem", marginTop: "9rem" }}>
                <div className="title">Khai báo lỗi thiết bị</div>
                <Grid container columnSpacing={6}>
                  <Grid item xs={12} className="label">
                    <img src={Fan} alt="fan" /> Quạt
                  </Grid>
                  <Grid item xs={6}>
                    <Field name="maxCountFan">
                      {({ input, meta, ...rest }) => {
                        return FormUIUtil.renderFormItem(
                          "Số lượng lỗi tối đa cho phép",
                          <TextField
                            {...input}
                            {...rest}
                            fullWidth
                            placeholder={"Nhập số lỗi"}
                            type="number"
                            color="success"
                            className="form-text-field"
                            inputProps={{ className: "input" }}
                            variant="outlined"
                            onChange={(e) => input.onChange(e.target.value)}
                            helperText={meta.touched ? meta.error : ""}
                            error={meta.error && meta.touched}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end" style={{ fontFamily: "SVN-Gotham" }}>
                                  lỗi
                                </InputAdornment>
                              ),
                            }}
                          />
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={6}>
                    <Field name="maxTimeFan">
                      {({ input, meta, ...rest }) => {
                        return FormUIUtil.renderFormItem(
                          "Thời gian xử lý lỗi tối đa cho phép",
                          <TextField
                            {...input}
                            {...rest}
                            fullWidth
                            placeholder={"Nhập thời gian"}
                            type="number"
                            color="success"
                            className="form-text-field"
                            inputProps={{ className: "input" }}
                            variant="outlined"
                            onChange={(e) => input.onChange(e.target.value)}
                            helperText={meta.touched ? meta.error : ""}
                            error={meta.error && meta.touched}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end" style={{ fontFamily: "SVN-Gotham" }}>
                                  giây
                                </InputAdornment>
                              ),
                            }}
                          />
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={12} className="label">
                    <img src={Bulb} alt="fan" /> Đèn
                  </Grid>
                  <Grid item xs={6}>
                    <Field name="maxCountBulb">
                      {({ input, meta, ...rest }) => {
                        return FormUIUtil.renderFormItem(
                          "Số lượng lỗi tối đa cho phép",
                          <TextField
                            {...input}
                            {...rest}
                            fullWidth
                            placeholder={"Nhập số lỗi"}
                            type="number"
                            color="success"
                            className="form-text-field"
                            inputProps={{ className: "input" }}
                            variant="outlined"
                            onChange={(e) => input.onChange(e.target.value)}
                            helperText={meta.touched ? meta.error : ""}
                            error={meta.error && meta.touched}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end" style={{ fontFamily: "SVN-Gotham" }}>
                                  lỗi
                                </InputAdornment>
                              ),
                            }}
                          />
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={6}>
                    <Field name="maxTimeBulb">
                      {({ input, meta, ...rest }) => {
                        return FormUIUtil.renderFormItem(
                          "Thời gian xử lý lỗi tối đa cho phép",
                          <TextField
                            {...input}
                            {...rest}
                            fullWidth
                            placeholder={"Nhập thời gian"}
                            type="number"
                            color="success"
                            className="form-text-field"
                            inputProps={{ className: "input" }}
                            variant="outlined"
                            onChange={(e) => input.onChange(e.target.value)}
                            helperText={meta.touched ? meta.error : ""}
                            error={meta.error && meta.touched}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end" style={{ fontFamily: "SVN-Gotham" }}>
                                  giây
                                </InputAdornment>
                              ),
                            }}
                          />
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={12} className="label">
                    <img src={WaterWell} alt="fan" /> Bơm
                  </Grid>
                  <Grid item xs={6}>
                    <Field name="maxCountWaterWell">
                      {({ input, meta, ...rest }) => {
                        return FormUIUtil.renderFormItem(
                          "Số lượng lỗi tối đa cho phép",
                          <TextField
                            {...input}
                            {...rest}
                            fullWidth
                            placeholder={"Nhập số lỗi"}
                            type="number"
                            color="success"
                            className="form-text-field"
                            inputProps={{ className: "input" }}
                            variant="outlined"
                            onChange={(e) => input.onChange(e.target.value)}
                            helperText={meta.touched ? meta.error : ""}
                            error={meta.error && meta.touched}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end" style={{ fontFamily: "SVN-Gotham" }}>
                                  lỗi
                                </InputAdornment>
                              ),
                            }}
                          />
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={6}>
                    <Field name="maxTimeWaterWell">
                      {({ input, meta, ...rest }) => {
                        return FormUIUtil.renderFormItem(
                          "Thời gian xử lý lỗi tối đa cho phép",
                          <TextField
                            {...input}
                            {...rest}
                            fullWidth
                            placeholder={"Nhập thời gian"}
                            type="number"
                            color="success"
                            className="form-text-field"
                            inputProps={{ className: "input" }}
                            variant="outlined"
                            onChange={(e) => input.onChange(e.target.value)}
                            helperText={meta.touched ? meta.error : ""}
                            error={meta.error && meta.touched}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end" style={{ fontFamily: "SVN-Gotham" }}>
                                  giây
                                </InputAdornment>
                              ),
                            }}
                          />
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={12} className="label">
                    <img src={Well} alt="fan" /> Cho ăn
                  </Grid>
                  <Grid item xs={6}>
                    <Field name="maxCountWell">
                      {({ input, meta, ...rest }) => {
                        return FormUIUtil.renderFormItem(
                          "Số lượng lỗi tối đa cho phép",
                          <TextField
                            {...input}
                            {...rest}
                            fullWidth
                            placeholder={"Nhập số lỗi"}
                            type="number"
                            color="success"
                            className="form-text-field"
                            inputProps={{ className: "input" }}
                            variant="outlined"
                            onChange={(e) => input.onChange(e.target.value)}
                            helperText={meta.touched ? meta.error : ""}
                            error={meta.error && meta.touched}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end" style={{ fontFamily: "SVN-Gotham" }}>
                                  lỗi
                                </InputAdornment>
                              ),
                            }}
                          />
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={6}>
                    <Field name="maxTimeWell">
                      {({ input, meta, ...rest }) => {
                        return FormUIUtil.renderFormItem(
                          "Thời gian xử lý lỗi tối đa cho phép",
                          <TextField
                            {...input}
                            {...rest}
                            fullWidth
                            placeholder={"Nhập thời gian"}
                            type="number"
                            color="success"
                            className="form-text-field"
                            inputProps={{ className: "input" }}
                            variant="outlined"
                            onChange={(e) => input.onChange(e.target.value)}
                            helperText={meta.touched ? meta.error : ""}
                            error={meta.error && meta.touched}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end" style={{ fontFamily: "SVN-Gotham" }}>
                                  giây
                                </InputAdornment>
                              ),
                            }}
                          />
                        );
                      }}
                    </Field>
                  </Grid>
                </Grid>
              </div>
              <Divider orientation="vertical" />
              <div style={{ width: "58rem", marginTop: "9rem" }}>
                <div className="title">Khai báo lỗi hệ thống</div>
                <Grid container columnSpacing={6}>
                  <Grid item xs={12} className="label">
                    <img src={Bolt} alt="fan" /> Lỗi mất điện
                  </Grid>
                  <Grid item xs={6}>
                    <Field name="maxCountBolt">
                      {({ input, meta, ...rest }) => {
                        return FormUIUtil.renderFormItem(
                          "Số lượng lỗi tối đa cho phép",
                          <TextField
                            {...input}
                            {...rest}
                            fullWidth
                            placeholder={"Nhập số lỗi"}
                            type="number"
                            color="success"
                            className="form-text-field"
                            inputProps={{ className: "input" }}
                            variant="outlined"
                            onChange={(e) => input.onChange(e.target.value)}
                            helperText={meta.touched ? meta.error : ""}
                            error={meta.error && meta.touched}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end" style={{ fontFamily: "SVN-Gotham" }}>
                                  lỗi
                                </InputAdornment>
                              ),
                            }}
                          />
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={6}>
                    <Field name="maxTimeBolt">
                      {({ input, meta, ...rest }) => {
                        return FormUIUtil.renderFormItem(
                          "Thời gian xử lý lỗi tối đa cho phép",
                          <TextField
                            {...input}
                            {...rest}
                            fullWidth
                            placeholder={"Nhập thời gian"}
                            type="number"
                            color="success"
                            className="form-text-field"
                            inputProps={{ className: "input" }}
                            variant="outlined"
                            onChange={(e) => input.onChange(e.target.value)}
                            helperText={meta.touched ? meta.error : ""}
                            error={meta.error && meta.touched}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end" style={{ fontFamily: "SVN-Gotham" }}>
                                  giây
                                </InputAdornment>
                              ),
                            }}
                          />
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={12} className="label">
                    <img src={Wifi} alt="fan" /> Lỗi kết nối
                  </Grid>
                  <Grid item xs={6}>
                    <Field name="maxCountWifi">
                      {({ input, meta, ...rest }) => {
                        return FormUIUtil.renderFormItem(
                          "Số lượng lỗi tối đa cho phép",
                          <TextField
                            {...input}
                            {...rest}
                            fullWidth
                            placeholder={"Nhập số lỗi"}
                            type="number"
                            color="success"
                            className="form-text-field"
                            inputProps={{ className: "input" }}
                            variant="outlined"
                            onChange={(e) => input.onChange(e.target.value)}
                            helperText={meta.touched ? meta.error : ""}
                            error={meta.error && meta.touched}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end" style={{ fontFamily: "SVN-Gotham" }}>
                                  lỗi
                                </InputAdornment>
                              ),
                            }}
                          />
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={6}>
                    <Field name="maxTimeWifi">
                      {({ input, meta, ...rest }) => {
                        return FormUIUtil.renderFormItem(
                          "Thời gian xử lý lỗi tối đa cho phép",
                          <TextField
                            {...input}
                            {...rest}
                            fullWidth
                            placeholder={"Nhập thời gian"}
                            type="number"
                            color="success"
                            className="form-text-field"
                            inputProps={{ className: "input" }}
                            variant="outlined"
                            onChange={(e) => input.onChange(e.target.value)}
                            helperText={meta.touched ? meta.error : ""}
                            error={meta.error && meta.touched}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end" style={{ fontFamily: "SVN-Gotham" }}>
                                  giây
                                </InputAdornment>
                              ),
                            }}
                          />
                        );
                      }}
                    </Field>
                  </Grid>
                </Grid>
              </div>
            </form>
          );
        }}
      </Form>

      <div className="modal-footer" style={{ justifyContent: "flex-end" }}>
        <input
          value={"Quay lại"}
          aria-label="submit supplier form"
          form={"area-form"}
          type="submit"
          style={{ background: "#ffffff", color: "#312f30", marginRight: "2rem" }}
        />
        <input
          onClick={() => {
            setSaveLc(false);
          }}
          value={"Lưu"}
          aria-label="submit supplier form"
          form={"area-form"}
          type="submit"
        />
      </div>
    </div>
  );
};

export default AreaSettingUpdate;
