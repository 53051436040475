import { IconBase, IconComponent } from "../../core/Icons";

export const SettingsIcon: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3392 5.35305L15.8205 4.45298C15.3817 3.69138 14.4092 3.42864 13.6466 3.86564V3.86564C13.2835 4.07949 12.8504 4.14017 12.4425 4.03428C12.0347 3.92839 11.6858 3.66464 11.4727 3.30118C11.3355 3.07016 11.2619 2.80704 11.2591 2.53841V2.53841C11.2714 2.10773 11.109 1.69038 10.8086 1.38143C10.5083 1.07249 10.0957 0.89826 9.66487 0.898438H8.61987C8.19776 0.898433 7.79305 1.06664 7.49528 1.36583C7.19752 1.66502 7.03126 2.07053 7.03329 2.49264V2.49264C7.02078 3.36415 6.31068 4.06405 5.43909 4.06396C5.17046 4.06117 4.90733 3.98749 4.67631 3.85038V3.85038C3.91367 3.41339 2.94121 3.67612 2.5024 4.43772L1.94557 5.35305C1.50729 6.1137 1.76645 7.08555 2.52528 7.52697V7.52697C3.01854 7.81174 3.32239 8.33803 3.32239 8.90759C3.32239 9.47715 3.01854 10.0034 2.52528 10.2882V10.2882C1.76742 10.7267 1.50797 11.6961 1.94557 12.4545V12.4545L2.47189 13.3622C2.67749 13.7332 3.02245 14.007 3.43045 14.1229C3.83844 14.2389 4.27582 14.1875 4.6458 13.9801V13.9801C5.00952 13.7678 5.44294 13.7097 5.84973 13.8185C6.25653 13.9274 6.60298 14.1942 6.81209 14.5598C6.9492 14.7908 7.02288 15.0539 7.02567 15.3225V15.3225C7.02567 16.203 7.73941 16.9167 8.61987 16.9167H9.66487C10.5424 16.9168 11.2549 16.2076 11.2591 15.3302V15.3302C11.257 14.9067 11.4243 14.5001 11.7238 14.2006C12.0232 13.9012 12.4298 13.7339 12.8533 13.736C13.1213 13.7431 13.3833 13.8165 13.6161 13.9495V13.9495C14.3767 14.3878 15.3485 14.1287 15.79 13.3698V13.3698L16.3392 12.4545C16.5517 12.0896 16.6101 11.655 16.5013 11.247C16.3925 10.839 16.1255 10.4912 15.7595 10.2806V10.2806C15.3934 10.07 15.1264 9.72219 15.0176 9.31416C14.9088 8.90613 14.9672 8.47156 15.1797 8.10668C15.318 7.86532 15.5181 7.66521 15.7595 7.52697V7.52697C16.5137 7.08579 16.7723 6.11962 16.3392 5.36068V5.36068V5.35305Z"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.14601 11.1045C10.3593 11.1045 11.3428 10.121 11.3428 8.90773C11.3428 7.69448 10.3593 6.71094 9.14601 6.71094C7.93276 6.71094 6.94922 7.69448 6.94922 8.90773C6.94922 10.121 7.93276 11.1045 9.14601 11.1045Z"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconBase>
  );
};
