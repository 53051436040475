import { RouteProps } from "react-router-dom";
import { APP_ROUTES } from "../../constants/module";
import ForgetPassword from "./ForgetPassword";
import OTPInput from "./OTPInput";
import ResetPassword from "./ResetPassword";

export const authRoute: Array<RouteProps> = [
  {
    exact: true,
    path: APP_ROUTES.FORGET_PASSWORD,
    component: ForgetPassword,
  },

  {
    exact: false,
    path: APP_ROUTES.RESET_PASSWORD,
    component: ResetPassword,
  },
  {
    exact: false,
    path: APP_ROUTES.OTP,
    component: OTPInput,
  },
];
