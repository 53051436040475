import React from "react";
import { IconBase, IconComponent } from "../../core/Icons";

export const AlarmIcon: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 0C13.6079 0 14.1006 0.492765 14.1006 1.10062V1.64755C18.6834 2.15263 22.3866 5.77544 22.9065 10.4539L23.5835 16.5474C23.5863 16.5723 23.595 16.5961 23.6089 16.617L25.0588 18.7919C26.211 20.5203 24.9721 22.8353 22.8949 22.8353H17.1876C17.0711 23.5608 16.8289 24.3121 16.4063 24.9706C15.7274 26.0284 14.6001 26.8034 13 26.8034C11.3999 26.8034 10.2726 26.0284 9.59368 24.9706C9.17109 24.3121 8.92893 23.5608 8.81243 22.8353H3.10508C1.02791 22.8353 -0.211049 20.5203 0.941162 18.7919L2.39114 16.617C2.40503 16.5961 2.41373 16.5723 2.4165 16.5474L3.09355 10.4539C3.61338 5.77543 7.3166 2.15263 11.8994 1.64755V1.10062C11.8994 0.492765 12.3921 0 13 0ZM11.0565 22.8353C11.1429 23.1855 11.2727 23.5112 11.4462 23.7817C11.7594 24.2696 12.2192 24.6021 13 24.6021C13.7808 24.6021 14.2406 24.2696 14.5538 23.7817C14.7273 23.5112 14.8571 23.1855 14.9435 22.8353H11.0565ZM3.10508 20.634C2.78603 20.634 2.59572 20.2784 2.77271 20.013L4.22268 17.838C4.43179 17.5243 4.56265 17.1651 4.60428 16.7904L5.28132 10.697C5.71833 6.76397 9.04275 3.78847 13 3.78847C16.9572 3.78847 20.2817 6.76398 20.7187 10.697L21.3957 16.7904C21.4374 17.1651 21.5682 17.5243 21.7773 17.838L23.2273 20.013C23.4043 20.2784 23.214 20.634 22.8949 20.634H3.10508Z"
        fill={props.color}
      />
    </IconBase>
  );
};
