import { IconBase, IconComponent } from "../../core/Icons";

export const ReportNullIcon: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <path
        d="M19.4976 2C18.9707 1.99968 18.453 2.1328 17.9966 2.38599C17.5401 2.63917 17.1611 3.00348 16.8976 3.44225C16.8315 3.55199 16.7886 3.67322 16.7715 3.79896C16.7543 3.92471 16.7631 4.05248 16.7975 4.17491C16.8319 4.29735 16.8912 4.41204 16.9718 4.51237C17.0525 4.6127 17.1531 4.69669 17.2677 4.7595C17.4973 4.88485 17.7691 4.9178 18.0239 4.85115C18.2787 4.7845 18.4957 4.62367 18.6276 4.40375C18.7368 4.21852 18.9068 4.07337 19.111 3.99105C19.3152 3.90874 19.5421 3.89391 19.756 3.94891C19.9699 4.0039 20.1588 4.1256 20.293 4.29492C20.4272 4.46424 20.4992 4.6716 20.4976 4.8845C20.4976 5.1395 20.3923 5.38406 20.2048 5.56438C20.0172 5.74469 19.7629 5.84599 19.4976 5.84599C19.2324 5.84599 18.9781 5.9473 18.7905 6.12761C18.603 6.30793 18.4976 6.55249 18.4976 6.80749C18.4976 7.0625 18.603 7.30706 18.7905 7.48737C18.9781 7.66769 19.2324 7.76899 19.4976 7.76899C20.2933 7.76899 21.0564 7.46509 21.619 6.92414C22.1816 6.38319 22.4976 5.64951 22.4976 4.8845C22.4976 4.11948 22.1816 3.3858 21.619 2.84485C21.0564 2.3039 20.2933 2 19.4976 2ZM19.8776 8.80741C19.6966 8.72671 19.4946 8.69992 19.2976 8.73049L19.1177 8.78818L18.9377 8.87471L18.7877 8.99971C18.6938 9.09069 18.6197 9.19881 18.5699 9.31769C18.5201 9.43658 18.4956 9.56384 18.4976 9.69199C18.4969 9.81853 18.5221 9.94397 18.5719 10.0611C18.6216 10.1783 18.695 10.2848 18.7877 10.3747C18.8806 10.4648 18.9912 10.5363 19.1131 10.5851C19.2349 10.6339 19.3656 10.6591 19.4976 10.6591C19.6297 10.6591 19.7604 10.6339 19.8822 10.5851C20.0041 10.5363 20.1147 10.4648 20.2076 10.3747C20.3003 10.2848 20.3737 10.1783 20.4234 10.0611C20.4732 9.94397 20.4984 9.81853 20.4976 9.69199C20.4967 9.50216 20.4373 9.31687 20.3269 9.15945C20.2166 9.00203 20.0603 8.87954 19.8776 8.80741Z"
        fill="#ADACAC"
      />
      <path
        d="M21.9046 14.3052C21.6394 14.3052 21.3849 14.4066 21.1974 14.5869C21.0098 14.7673 20.9044 15.0119 20.9044 15.267V19.1139C20.9044 19.369 20.799 19.6136 20.6114 19.794C20.4238 19.9743 20.1694 20.0757 19.9041 20.0757H5.90065C5.63537 20.0757 5.38095 19.9743 5.19337 19.794C5.00578 19.6136 4.9004 19.369 4.9004 19.1139V9.89086L10.7819 15.5555C11.3445 16.0958 12.1072 16.3993 12.9024 16.3993C13.6976 16.3993 14.4603 16.0958 15.0229 15.5555L16.6633 13.9782C16.8517 13.7971 16.9575 13.5515 16.9575 13.2954C16.9575 13.0393 16.8517 12.7937 16.6633 12.6126C16.475 12.4315 16.2195 12.3297 15.9532 12.3297C15.6868 12.3297 15.4313 12.4315 15.243 12.6126L13.6026 14.1898C13.4156 14.366 13.1642 14.4647 12.9024 14.4647C12.6406 14.4647 12.3892 14.366 12.2022 14.1898L6.31075 8.5348H12.9024C13.1677 8.5348 13.4221 8.43348 13.6097 8.25312C13.7973 8.07276 13.9026 7.82814 13.9026 7.57307C13.9026 7.318 13.7973 7.07338 13.6097 6.89302C13.4221 6.71265 13.1677 6.61133 12.9024 6.61133H5.90065C5.1048 6.61133 4.34155 6.91531 3.7788 7.45639C3.21605 7.99747 2.8999 8.73134 2.8999 9.49654V19.1139C2.8999 19.8791 3.21605 20.613 3.7788 21.1541C4.34155 21.6952 5.1048 21.9991 5.90065 21.9991H19.9041C20.7 21.9991 21.4632 21.6952 22.026 21.1541C22.5887 20.613 22.9049 19.8791 22.9049 19.1139V15.267C22.9049 15.0119 22.7995 14.7673 22.6119 14.5869C22.4243 14.4066 22.1699 14.3052 21.9046 14.3052Z"
        fill="#ADACAC"
      />
    </IconBase>
  );
};
