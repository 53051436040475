import { ArrowForwardRounded as ArrowForwardRoundedIcon } from "@mui/icons-material";
import { Dialog } from "@mui/material";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import SimpleModalHeader from "../../../components/SimpleModalHeader";
import { APP_ROUTES } from "../../../constants/module";
import AreaDevice from "./AreaDevice";
import AreaDeviceUpdate from "./AreaDevice/AreaDeviceUpdate";
import AreaInfo from "./AreaInfo";
import AreaInfoUpdate from "./AreaInfo/AreaInfoUpdate";
import AreaSetting from "./AreaSetting";
import AreaSettingUpdate from "./AreaSetting/AreaSettingUpdate";
import "./index.scss";

type P_Props = {
  closeForm: () => void;
  //eslint-disable-next-line
  data?: any;
};

const MenuStepArea = [
  {
    label: "Bước 1: Thông tin chung",
    id: 0,
  },
  {
    label: "Bước 2: Danh sách thiết bị",
    id: 1,
  },
  {
    label: "Bước 3: Cài đặt thông số",
    id: 2,
  },
];

const AreaModal: React.FC<P_Props> = ({ closeForm, data }) => {
  const history = useHistory();
  const [valStep, setValStep] = useState<number>(0);

  const funcRemove = () =>
    data
      ? (localStorage.removeItem("dataUpdate"),
        localStorage.removeItem("devicesUpdate"),
        localStorage.removeItem("settingUpdate"),
        closeForm())
      : (localStorage.removeItem("data"),
        localStorage.removeItem("devices"),
        localStorage.removeItem("setting"),
        history.push(APP_ROUTES.AREA));

  return (
    <Dialog fullScreen open={true}>
      <div className="area-modal">
        <SimpleModalHeader
          title={data ? "Chỉnh sửa chuồng" : "Tạo mới chuồng"}
          onClose={() => funcRemove()}
        />
        <div className="step-area">
          {MenuStepArea.map((step) => {
            const isActive = valStep === step.id;
            return (
              <div className={isActive ? "item item-active" : "item"} key={step.id}>
                <div style={{ margin: "0 3.5rem" }}>{step.label}</div>
                {step.id !== 2 && (
                  <ArrowForwardRoundedIcon fontSize="large" style={{ color: "#000000" }} />
                )}
              </div>
            );
          })}
        </div>
        <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center" }}>
          {valStep === 0 && data && (
            <AreaInfoUpdate setValStep={() => setValStep(1)} dataDetail={data} />
          )}
          {valStep === 0 && !data && <AreaInfo setValStep={() => setValStep(1)} />}
          {valStep === 1 && data && (
            <AreaDeviceUpdate setValStep={(num: number) => setValStep(num)} dataDetail={data} />
          )}
          {valStep === 1 && !data && <AreaDevice setValStep={(num: number) => setValStep(num)} />}
          {valStep === 2 && data && (
            <AreaSettingUpdate setValStep={() => setValStep(1)} dataDetail={data} />
          )}
          {valStep === 2 && !data && <AreaSetting setValStep={() => setValStep(1)} />}
        </div>
      </div>
    </Dialog>
  );
};

export default AreaModal;
