import { Menu as MenuIcon, MenuOpen as MenuOpenIcon } from "@mui/icons-material";
import {
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps,
  Box,
  Divider,
  Drawer as MuiDrawer,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { createTheme, CSSObject, styled, Theme, ThemeProvider } from "@mui/material/styles";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Avatar from "../../../assets/images/avt.png";
import Logo from "../../../assets/images/logo.png";
import { withProfile } from "../../../hoc/withProfile";
import { checkLinkImage, renderErrorImage } from "../../../utils/other.util";
import PushNotification from "../../components/PushNotification";
import { TEXT_HOLDER } from "../../constants/utilities";
import SignOut from "../../containers/Authentication/SignOut";
import "./index.scss";
import moduleList from "./routes";

const openedMixin = (theme: Theme): CSSObject => ({
  [theme.breakpoints.up("md")]: {
    width: 190,
  },
  [theme.breakpoints.up("xl")]: {
    width: 240,
  },
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  ...theme.mixins.toolbar,
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    // marginLeft: drawerWidth,
    // width: `calc(100% - ${drawerWidth}px)`,
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - 190px)`,
      marginLeft: 190,
    },
    [theme.breakpoints.up("xl")]: {
      width: `calc(100% - 240px)`,
      marginLeft: 240,
    },
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    [theme.breakpoints.up("md")]: {
      width: 190,
    },
    [theme.breakpoints.up("xl")]: {
      width: 240,
    },
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  })
);

//eslint-disable-next-line
const LayoutComponent: React.FC = (props: any) => {
  const theme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: "1.3rem",
            lineHeight: "1.9rem",
            color: " #FFFFFF",
            backgroundColor: "rgba(0,0,0,0.75)",
            fontFamily: "SVN-Gotham",
            fontWeight: 400,
            boxShadow: "0rem 0.4rem 1.2rem rgba(111, 125, 145, 0.16)",
            borderRadius: "0.4rem",
          },
        },
      },
    },
  });

  const location = useLocation();
  const [open, setOpen] = useState<boolean>(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const currentRoute = React.useMemo(() => {
    const routes = moduleList.map(({ path }) => path).reverse();
    const selected = routes.find((route) => location.pathname.includes(route));
    return selected;
  }, [location.pathname]);

  const selectedRoute = React.useMemo(() => {
    const selected = moduleList.find((route) => currentRoute === route.path);
    return selected;
  }, [currentRoute]);

  //button logout
  const [anchorUserEl, setAnchorUserEl] = useState<HTMLElement | null>(null);
  const handleCloseUserMenu = () => {
    setAnchorUserEl(null);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorUserEl(event.currentTarget);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar position="fixed" open={open} className="app-bar">
        <Toolbar className="app-toolbar">
          <div className="app-toolbar-left">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: "36px",
                ...(open && { display: "none !important" }),
              }}>
              <MenuIcon style={{ fontSize: "2.8rem", color: "#6C778D" }} />
            </IconButton>
            <Typography variant="h6" noWrap component="div" className="title">
              {selectedRoute?.title || TEXT_HOLDER}
            </Typography>
          </div>
          <div className="app-toolbar-right">
            <div className="notification">
              <PushNotification />
            </div>
            <div className="menu-user">
              <img
                src={checkLinkImage(props.profile?.avatar) || Avatar}
                alt="avt"
                onError={renderErrorImage("avatar")}
                onClick={handleOpenUserMenu}
              />
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}>
          <img className="DrawerHeader_img" src={Logo} alt="greenfeed-logo" />
          <IconButton onClick={handleDrawerClose}>
            <MenuOpenIcon style={{ fontSize: "2.8rem", color: "#6C778D" }} />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <ul className="nav-list">
          {moduleList.map(({ label, title, defaultIcon, activeIcon, path }) => {
            const selected = currentRoute === path;
            return (
              <Link key={label} to={path}>
                <li className="nav-item" style={{ padding: open ? "0.5rem" : "0" }}>
                  {open ? (
                    <button
                      role="tab"
                      className={selected ? "btn-icon btn--shiny" : "btn-icon"}
                      aria-selected={selected}>
                      <span>{selected ? activeIcon : defaultIcon}</span>
                    </button>
                  ) : (
                    <ThemeProvider theme={theme}>
                      <Tooltip arrow placement="right" title={<span>{title}</span>}>
                        <button
                          role="tab"
                          className={selected ? "btn-icon btn--shiny" : "btn-icon btn-hover"}
                          aria-selected={selected}>
                          <span>{selected ? activeIcon : defaultIcon}</span>
                        </button>
                      </Tooltip>
                    </ThemeProvider>
                  )}
                  <div className="label">{label}</div>
                </li>
              </Link>
            );
          })}
        </ul>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          background: "#F0F3F8",
          height: "100vh",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
        }}>
        <DrawerHeader />
        <div className="layout__content__body">{props.children}</div>
      </Box>
      {/*Sign out*/}
      <SignOut onClose={handleCloseUserMenu} anchorEl={anchorUserEl} avatarImg={Avatar} />
    </Box>
  );
};
export default withProfile(LayoutComponent);
