import {
  MoreHoriz as MoreHorizIcon,
  PriorityHigh as PriorityHighIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import { Dialog, Divider, InputAdornment, Popover, TextField } from "@mui/material";
import { AxiosError } from "axios";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useHistory } from "react-router-dom";
import Search from "../../../assets/images/img-search.png";
import Ord from "../../../assets/images/ordnull.png";
import { withProfile } from "../../../hoc/withProfile";
import { useFetchWithoutPagination } from "../../../hooks/useFetchWithoutPagination";
import useNoti from "../../../hooks/useNoti";
import { AppNotifications } from "../../../models";
import { DeviceOutputModel, TypeDevice } from "../../../models/device.model";
import { DeviceService } from "../../../services";
import { autoLoadingPagination, checkLinkImage } from "../../../utils/other.util";
import EmptyList from "../../components/EmptyList";
import FloatingBtn from "../../components/FloatingBtn";
import { DeleteIcon } from "../../components/Icons/DeleteIcon";
import { EditIcon } from "../../components/Icons/EditIcon";
import Loading from "../../components/Loading";
import NotificationModal from "../../components/NotificationModal";
import { APP_ROUTES } from "../../constants/module";
import { TEXT_HOLDER } from "../../constants/utilities";
import { DEFAULT_SIZE } from "../../core/Layout/routes";
import DeviceModal from "./DeviceModal";
import "./index.scss";

const Device: React.FC = () => {
  const [pushNoti] = useNoti();
  const history = useHistory();
  const [getIndex, setGetIndex] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>("");
  const [visibleSearch, setVisibleSearch] = useState<boolean>(false);
  const [deviceTypeId, setDeviceTypeId] = useState<number | undefined>(undefined);
  //eslint-disable-next-line
  const [openDeviceModal, setOpenDeviceModal] = useState<boolean>(false);
  const [notiDelete, setNotiDelete] = useState<boolean>(false);
  //eslint-disable-next-line
  const [dataDevice, setDataDevice] = useState<any>();

  const loadData = async (page: number, limit: number) => {
    // eslint-disable-next-line
    const resultAccount: any = await autoLoadingPagination(
      DeviceService.getAllDevice,
      {
        filter: {
          searchString: searchValue,
          deviceTypeId: deviceTypeId,
        },
      },
      page,
      limit,
      dataDevice && dataDevice?.returnData
    );

    if (resultAccount?.returnData?.length) setDataDevice(resultAccount);
    if (resultAccount?.returnData?.length === 0) setDataDevice({ ...resultAccount });
  };

  const hasMore =
    dataDevice?.payload?.data?.length < dataDevice?.payload?.totalRecords &&
    dataDevice?.payload?.page < dataDevice?.payload?.totalPages;

  useEffect(() => {
    loadData(1, 10);
    // eslint-disable-next-line
  }, [deviceTypeId, searchValue]);

  const [anchorUserEl, setAnchorUserEl] = useState<HTMLElement | null>(null);
  const handleCloseUserMenu = () => {
    setAnchorUserEl(null);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorUserEl(event.currentTarget);
  };

  const onSearch = _.debounce((value) => {
    setSearchValue(value);

    value ? setVisibleSearch(true) : setVisibleSearch(false);
  }, 300);

  const { data: typeDataDevice } = useFetchWithoutPagination(DeviceService.getTypeDevice);

  const deleteDevice = async (id: number) => {
    await DeviceService.deleteDevice(id)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          pushNoti(AppNotifications.SUCCESS, "Xóa thiết bị thành công");
          setTimeout(
            () =>
              //eslint-disable-next-line
              (window.location.href = window.location.href),
            500
          );
        } else {
          pushNoti(AppNotifications.ERROR, "Xóa thiết bị thất bại");
        }
      })
      .catch((err: AxiosError) => {
        pushNoti(AppNotifications.ERROR, "Xóa thiết bị thất bại");
      });
  };

  const item = dataDevice?.returnData[getIndex];

  return (
    <div className="device">
      <div className="device-content">
        <div className="action">
          <div className="device__nav-header">
            {typeDataDevice?.length > 0 &&
              [{ id: undefined, name: "Tất cả" }, ...typeDataDevice].map(
                (type: TypeDevice, idx: number) => {
                  return (
                    <div
                      key={idx}
                      className={
                        type.id === deviceTypeId ? "tab tab-active btn--shiny" : "tab tab-hover"
                      }
                      onClick={() => {
                        setDeviceTypeId(type.id);
                      }}>
                      {(() => {
                        switch (type.name) {
                          case "Tất cả":
                            return (
                              <span>
                                {type.name}
                                <span className="total">{dataDevice?.payload?.count["all"]}</span>
                              </span>
                            );
                          default:
                            return (
                              <span>
                                <img src={checkLinkImage(type?.avatar as string)} alt="icon" />
                                {type.name}
                                <span className="total">
                                  {dataDevice?.payload?.count[type.name as string]}
                                </span>
                              </span>
                            );
                        }
                      })()}
                    </div>
                  );
                }
              )}
          </div>
          <TextField
            placeholder="Tìm theo tên, mã thiết bị...."
            variant="outlined"
            className={"search-style"}
            inputProps={{ className: "input" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="large" />
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              onSearch(e.target.value);
            }}
          />
        </div>
        <div className="list">
          <div className="list-header">
            <div className="ic"></div>
            <div className="id">Mã thiết bị</div>
            <div className="type">Loại thiết bị</div>
            <div className="area">Chuồng chứa</div>
            <div className="position">Vị trí</div>
            <div className="status">Tình trạng</div>
          </div>
          {dataDevice?.loading ? (
            <Loading style={{ margin: "2rem 0", height: "60vh" }} />
          ) : dataDevice?.returnData ? (
            dataDevice?.returnData?.length > 0 ? (
              <InfiniteScroll
                useWindow={false}
                pageStart={0}
                initialLoad={false}
                hasMore={hasMore}
                loadMore={() => loadData(dataDevice.payload.page + 1, 10)}>
                {dataDevice.fetching && searchValue && <Loading />}
                {dataDevice?.returnData?.map((device: DeviceOutputModel, index: number) => {
                  return (
                    <div className="item-list-page" key={index}>
                      <div
                        className="ic"
                        onClick={(e) => {
                          handleOpenUserMenu(e);
                          setGetIndex(index);
                        }}>
                        <MoreHorizIcon fontSize="large" />
                      </div>
                      <div className="id">{device.code || TEXT_HOLDER}</div>
                      <div className="type">
                        <img src={checkLinkImage(device.deviceType.avatar as string)} alt="avt" />
                        {device.deviceType.name || TEXT_HOLDER}
                      </div>
                      <div className="area">
                        {device.area?.name || TEXT_HOLDER}
                        <div className="code">{device.area?.code || TEXT_HOLDER}</div>
                      </div>
                      <div className="position">{device.position}</div>
                      <div className="status">
                        {device.isActive ? (
                          <span className="status" style={{ color: "#41AD49" }}>
                            &#9679;&ensp;Đang hoạt động
                          </span>
                        ) : (
                          <span className="status" style={{ color: "#B8292F" }}>
                            &#9679;&ensp;Ngưng hoạt động
                          </span>
                        )}
                      </div>
                    </div>
                  );
                })}
              </InfiniteScroll>
            ) : visibleSearch === true ? (
              <EmptyList img={Search} title="Không tìm thấy kết quả phù hợp" />
            ) : (
              <EmptyList img={Ord} title="Chưa có dữ liệu" />
            )
          ) : (
            <EmptyList img={Ord} title="Chưa có dữ liệu" />
          )}
        </div>
      </div>

      <FloatingBtn
        visible={false}
        onClickPrimary={() => {
          history.push(APP_ROUTES.DEVICE_CREATE);
        }}
        btns={[]}
      />

      <Dialog fullScreen open={openDeviceModal}>
        <DeviceModal closeForm={() => setOpenDeviceModal(false)} data={item} />
      </Dialog>

      <Popover
        open={Boolean(anchorUserEl)}
        anchorEl={anchorUserEl}
        onClose={handleCloseUserMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}>
        <div className="popover-menu-edit">
          <button
            className="btn-white"
            onClick={(e) => {
              handleCloseUserMenu();
              setOpenDeviceModal(true);
            }}>
            <EditIcon color={"#312F30"} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />
            &ensp;Chỉnh sửa
          </button>
          <Divider />
          <button
            className="btn-white"
            onClick={() => {
              handleCloseUserMenu();
              setNotiDelete(true);
            }}>
            <DeleteIcon color={"#312F30"} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />
            &ensp;Xóa
          </button>
        </div>
      </Popover>

      <NotificationModal
        visible={notiDelete}
        title={"Xoá thiết bị"}
        subTitle={"Bạn có chắc muốn xóa thiết bị?"}
        icon={
          <div className={`notification-icon-box red`}>
            <PriorityHighIcon style={{ fontSize: "7rem", color: "#fff" }} />
          </div>
        }
        actions={
          <>
            <button
              className="btn-confirm-style"
              style={{
                background: "#fff",
                color: "#0A6836",
                border: "0.1rem solid #0A6836",
                width: "20.6rem",
                marginRight: "2rem",
              }}
              onClick={() => {
                setNotiDelete(false);
              }}>
              Huỷ
            </button>
            <button
              className="btn-confirm-style"
              style={{
                width: "20.6rem",
                marginRight: "2rem",
              }}
              onClick={() => {
                deleteDevice(item?.id);
              }}>
              Đồng ý
            </button>
          </>
        }
        onClose={() => {
          setNotiDelete(false);
        }}
      />
    </div>
  );
};

export default withProfile(Device);
