import { REQUEST_URL } from "../app/constants";
import { PaginationQueryModel } from "../models";
import { AlarmInputModal, UpdateAlarmInputModal } from "../models/alarm.model";
import { RequestUtil } from "../utils";

export const AlarmService = {
  getAllAlarm: (
    query: PaginationQueryModel & {
      searchString?: string;
      alarmType?: string[];
      sendOptions?: string[];
      siteId?: number;
      status?: string;
    }
  ) => {
    const url = REQUEST_URL.ALARM;

    return RequestUtil.get({ url, query: query });
  },

  createAlarm: (data: AlarmInputModal) => {
    const url = REQUEST_URL.ALARM;

    return RequestUtil.post({ url, data: data });
  },

  updateAlarm: (data: UpdateAlarmInputModal) => {
    const url = REQUEST_URL.ALARM;

    return RequestUtil.put({ url, data: data });
  },

  deleteAlarm: (id: number) => {
    const url = REQUEST_URL.DELETE_ALARM.replace("{id}", id.toString());

    return RequestUtil.delete({ url, query: id });
  },
};
