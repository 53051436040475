import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";

export interface LoginPayload {
  username: string;
  password: string;
}

export interface AuthError {
  message: string;
}

export interface AuthState {
  isLoggedIn: boolean;
  currentUser?: CurrentUser;
  logging: boolean;
}

export interface CurrentUser {
  id: string;
  name: string;
}
export const initialState: AuthState = {
  isLoggedIn: false,
  logging: false,
  currentUser: undefined,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login(state, action: PayloadAction<LoginPayload>) {
      state.logging = true;
    },
    loginSuccess(state, action: PayloadAction<CurrentUser>) {
      state.logging = false;
      state.currentUser = action.payload;
    },
    loginFailed(state, action: PayloadAction<string>) {
      state.logging = false;
    },

    logout(state) {
      state.isLoggedIn = false;
      state.currentUser = undefined;
    },
  },
});

//actions
export const authActions = authSlice.actions;

//selectors
export const selectIsLoggedIn = (state: RootState) => state.auth.isLoggedIn;
export const selectIsLoggin = (state: RootState) => state.auth.logging;

//reducers
const authReducer = authSlice.reducer;
export default authReducer;
