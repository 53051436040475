/* eslint-disable */
import { Divider, MenuItem, OutlinedInput, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import Pig from "../../../../assets/images/pig.png";
import useFetch from "../../../../hooks/useFetch";
import { AreaOutputModel, SiteOutputModel } from "../../../../models";
import { AreaService } from "../../../../services";
import { SiteService } from "../../../../services/site.service";
import {
  autoLoadingPagination,
  checkLinkImage,
  renderErrorImage,
} from "../../../../utils/other.util";
import Loading from "../../../components/Loading";
import "./index.scss";

type Props = {
  onChangeArea: (areaId: number | undefined, areaName: string | undefined) => void;
  onChangeSite: (siteId: any, siteName: string | undefined) => void;
};

const LeftSiteArea: React.FC<Props> = ({ onChangeArea, onChangeSite }) => {
  const [siteId, setSiteId] = useState<string>("");
  const [areaId, setAreaId] = useState<number>(0);
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const resultSite = useFetch(SiteService.getAllSite, {
    page: 1,
    limit: 500,
  });

  useEffect(() => {
    resultSite && setSiteId(resultSite.data?.[0]?.id);
  }, [resultSite]);

  useEffect(() => {
    siteId &&
      onChangeSite(siteId, resultSite.data?.find((s: SiteOutputModel) => s.id === +siteId)?.name);
  }, [siteId]);

  const loadData = async (page: number, limit: number) => {
    setLoading(true);
    const resultArea: any = await autoLoadingPagination(
      AreaService.getAllArea,
      {
        filter: {
          siteId: siteId,
          isAdmin: true,
        },
      },
      page,
      limit,
      data?.returnData
    );

    if (resultArea?.returnData?.length) setData(resultArea);
    if (resultArea?.returnData?.length === 0) setData({ ...resultArea });
    setLoading(false);
  };

  const hasMore =
    data?.payload?.data?.length < data?.payload?.totalRecords &&
    data?.payload?.page < data?.payload?.totalPages;

  useEffect(() => {
    siteId && loadData(1, 20);
  }, [siteId]);

  useEffect(() => {
    data?.returnData && setAreaId(data.returnData?.[0]?.id);
  }, [data]);

  useEffect(() => {
    areaId &&
      onChangeArea(areaId, data?.returnData?.find((s: AreaOutputModel) => s.id === areaId)?.name);
  }, [areaId]);

  return (
    <div className="left-site-area">
      <div className="left-site-area-header">
        <div style={{ padding: "0 3.4rem" }}>
          {siteId && (
            <Select
              fullWidth
              value={siteId.toString()}
              onChange={(e) => {
                setSiteId(e.target.value as string);
              }}
              input={<OutlinedInput className="form-text-field" color="success" />}
              renderValue={
                siteId !== ""
                  ? undefined
                  : () => <span className="form__placeholder">Chọn vai trò cho người dùng</span>
              }
              variant="outlined"
              className="form-select-field"
              displayEmpty
              MenuProps={{
                className: "select-menu-list select-menu-list-border-item",
              }}>
              {resultSite.data?.length &&
                resultSite.data.map((r: any, index: number) => {
                  return (
                    <MenuItem key={index} value={r.id}>
                      {r.name}
                    </MenuItem>
                  );
                })}
            </Select>
          )}
        </div>
        <Divider style={{ width: "100%", margin: "2.1rem 0 1.9rem" }} />
        <div className="title">Danh sách chuồng</div>
        <Divider style={{ width: "100%", marginBottom: "0.8rem" }} />
      </div>
      <div className="left-site-area-body">
        {loading ? (
          <Loading style={{ margin: "25rem 0" }} />
        ) : (
          data?.returnData?.length > 0 && (
            <InfiniteScroll
              useWindow={false}
              pageStart={0}
              initialLoad={false}
              hasMore={hasMore}
              loadMore={() => loadData(data.payload.page + 1, 10)}>
              {data?.returnData?.map((area: AreaOutputModel, index: number) => {
                return (
                  <div
                    className={areaId === area.id ? "active item" : "item"}
                    key={index}
                    onClick={() => {
                      !data.payload?.fetching && setAreaId(area.id);
                    }}>
                    <img
                      src={checkLinkImage(area.avatar as string) || Pig}
                      alt="avt-pig"
                      onError={renderErrorImage()}
                    />
                    <div className="name">{area.name}</div>
                  </div>
                );
              })}
            </InfiniteScroll>
          )
        )}
      </div>
    </div>
  );
};

export default LeftSiteArea;
