import { IconBase, IconComponent } from "../../core/Icons";

export const UserIcon: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.64869 9.0464C9.84771 9.0464 11.6304 7.26373 11.6304 5.0647C11.6304 2.86567 9.84771 1.08301 7.64869 1.08301C5.44966 1.08301 3.66699 2.86567 3.66699 5.0647C3.66699 7.26373 5.44966 9.0464 7.64869 9.0464Z"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.33302 14.5846C1.33195 14.3047 1.39455 14.0282 1.51609 13.7761C1.89748 13.0133 2.97299 12.609 3.86544 12.426C4.50907 12.2886 5.1616 12.1969 5.81815 12.1514C7.03371 12.0446 8.25629 12.0446 9.47185 12.1514C10.1283 12.1974 10.7808 12.2891 11.4246 12.426C12.317 12.609 13.3925 12.9752 13.7739 13.7761C14.0183 14.2901 14.0183 14.8868 13.7739 15.4008C13.3925 16.2017 12.317 16.5678 11.4246 16.7433C10.7817 16.8863 10.1289 16.9807 9.47185 17.0255C8.48248 17.1094 7.4885 17.1247 6.49702 17.0713C6.26819 17.0713 6.04698 17.0713 5.81815 17.0255C5.16353 16.9812 4.51327 16.8869 3.87307 16.7433C2.97299 16.5678 1.9051 16.2017 1.51609 15.4008C1.39517 15.1457 1.33263 14.8669 1.33302 14.5846Z"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconBase>
  );
};
