/* eslint-disable */
export type TCb = {
  success?: (data?: any) => void;
  fail?: (data?: any) => void;
  error?: (data?: any) => void;
};

export type IconModel = {
  color: string;
  size: [number, number]; // mang kich thuoc x,y (width, height)
  viewBox?: [number, number]; // mang kich thuoc x,y (width, height)
  className?: string;
  style?: React.CSSProperties;
  options?: {
    color: string;
    backgroundColor: string;
  };
};

export type PaginationQueryModel = {
  page: number;
  limit: number;
  searchString?: string;
};

export type ActionState = {
  type: string;
  payload: {
    data: any;
    complete?: () => void;
    error?: (err: any) => void;
  };
};

export type FetchingPaginationDataModel<T> = {
  page: number;
  totalPages: number;
  limit: number;
  totalRecords: number;
  data: T[];
  loading: boolean;
};

export type FetchingDataModel<T> = {
  data: T;
  loading: boolean;
};

export enum FORM_MODE {
  CREATE,
  EDIT,
  VIEW,
}

/**
 * dùng cho <ImageUpload />
 */
export type UploadedImageModel = {
  id?: string;
  file: any;
  fileStr: any;
  alt?: string;
};

export type FormUploadImageModel = {
  id: string;
  src: string;
  alt?: string;
};

export const Action = {
  FETCH: "FETCH",
  SUCCESS: "FETCH_SUCCESS",
  FAILED: "FETCH_FAILED",
  RESET: "FETCH_RESET",
  LOGOUT: "LOGOUT",
} as const;

export type ValueOf<T> = T[keyof T];

export type FetchAction = {
  type: ValueOf<typeof Action>;
  payload: any;
};

export type FetchReducer<T> = {
  data: T[];
  fetching: boolean;
  failMessage: string;
};

export enum ManageRegistration {
  REFUSE = "refuse",
  NOT_APPROVED = "not approved",
  APPROVED = "approved",
}

export enum Direction {
  UP = "UP",
  DOWN = "DOWN",
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

export enum AccountStatus {
  ACTIVE = "active",
  INACTIVE = "inactive",
  PENDING = "pending",
  DECLINE = "decline",
}

export enum SiteStatus {
  OFFICIAL = "official",
  UNOFFICIAL = "unofficial",
}

export enum SendOption {
  NOTIFICATION = "notification",
  SMS = "sms",
  BOTH = "both",
  NONE = "none",
}

export enum AlarmType {
  IMPORTANCE = "importance",
  OPERATION = "operation",
  MEDIUM = "medium",
}

export const ErrorMessage = {
  // Not found
  ACCOUNT_NOT_FOUND: "ACCOUNT_NOT_FOUND",
  ANIMAL_TYPE_NOT_FOUND: "ANIMAL_TYPE_NOT_FOUND",
  AREA_NOT_FOUND: "AREA_NOT_FOUND",
  DATA_ACCESS_NOT_FOUND: "DATA_ACCESS_NOT_FOUND",
  AREA_TYPE_NOT_FOUND: "AREA_TYPE_NOT_FOUND",
  DEVICE_NOT_FOUND: "DEVICE_NOT_FOUND",
  DEVICE_TYPE_NOT_FOUND: "DEVICE_TYPE_NOT_FOUND",
  OPC_TAG_NOT_FOUND: "OPC_TAG_NOT_FOUND",
  ROLE_NOT_FOUND: "ROLE_NOT_FOUND",
  SITE_NOT_FOUND: "SITE_NOT_FOUND",

  // Existed
  ACCOUNT_ALREADY_EXISTS: "ACCOUNT_ALREADY_EXISTS",
  SITE_ALREADY_EXISTS: "SITE_ALREADY_EXISTS",
  THE_AREA_BELONGS_TO_ANOTHER_SITE: "THE_AREA_BELONGS_TO_ANOTHER_SITE",
  AREA_ALREADY_EXISTS: "AREA_ALREADY_EXISTS",
  THE_DEVICE_BELONGS_TO_ANOTHER_AREA: "THE_DEVICE_BELONGS_TO_ANOTHER_AREA",
  DEVICE_ALREADY_EXISTS: "DEVICE_ALREADY_EXISTS",
  // Invalid type
  ID_MUST_BE_A_NUMBER: "ID_MUST_BE_A_NUMBER",
  INVALID_FILE_TYPE: "INVALID_FILE_TYPE",
  INVALID_TOKEN: "INVALID_TOKEN",
  VALUE_INVALID: "VALUE_INVALID",
  INVALID_TOKEN_OR_OTP_EXPIRED: "INVALID_TOKEN_OR_OTP_EXPIRED",
  TOKEN_DOES_NOT_MATCH_ANY_SESSION: "TOKEN_DOES_NOT_MATCH_ANY_SESSION",
  TOKEN_IS_NO_LONGER_AVAILABLE: "TOKEN_IS_NO_LONGER_AVAILABLE",

  // Other
  UNAUTHORIZED: "UNAUTHORIZED",
  ACCOUNT_HAS_BEEN_BLOCKED_OR_DELETED: "ACCOUNT_HAS_BEEN_BLOCKED_OR_DELETED",
  ACCOUNT_HAS_BEEN_ACTIVATED: "ACCOUNT_HAS_BEEN_ACTIVATED",
  ACCOUNT_IS_PENDING_APPROVAL: "ACCOUNT_IS_PENDING_APPROVAL",
  CODE_ALREADY_IN_USE: "CODE_ALREADY_IN_USE",
  EMAIL_ALREADY_IN_USE: "EMAIL_ALREADY_IN_USE",
  PHONE_NUMBER_ALREADY_IN_USE: "PHONE_NUMBER_ALREADY_IN_USE",
  ALARM_CODE_ALREADY_IN_USE: "ALARM_CODE_ALREADY_IN_USE",
  ACCOUNT_NOT_MATCH_WITH_TOKEN: "ACCOUNT_NOT_MATCH_WITH_TOKEN",
  EXPIRED_OTP: "EXPIRED_OTP",
  AN_ERROR_OCCURRED_WHILE_SENDING_MAIL: "AN_ERROR_OCCURRED_WHILE_SENDING_MAIL",
  CANNOT_ASSIGN_MULTIPLE_AREA_WITH_THE_SAME_CODE_AND_TYPE_TO_ONE_SITE:
    "CANNOT_ASSIGN_MULTIPLE_AREA_WITH_THE_SAME_CODE_AND_TYPE_TO_ONE_SITE",
  CANNOT_ASSIGN_MULTIPLE_DEVICES_WITH_THE_SAME_CODE_AND_TYPE_TO_ONE_AREA:
    "CANNOT_ASSIGN_MULTIPLE_DEVICES_WITH_THE_SAME_CODE_AND_TYPE_TO_ONE_AREA",
  THE_ACCOUNT_HAS_BEEN_DISABLED_TOO: "THE_ACCOUNT_HAS_BEEN_DISABLED_TOO",
  INCORRECT_PASSWORD: "INCORRECT_PASSWORD",
  INCORRECT_OTP: "INCORRECT_OTP",
  NEED_TO_VERIFY_OTP_FIRST: "NEED_TO_VERIFY_OTP_FIRST",
  OTP_HAS_BEEN_VERIFIED: "OTP_HAS_BEEN_VERIFIED",
  PERMISSION_DENIED: "PERMISSION_DENIED",
  SESSION_EXPIRED: "SESSION_EXPIRED",
  PASSWORD_MUST_BE_AT_LEAST_1_CHARACTER_UPPERCASE:
    "PASSWORD_MUST_BE_AT_LEAST_1_CHARACTER_UPPERCASE",
  PASSWORD_MUST_BE_AT_LEAST_1_SPECIAL_CHARACTER: "PASSWORD_MUST_BE_AT_LEAST_1_SPECIAL_CHARACTER",
  PASSWORD_MUST_BE_AT_LEAST_1_NUMBER: "PASSWORD_MUST_BE_AT_LEAST_1_NUMBER",
  THE_NEW_PASSWORD_IS_THE_SAME_AS_THE_OLD_PASSWORD:
    "THE_NEW_PASSWORD_IS_THE_SAME_AS_THE_OLD_PASSWORD",
  OPC_TAG_HAS_ASSIGNED_IN_ALARM: "OPC_TAG_HAS_ASSIGNED_IN_ALARM",
  CONDITION_IS_NECCESSARY_WITH_OPC_TAG_HAVE_VALUE_TYPE_IS_VALUE:
    "CONDITION_IS_NECCESSARY_WITH_OPC_TAG_HAVE_VALUE_TYPE_IS_VALUE",
  ACCOUNT_TEMPORARY_BLOCKED: "ACCOUNT_TEMPORARY_BLOCKED",
};
