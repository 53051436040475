import {
  Dialog,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import { useHistory } from "react-router-dom";
import { useFetchWithoutPagination } from "../../../../hooks/useFetchWithoutPagination";
import useNoti from "../../../../hooks/useNoti";
import { AppNotifications } from "../../../../models";
import { DeviceInputModal, TypeDevice } from "../../../../models/device.model";
import { AreaService, DeviceService } from "../../../../services";
import { FormUtil } from "../../../../utils/form.util";
import { FormUIUtil } from "../../../../utils/formUI.util";
import { checkLinkImage } from "../../../../utils/other.util";
import ModalFooter from "../../../components/ModalFooter";
import SelectPagination from "../../../components/SelectPagination";
import SimpleModalHeader from "../../../components/SimpleModalHeader";
import DefaultSwitch from "../../../components/Switch";
import { REGEX } from "../../../constants";
import { APP_ROUTES } from "../../../constants/module";
import { TEXT_HOLDER } from "../../../constants/utilities";
import "./index.scss";

type P_Props = {
  closeForm: () => void;
  // eslint-disable-next-line
  data?: any | null;
};
const DeviceModal: React.FC<P_Props> = ({ closeForm, data }) => {
  const [pushNoti] = useNoti();
  const history = useHistory();
  const [deviceType, setDeviceType] = useState<string>(data ? data?.deviceType?.id : "");
  const [area, setArea] = useState<string>("");
  const [submitErrorDeviceType, setSubmitErrorDeviceType] = useState<boolean>(false);
  const [status, setStatus] = useState<boolean>(data ? data?.isActive : true);

  const { data: deviceTypeData } = useFetchWithoutPagination(DeviceService.getTypeDevice);

  const createDevice = (data: DeviceInputModal) => {
    DeviceService.createDevice(data)
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          pushNoti(AppNotifications.SUCCESS, "Tạo thiết bị thành công!");
          history.push(APP_ROUTES.DEVICE);
        } else {
          pushNoti(AppNotifications.ERROR, "Tạo thiết bị thất bại!");
        }
      })
      .catch((err) => {
        pushNoti(AppNotifications.ERROR, "Tạo thiết bị thất bại!");
        console.log(err);
      });
  };

  const updateDevice = (data: DeviceInputModal) => {
    DeviceService.updateDevice(data)
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          pushNoti(AppNotifications.SUCCESS, "Chỉnh sửa thiết bị thành công!");
          closeForm();
        } else {
          pushNoti(AppNotifications.ERROR, "Chỉnh sửa thiết bị thất bại!");
        }
      })
      .catch((err) => {
        pushNoti(AppNotifications.ERROR, "Chỉnh sửa thiết bị thất bại!");
        console.log(err);
      });
  };

  // eslint-disable-next-line
  const submitForm = async (values: any) => {
    if (values.code === values.position) {
      pushNoti(AppNotifications.ERROR, "Mã và Vị trí thiết bị không trùng nhau!");
      return;
    }

    const val = {
      id: data ? data?.id : undefined,
      code: values.code,
      position: parseInt(values.position),
      areaId: +area,
      deviceTypeId: deviceType,
      status: status ? "active" : "inactive",
      // eslint-disable-next-line
    } as any;

    if (data && data?.id) return updateDevice(val);

    return createDevice(val);
  };

  return (
    <Dialog fullScreen open={true}>
      <div className="device-modal">
        <SimpleModalHeader
          title={data ? "Chỉnh sửa thiết bị" : "Tạo mới thiết bị"}
          onClose={() => (data ? closeForm() : history.push(APP_ROUTES.DEVICE))}
        />
        <div className="content">
          <Form
            onSubmit={async (values: DeviceInputModal) => submitForm(values)}
            initialValues={data}>
            {({ handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit} id="device-form" className="device-modal-form">
                  <Grid container>
                    <Grid item xs={6} className="title">
                      Thông tin chung
                    </Grid>
                    <Grid item xs={6} display="flex" justifyContent="flex-end" alignItems="center">
                      <DefaultSwitch
                        checked={status}
                        onChange={() => {
                          setStatus(!status);
                        }}
                      />
                      {status ? (
                        <span style={{ fontSize: "1.6rem", color: "#41AD49" }}>Đang hoạt động</span>
                      ) : (
                        <span style={{ fontSize: "1.6rem", color: "#ADACAC" }}>
                          Ngừng hoạt động
                        </span>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={6}>
                    <Grid item xs={6}>
                      <Field name="code" validate={FormUtil.Rule.required("Xin nhập mã thiết bị")}>
                        {({ input, meta, ...rest }) => {
                          return FormUIUtil.renderFormItem(
                            "Mã thiết bị",
                            <TextField
                              {...input}
                              {...rest}
                              fullWidth
                              placeholder={"Nhập mã thiết bị..."}
                              color="success"
                              className="form-text-field"
                              inputProps={{ className: "input" }}
                              variant="outlined"
                              onChange={(e) => input.onChange(e.target.value)}
                              helperText={meta.touched ? meta.error : ""}
                              error={meta.error && meta.touched}
                            />
                          );
                        }}
                      </Field>
                      {FormUIUtil.renderFormItem(
                        "Chuồng",
                        <FormControl fullWidth>
                          <SelectPagination
                            value={area}
                            valueItem={"id"}
                            disabled={data?.area ? true : false}
                            onChange={(e) => {
                              setArea(e.target.value as string);
                            }}
                            menuPropsClassName={"select-menu-list"}
                            // eslint-disable-next-line
                            renderItem={(item: any) => (
                              <>
                                {item.OPCSlot ? item.OPCSlot : TEXT_HOLDER} - {item.name}
                              </>
                            )}
                            renderValue={
                              area !== ""
                                ? undefined
                                : () =>
                                    data?.area?.name ? (
                                      data?.area?.name
                                    ) : (
                                      <span className="form__placeholder">- Chọn Chuồng -</span>
                                    )
                            }
                            actionConfig={{
                              action: AreaService.getAllArea,
                            }}
                          />
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      {FormUIUtil.renderFormItem(
                        "Loại thiết bị",
                        <FormControl fullWidth>
                          <Select
                            fullWidth
                            disabled={data?.area ? true : false}
                            value={deviceType}
                            onChange={(e) => {
                              setDeviceType(e.target.value as string);
                            }}
                            input={<OutlinedInput className="form-text-field" color="success" />}
                            renderValue={
                              deviceType !== ""
                                ? undefined
                                : () => (
                                    <span className="form__placeholder">
                                      - Chọn Loại thiết bị -
                                    </span>
                                  )
                            }
                            onBlur={(e) => {
                              setSubmitErrorDeviceType(deviceType === "");
                            }}
                            variant="outlined"
                            className="form-select-field"
                            error={submitErrorDeviceType}
                            displayEmpty
                            MenuProps={{
                              className: "select-menu-list",
                            }}>
                            {deviceTypeData?.length &&
                              deviceTypeData.map((r: TypeDevice) => {
                                return (
                                  <MenuItem key={r.id} value={r.id}>
                                    <img src={checkLinkImage(r?.avatar as string)} alt="icon" />
                                    &ensp;
                                    {r.name}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                          <FormHelperText>
                            {submitErrorDeviceType && "Xin chọn loại thiết bị"}
                          </FormHelperText>
                        </FormControl>
                      )}
                      <Field
                        name="position"
                        validate={FormUtil.Rule.pattern(REGEX.NUMBER_ONLY, {
                          errorMessage: "Vị trí không phù hợp (chỉ bao gồm số)",
                        })}>
                        {({ input, meta, ...rest }) => {
                          return FormUIUtil.renderFormItem(
                            "Vị trí thiết bị",
                            <TextField
                              {...input}
                              {...rest}
                              fullWidth
                              disabled={data?.area ? true : false}
                              placeholder={"-Chọn vị trí thiết bị-"}
                              color="success"
                              className="form-text-field"
                              inputProps={{ className: "input" }}
                              variant="outlined"
                              onChange={(e) => input.onChange(e.target.value)}
                              helperText={meta.touched ? meta.error : ""}
                              error={meta.error && meta.touched}
                            />
                          );
                        }}
                      </Field>
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          </Form>
        </div>
        <ModalFooter
          formId="device-form"
          showDeleteButton={false}
          handleDeleteAction={() => {
            // toggleDeleteModal();
          }}
          handleCancelAction={() => (data ? closeForm() : history.push(APP_ROUTES.DEVICE))}
          title={data ? "Chỉnh sửa" : "Tạo mới"}
          handleSubmit={() => {
            setSubmitErrorDeviceType(deviceType === "");
          }}
        />
      </div>
    </Dialog>
  );
};

export default DeviceModal;
