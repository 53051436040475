import { REQUEST_URL } from "../app/constants";
import { PaginationQueryModel, SetSitePosition, SiteInputModel } from "../models";
import { RequestUtil } from "../utils";

export const SiteService = {
  getAllSite: (
    query: PaginationQueryModel & {
      status?: string[];
      animalTypeIds?: number[];
      searchString?: string;
      isAdmin?: boolean;
    }
  ) => {
    const url = REQUEST_URL.SITE;
    return RequestUtil.get({ url, query: query });
  },

  createSite: (data: SiteInputModel) => {
    const url = REQUEST_URL.SITE;
    return RequestUtil.post({ url, data });
  },

  updateSite: (data: SiteInputModel) => {
    const url = REQUEST_URL.SITE;
    return RequestUtil.put({ url, data });
  },

  getType: () => {
    const url = REQUEST_URL.TYPE;
    return RequestUtil.get({ url });
  },

  getAnimalType: () => {
    const url = REQUEST_URL.TYPE;
    return RequestUtil.get({ url });
  },

  getSiteDetailById: (siteId: string) => {
    const url = `${REQUEST_URL.SITE}/${siteId}`;
    return RequestUtil.get({ url });
  },

  setSitePosition: (data: SetSitePosition) => {
    const url = REQUEST_URL.SET_SITE_POSITION;
    return RequestUtil.post({ url, data });
  },

  deleteSite: (id: number) => {
    const url = REQUEST_URL.DELETE_SITE.replace("{id}", id.toString());

    return RequestUtil.delete({ url, query: id });
  },
};
