export enum AccountPermission {
  ACCOUNT_CREATE = "ACCOUNT_CREATE",
  ACCOUNT_CONFIRM_SIGNUP = "ACCOUNT_CONFIRM-SIGNUP",
  ACCOUNT_USER_AUTHORIZATION = "ACCOUNT_USER-AUTHORIZATION",
  ACCOUNT_SITE_ALLOCATION = "ACCOUNT_SITE-ALLOCATION",
  ACCOUNT_ACTIVE_USER_ACCOUNT = "ACCOUNT_ACTIVE-USER-ACCOUNT",
  ACCOUNT_DELETE_USER_ACCOUNT = "ACCOUNT_DELETE-USER-ACCOUNT",
  ACCOUNT_UPDATE = "ACCOUNT_UPDATE",
  ACCOUNT_RESET_PASSWORD = "ACCOUNT_RESET-PASSWORD",
  ACCOUNT_RESET_USER_PASSWORD = "ACCOUNT_RESET-USER-PASSWORD",
  ACCOUNT_SETTING_USER_ALARM = "ACCOUNT_SETTING-USER-ALARM",
  ACCOUNT_VIEW_USER_LOGIN = "ACCOUNT_VIEW-USER-LOGIN",
}
export enum SitePermission {
  SITE_DETAIL = "SITE_DETAIL",
  SITE_LIST_AREA = "SITE_LIST-AREA",
  SITE_SETTING_INFO = "SITE_SETTING-INFO",
  SITE_ACTIVE = "SITE_ACTIVE",
  SITE_EDIT_INFO = "SITE_EDIT-INFO",
  SITE_DELETE_INFO = "SITE_DELETE-INFO",
  SITE_VIEW_ALARM_STATUS = "SITE_VIEW-ALARM-STATUS",
}
export enum AreaPermission {
  AREA_DETAIL = "AREA_DETAIL",
  AREA_SETTING_INFO = "AREA_SETTING_INFO",
  AREA_ACTIVE = "AREA_ACTIVE",
  AREA_EDIT_INFO = "AREA_EDIT_INFO",
  AREA_DELETE_INFO = "AREA_DELETE_INFO",
}
export enum DevicePermission {
  DEVICE_LIST = "DEVICE_LIST",
  DEVICE_SETTING_INFO = "DEVICE_SETTING_INFO",
  DEVICE_ACTIVE = "DEVICE_ACTIVE",
  DEVICE_EDIT_INFO = "DEVICE_EDIT_INFO",
  DEVICE_DELETE_INFO = "DEVICE_DELETE_INFO",
}
export enum AlarmPermission {
  ALARM_RECEIVE_NOTIFY = "ALARM_RECEIVE-NOTIFY",
  ALARM_SETTING_INFO = "ALARM_SETTING-INFO",
  ALARM_EDIT = "ALARM_EDIT",
  ALARM_DETAIL = "ALARM_DETAIL",
  ALARM_DELETE = "ALARM_DELETE",
  ALARM_LIST = "ALARM_LIST",
  ALARM_HISTORY_FOLLOW_SITE = "ALARM_HISTORY-FOLLOW-SITE",
}
export enum StatisticPermission {
  STATISTIC_VIEW = "STATISTIC_VIEW",
  STATISTIC_RECEIVE = "STATISTIC_RECEIVE",
  STATISTIC_REQUIRED_SEND_THROUGH_MAIL = "STATISTIC_REQUIRED_SEND_THROUGH_MAIL",
}
