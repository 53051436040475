import { IconBase, IconComponent } from "../../core/Icons";

export const ReportSuccessIcon: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <path
        d="M22.6288 4.27633C22.4495 4.09809 22.2069 3.99805 21.9541 3.99805C21.7013 3.99805 21.4587 4.09809 21.2794 4.27633L18.0448 7.43442L16.8772 6.28603C16.6958 6.10836 16.4511 6.01006 16.1972 6.01275C15.9432 6.01544 15.7008 6.11891 15.5231 6.30038C15.3454 6.48186 15.2471 6.72648 15.2498 6.98043C15.2525 7.23438 15.356 7.47686 15.5374 7.65453L17.3749 9.45368C17.5538 9.62903 17.7943 9.72725 18.0448 9.72725C18.2953 9.72725 18.5358 9.62903 18.7147 9.45368L22.6192 5.6257C22.7987 5.44766 22.9005 5.20582 22.9023 4.95299C22.9041 4.70016 22.8058 4.4569 22.6288 4.27633Z"
        fill="#41AD49"
      />
      <path
        d="M21.0898 12.3454C20.8359 12.3454 20.5925 12.4462 20.4129 12.6257C20.2334 12.8053 20.1326 13.0487 20.1326 13.3026V17.1316C20.1326 17.3854 20.0317 17.6289 19.8522 17.8084C19.6727 17.9879 19.4292 18.0888 19.1753 18.0888H5.77405C5.52017 18.0888 5.2767 17.9879 5.09718 17.8084C4.91766 17.6289 4.81681 17.3854 4.81681 17.1316V7.95167L10.4454 13.5898C10.9838 14.1276 11.7137 14.4296 12.4747 14.4296C13.2357 14.4296 13.9656 14.1276 14.504 13.5898L16.0739 12.0199C16.2541 11.8397 16.3554 11.5952 16.3554 11.3403C16.3554 11.0854 16.2541 10.8409 16.0739 10.6606C15.8936 10.4804 15.6492 10.3791 15.3943 10.3791C15.1393 10.3791 14.8949 10.4804 14.7146 10.6606L13.1448 12.2305C12.9658 12.4059 12.7253 12.5041 12.4747 12.5041C12.2241 12.5041 11.9836 12.4059 11.8046 12.2305L6.16651 6.60197H12.4747C12.7286 6.60197 12.972 6.50112 13.1516 6.3216C13.3311 6.14209 13.4319 5.89861 13.4319 5.64473C13.4319 5.39086 13.3311 5.14738 13.1516 4.96787C12.972 4.78835 12.7286 4.6875 12.4747 4.6875H5.77405C5.01242 4.6875 4.282 4.99005 3.74345 5.5286C3.2049 6.06715 2.90234 6.79758 2.90234 7.5592V17.1316C2.90234 17.8932 3.2049 18.6236 3.74345 19.1622C4.282 19.7007 5.01242 20.0033 5.77405 20.0033H19.1753C19.937 20.0033 20.6674 19.7007 21.2059 19.1622C21.7445 18.6236 22.047 17.8932 22.047 17.1316V13.3026C22.047 13.0487 21.9462 12.8053 21.7667 12.6257C21.5872 12.4462 21.3437 12.3454 21.0898 12.3454Z"
        fill="#41AD49"
      />
    </IconBase>
  );
};
