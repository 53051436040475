import {
  CheckRounded as CheckRoundedIcon,
  ClearRounded as ClearRoundedIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from "@mui/icons-material";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { AxiosError } from "axios";
import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import { useHistory } from "react-router";
import Logo from "../../../../assets/images/logo.png";
import LeftImage from "../../../../assets/images/signin/left-login.png";
import RightImage from "../../../../assets/images/signin/right-login.png";
import useNoti from "../../../../hooks/useNoti";
import { AppNotifications, ErrorMessage } from "../../../../models";
import { AuthService } from "../../../../services";
import { FormUtil } from "../../../../utils";
import { FormUIUtil } from "../../../../utils/formUI.util";
import { loadCallback } from "../../../../utils/other.util";
import NotificationModal from "../../../components/NotificationModal";
import { REGEX } from "../../../constants";
import { APP_ROUTES } from "../../../constants/module";

import "./index.scss";

const ResetPassword: React.FC = () => {
  const [pushNoti] = useNoti();
  const [noti, setNoti] = useState<boolean>(false);
  const [notiSignUp, setNotiSignUp] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [checkPassword, setCheckPassword] = useState<boolean>(false);
  const [value, setValue] = useState<string>("");

  const history = useHistory();

  //eslint-disable-next-line
  let submit: any;
  const submitForm = React.useCallback((values) => {
    AuthService.updatePassword({
      token: localStorage.getItem("verify-forgot-password-token") || "",
      password: values.password,
    })
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          setNoti(true);
          setNotiSignUp(true);
          localStorage.clear();
        } else {
          setNoti(false);
          setNotiSignUp(true);
          if (res?.data.message === ErrorMessage.NEED_TO_VERIFY_OTP_FIRST) {
            pushNoti(AppNotifications.ERROR, "Vui lòng xác thực OTP");
          } else if (res?.data.message === ErrorMessage.INVALID_TOKEN) {
            pushNoti(AppNotifications.ERROR, "Mã xác thực không đúng");
            setTimeout(() => history.push(APP_ROUTES.FORGET_PASSWORD), 2000);
          } else if (res?.data.message === ErrorMessage.ACCOUNT_NOT_MATCH_WITH_TOKEN) {
            pushNoti(AppNotifications.ERROR, "Tài khoản không chính xác");
            setTimeout(() => history.push(APP_ROUTES.FORGET_PASSWORD), 2000);
          } else if (
            res?.data.message === ErrorMessage.THE_NEW_PASSWORD_IS_THE_SAME_AS_THE_OLD_PASSWORD
          ) {
            setCheckPassword(true);
          } else {
            pushNoti(AppNotifications.ERROR, "Lỗi hệ thống");
          }
        }
      })
      .catch((error: AxiosError) => {
        console.log(error);
      });

    //eslint-disable-next-line
  }, []);

  return (
    <div className="resetpw-form">
      <img className="img-left" src={LeftImage} alt="left" />
      <div className="resetpw-form__content">
        <div className="resetpw-form__logo">
          <img src={Logo} alt="greenfeed-logo" style={{ width: "20rem" }} />
        </div>
        <div className="title">Đặt lại mật khẩu</div>
        <div className="description">
          Bạn vui lòng nhập mật khẩu mới để tiếp tục
          <br />
          đăng nhập vào hệ thống
        </div>
        <Form
          initialValuesEqual={() => true}
          validate={(values) => {
            const errors: Record<string, unknown> = {};
            if (values.confirmPassword !== values.password) {
              errors.confirmPassword = "Mật khẩu không trùng khớp";
            } else if (checkPassword) {
              errors.confirmPassword = "Mật khẩu không trùng mật khẩu cũ";
            }

            return errors;
          }}
          onSubmit={submitForm}
          render={({ handleSubmit }) => {
            submit = handleSubmit;
            return (
              <>
                <form onSubmit={handleSubmit} className="login-form-input">
                  <Field
                    name="password"
                    validate={FormUtil.Rule.required("Bạn chưa nhập mật khẩu mới")}>
                    {({ input, meta, ...rest }) => {
                      return FormUIUtil.renderFormItem(
                        "Mật khẩu mới",
                        <TextField
                          {...input}
                          {...rest}
                          color="success"
                          placeholder={"Mật khẩu mới"}
                          className={"text-border-style"}
                          inputProps={{ className: "input" }}
                          InputProps={{
                            type: showNewPassword ? "text" : "password",
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={() => setShowNewPassword((show) => !show)}>
                                  {showNewPassword ? (
                                    <VisibilityIcon
                                      style={{ fontSize: "2rem", color: "#000000" }}
                                    />
                                  ) : (
                                    <VisibilityOffIcon
                                      style={{ fontSize: "2rem", color: "#000000" }}
                                    />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) => {
                            input.onChange(e.target.value);
                            setValue(e.target.value);
                            setCheckPassword(false);
                          }}
                          helperText={meta.touched ? meta.error : ""}
                          error={meta.error && meta.touched}
                        />
                      );
                    }}
                  </Field>
                  <br />
                  <Field name="confirmPassword">
                    {({ input, meta, ...rest }) => {
                      return FormUIUtil.renderFormItem(
                        "Nhập lại mật khẩu mới",
                        <TextField
                          {...input}
                          {...rest}
                          color="success"
                          placeholder={"Nhập lại mật khẩu"}
                          className={"text-border-style"}
                          inputProps={{ className: "input" }}
                          InputProps={{
                            type: showPassword ? "text" : "password",
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword((show) => !show)}>
                                  {showPassword ? (
                                    <VisibilityIcon
                                      style={{ fontSize: "2rem", color: "#000000" }}
                                    />
                                  ) : (
                                    <VisibilityOffIcon
                                      style={{ fontSize: "2rem", color: "#000000" }}
                                    />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          helperText={meta.touched ? meta.error : ""}
                          error={meta.error && meta.touched}
                        />
                      );
                    }}
                  </Field>
                  <div className="resetpw-form__validate">
                    <div className="validate-title">Yêu cầu mật khẩu có :</div>
                    <div className="validate-content">
                      <div className="item">
                        <div
                          className="ic"
                          style={{
                            background:
                              value !== ""
                                ? REGEX.CHARACTER_LENGTH.test(value) === true
                                  ? "#2ac769"
                                  : "#e93c3c"
                                : "rgba(42, 199, 105, 0.24)",
                          }}>
                          <CheckRoundedIcon style={{ fontSize: "0.8rem", color: "#fff" }} />
                        </div>
                        <div>&ensp;Tối thiểu 8 ký tự</div>
                      </div>
                      <div className="item">
                        <div
                          className="ic"
                          style={{
                            background:
                              value !== ""
                                ? REGEX.UPPERCASE.test(value) === true
                                  ? "#2ac769"
                                  : "#e93c3c"
                                : "rgba(42, 199, 105, 0.24)",
                          }}>
                          <CheckRoundedIcon style={{ fontSize: "0.8rem", color: "#fff" }} />
                        </div>
                        <div>&ensp;Ít nhất 1 ký tự viết in hoa</div>
                      </div>
                      <div className="item">
                        <div
                          className="ic"
                          style={{
                            background:
                              value !== ""
                                ? REGEX.DIGITS.test(value) === true
                                  ? "#2ac769"
                                  : "#e93c3c"
                                : "rgba(42, 199, 105, 0.24)",
                          }}>
                          <CheckRoundedIcon style={{ fontSize: "0.8rem", color: "#fff" }} />
                        </div>
                        <div>&ensp;Ít nhất 1 chữ số</div>
                      </div>
                      <div className="item">
                        <div
                          className="ic"
                          style={{
                            background:
                              value !== ""
                                ? REGEX.SPECIAL_CHARACTER.test(value) === true
                                  ? "#2ac769"
                                  : "#e93c3c"
                                : "rgba(42, 199, 105, 0.24)",
                          }}>
                          <CheckRoundedIcon style={{ fontSize: "0.8rem", color: "#fff" }} />
                        </div>
                        <div>&ensp;Ít nhất 1 ký tự đặc biệt như @#$%^& ...</div>
                      </div>
                    </div>
                  </div>
                  <div className="button-summitt">
                    <button
                      className="button-summitt-btn"
                      onClick={(e) => {
                        e.preventDefault();
                        loadCallback(submit, e);
                      }}>
                      Đặt lại mật khẩu
                    </button>
                  </div>
                  <div className="resetpw-form__text">
                    Điều khoản sử dụng và bảo mật
                    <br />
                    Phiên bản 1.2.21 • Bản quyền thuộc về
                    <a
                      style={{ color: "#0A6836", fontWeight: 700 }}
                      rel="noreferrer"
                      href={"https://www.greenfeed.com.vn/"}
                      target="_blank">
                      {" "}
                      GREENFEED VIETNAM
                    </a>
                  </div>
                </form>

                <NotificationModal
                  visible={notiSignUp}
                  icon={
                    <div className={`notification-icon-box ${noti ? "green" : "red"}`}>
                      {noti ? (
                        <CheckRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
                      ) : (
                        <ClearRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
                      )}
                    </div>
                  }
                  title={noti ? "Đổi mật khẩu thành công!" : "Đổi mật khẩu thất bại!"}
                  subTitle={
                    noti ? (
                      <span>
                        <br />
                        Chúc mừng bạn!
                        <br />
                        Mật khẩu đã được cập nhật
                      </span>
                    ) : (
                      <span>Vui lòng thử đặt lại mật khẩu</span>
                    )
                  }
                  actions={
                    <button
                      className="btn-confirm-style"
                      onClick={() => {
                        noti ? history.push(APP_ROUTES.LOGIN) : setNotiSignUp(false);
                      }}>
                      {noti ? " Đăng nhập" : "Thử lại"}
                    </button>
                  }
                  onClose={() => (noti ? history.push(APP_ROUTES.LOGIN) : setNotiSignUp(false))}
                />
              </>
            );
          }}
        />
      </div>
      <img className="img-right" src={RightImage} alt="right" />
    </div>
  );
};

export default ResetPassword;
