import { REQUEST_URL } from "../app/constants/request.contant";
import { AccountInputModel } from "../models";
import { PaginationQueryModel } from "../models/other.model";
import { RequestUtil } from "../utils/request.util";

export const AccountService = {
  getAccount: (
    query: PaginationQueryModel & {
      status?: string;
      roleIds?: number[];
      searchString?: string;
    }
  ) => {
    const url = REQUEST_URL.ACCOUNT_LIST;

    return RequestUtil.get({ url, query: query });
  },

  getAccountDetailById: (accountId: number) => {
    const url = `${REQUEST_URL.ACCOUNT_LIST}/${accountId}`;
    return RequestUtil.get({ url });
  },

  getRoleList: () => {
    const url = REQUEST_URL.ROLE;
    return RequestUtil.get({ url });
  },

  createAccount: (data: AccountInputModel) => {
    const url = REQUEST_URL.ACCOUNT_LIST;
    return RequestUtil.post({ url, data });
  },

  updateAccount: (data: AccountInputModel) => {
    const url = REQUEST_URL.ACCOUNT_LIST;
    return RequestUtil.put({ url, data });
  },

  updateAccountActive: (data: { accountId: number; status: string }) => {
    const url = REQUEST_URL.ACCOUNT_ACTIVATE;
    return RequestUtil.put({ url, data });
  },

  updateAccountActiveNotify: (data: { id: number; type: string; isActive: boolean }) => {
    const url = REQUEST_URL.ACCOUNT_ACTIVATE_NOTIFY;
    return RequestUtil.put({ url, data });
  },

  deleteAccount: (id: number) => {
    const url = REQUEST_URL.DELETE_ACCOUNT.replace("{id}", id.toString());

    return RequestUtil.delete({ url, query: id });
  },

  recoverAccount: (data: { id: number }) => {
    const url = REQUEST_URL.RECOVER_ACCOUNT;
    return RequestUtil.put({ url, data });
  },
};
