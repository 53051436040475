/* eslint-disable */
import { styled } from "@mui/system";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

export type P_Props<T = any> = {
  data: T[];
  renderItem: (item: T) => any;
  onChange: (data: { id: string; index: number }[]) => any;
  itemPositionChange?: (data: { position: number; id: string }) => any;
};

const StyledDndList = styled("div")({
  [`.custom-scrollable-list__content__item:last-child`]: {
    marginBottom: "1.5rem",
  },
});

const DndList: React.FC<P_Props> = (props) => {
  const [tempList, setTempList] = useState<any[]>([]);

  useEffect(() => {
    setTempList(props.data);
  }, [props.data]);

  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver: any) => ({
    padding: "0",
  });

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) return;

    const list = [...tempList];
    const [removed] = list.splice(result.source.index, 1);

    list.splice(result.destination.index, 0, removed);

    setTempList(list);
    props.onChange(list.map((i, idx) => ({ id: i.id, index: idx })));
    props.itemPositionChange?.({ position: result.destination.index, id: removed.id });
  };

  return (
    <StyledDndList>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}>
              {tempList.map((item, index) => (
                <Draggable key={index} draggableId={index.toString()} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                      {props.renderItem(item)}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </StyledDndList>
  );
};

export default DndList;
