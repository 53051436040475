/*eslint-disable*/
import { Autocomplete } from "@material-ui/lab";
import { Clear as ClearIcon, Search as SearchIcon } from "@mui/icons-material";
import {
  Dialog,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Field, Form } from "react-final-form";
import { useFetchWithoutPagination } from "../../../../../hooks/useFetchWithoutPagination";
import useNoti from "../../../../../hooks/useNoti";
import { AppNotifications, TypeDevice, WithoutAreaDeviceOutputModel } from "../../../../../models";
import { DeviceService } from "../../../../../services";
import { checkLinkImage, FormUIUtil, FormUtil } from "../../../../../utils";
import Loading from "../../../../components/Loading";
import "./index.scss";

type P_Props = {
  setValStep: (num: number) => void;
};

const AreaDevice: React.FC<P_Props> = ({ setValStep }) => {
  const localStorageData = localStorage.getItem("devices");
  const data = JSON.parse(localStorageData as any);
  const [pushNoti] = useNoti();
  const mounted = useRef(false);
  const [withoutArea, setWithoutArea] = useState<any>();
  const [searchValue, setSearchValue] = useState<{ position: number; value: string }>({
    position: 0,
    value: "",
  });
  const [deviceTypeId, setDeviceTypeId] = useState<string>("");
  const [openCreateSite, setOpenCreateSite] = useState<number>(0);
  // const [submitErrorDupId, setSubmitErrorDupId] = useState<number[]>([]);
  const [devices, setDevices] = useState<any>(
    Array.from(Array(32)).map((_, index: number) => {
      return { position: index + 1 };
    })
  );

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const { data: deviceTypeData } = useFetchWithoutPagination(DeviceService.getTypeDevice);

  const fetchDataWithoutArea: () => Promise<void> = async () => {
    try {
      const result = await DeviceService.getWithoutArea({
        page: 1,
        limit: 500,
        // id: data ? data.id : undefined,
      });
      if (mounted.current) {
        setWithoutArea(result.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchDataWithoutArea();
  }, [devices]);

  useEffect(() => {
    deviceTypeData && setDeviceTypeId(deviceTypeData[0]?.id.toString());
  }, [deviceTypeData]);

  const dataWithoutArea = withoutArea && [
    { name: "+ Thêm thiết bị mới" },
    ...withoutArea?.filter(
      (f: WithoutAreaDeviceOutputModel) =>
        f.deviceType.id !==
        devices.find((d: any) => d.code === f.code && d.deviceType === f.deviceType.id)?.deviceType
    ),
  ];

  const submitFormDevice = async (values: any) => {
    DeviceService.createDevice({ code: values.code, deviceTypeId: +deviceTypeId, status: "active" })
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          pushNoti(AppNotifications.SUCCESS, "Tạo thiết bị thành công!");
          setTimeout(() => {
            fetchDataWithoutArea();
          });
          onUpdateQuantity(
            openCreateSite,
            res.data.data?.id,
            res.data.data?.code,
            res.data.data?.name,
            res.data.data?.deviceType?.id
          );
          setOpenCreateSite(0);
        } else {
          pushNoti(AppNotifications.ERROR, "Tạo thiết bị thất bại!");
        }
      })
      .catch((err) => {
        pushNoti(AppNotifications.ERROR, "Tạo thiết bị thất bại!");
        console.log(err);
      });
  };

  const onUpdateQuantity = (
    position: number,
    id: number | undefined,
    code: string | undefined,
    name: string | undefined,
    deviceType: number | undefined,
    addNew?: boolean
  ) => {
    const index = devices.findIndex((item: any) => item.position === position);

    addNew = false;
    let dataDevice = [];

    dataDevice = [...devices];

    if (index !== -1) {
      if (addNew) {
        return;
      }
      // update
      dataDevice[index] = {
        ...dataDevice[index],
        id: id,
        position: position,
        code: code,
        name: name,
        deviceType: deviceType,
      };
    } else {
      // thêm mới

      dataDevice.push({ id, position, code, name, deviceType });
    }

    setDevices(dataDevice);
  };

  useEffect(() => {
    data &&
      withoutArea &&
      setDevices(
        Array.from(Array(32)).map((_, index: number) => {
          const de = data?.find((d: any) => +d.position - 1 === index);

          if (de) {
            return {
              id: de.id,
              position: de.position,
              code: withoutArea.find((d: any) => d.id === de.id)?.code,
              name: withoutArea.find((d: any) => d.id === de.id)?.name,
              deviceType: withoutArea.find((d: any) => d.id === de.id)?.deviceType?.id,
            };
          }
          return { position: index + 1 };
        })
      );
  }, [withoutArea?.length]);

  const submitForm = async () => {
    let val = [];
    val = devices
      .filter((device: any) => device.id !== undefined)
      .map((d: any) => {
        return {
          id: d.id,
          position: d.position,
        };
      });
    localStorage.setItem("devices", JSON.stringify(val));
  };

  return (
    <div className="area-device">
      <div className="area-device-form">
        <div className="title">Danh sách thiết bị</div>
        <div className="list">
          <Grid container columnSpacing={5}>
            {withoutArea ? (
              devices.map((d: any, idx: number) => {
                return (
                  <Grid item xs={4} className="item" key={idx}>
                    <div style={{ width: "2rem", marginRight: "1.2rem" }}>{d.position}</div>
                    <Autocomplete
                      inputValue={
                        d.code
                          ? `${d.code} - ${
                              withoutArea.find((a: any) => a.id === d.id)?.deviceType?.name || ""
                            } `
                          : searchValue.position === d.position
                          ? searchValue.value
                          : ""
                      }
                      fullWidth
                      options={dataWithoutArea}
                      getOptionLabel={(option) =>
                        option.name !== "+ Thêm thiết bị mới"
                          ? `${option.code} - ${option.deviceType?.name}`
                          : ""
                      }
                      onChange={(e, value: any) => {
                        if (value?.name === "+ Thêm thiết bị mới") {
                          return;
                        } else
                          onUpdateQuantity(
                            d.position,
                            value?.id,
                            value?.code,
                            value?.name,
                            value?.deviceType?.id
                          );
                      }}
                      getOptionSelected={(option, value) =>
                        option.name !== "+ Thêm thiết bị mới" && option.code === value.code
                      }
                      onInputChange={(e: any, value: any) => {
                        // const value = e.target?.value;
                        setSearchValue({
                          position: d.position,
                          value: value,
                        });
                      }}
                      noOptionsText={
                        <div className="noOptionsText">
                          {dataWithoutArea.map(
                            (option: WithoutAreaDeviceOutputModel, idx: number) => {
                              return (
                                <div key={idx} className="area-modal-site-menu">
                                  {option.code ? (
                                    <div className="area-modal-site-menu-hover">
                                      <div
                                        className="area-modal-site-menu-item"
                                        onMouseDown={() => {
                                          onUpdateQuantity(
                                            d.position,
                                            option?.id,
                                            option?.code,
                                            option?.name,
                                            option?.deviceType?.id
                                          );
                                        }}>
                                        <div className="code">{option.code}&ensp;&#9679;</div>
                                        &ensp;
                                        {option.deviceType?.name}
                                      </div>
                                      <Divider style={{ margin: "0 1.5rem" }} />
                                    </div>
                                  ) : (
                                    <div className="area-modal-site-menu-hover">
                                      <div
                                        className="area-modal-site-menu-item"
                                        style={{
                                          color: "#41AD49",
                                        }}
                                        onMouseDown={() => {
                                          setOpenCreateSite(d.position);
                                        }}>
                                        {option.name}
                                      </div>
                                      <Divider style={{ margin: "0 1.5rem" }} />
                                    </div>
                                  )}
                                </div>
                              );
                            }
                          )}
                        </div>
                      }
                      defaultValue={withoutArea.find((a: any) => a.code === d.code)}
                      renderOption={(option: WithoutAreaDeviceOutputModel) => (
                        <div className="area-modal-site-menu">
                          {option.code ? (
                            <>
                              <div className="area-modal-site-menu-item">
                                <div className="code">{option.code}&ensp;&#9679;</div>
                                &ensp;
                                {option.deviceType?.name}
                              </div>
                              <Divider />
                            </>
                          ) : (
                            <>
                              <div
                                className="area-modal-site-menu-item"
                                style={{ color: "#41AD49" }}
                                onClick={() => {
                                  setOpenCreateSite(d.position);
                                }}>
                                {option.name}
                              </div>
                              <Divider />
                            </>
                          )}
                        </div>
                      )}
                      renderInput={(params) => (
                        <div className={"item-textfield"}>
                          <SearchIcon />
                          <TextField
                            {...params}
                            placeholder="Tìm theo mã, tên,..."
                            color="success"
                            className="textfield__root"
                            InputProps={{
                              ...params.InputProps,
                            }}
                            // error={submitErrorDupId?.includes(d.id)}
                          />
                        </div>
                      )}
                    />
                    <div
                      style={{ cursor: "pointer", marginLeft: "1.5rem" }}
                      onClick={() => {
                        onUpdateQuantity(d.position, undefined, undefined, undefined, undefined);
                      }}>
                      <ClearIcon fontSize="large" />
                    </div>
                  </Grid>
                );
              })
            ) : (
              <Loading style={{ marginTop: "18rem" }} />
            )}
          </Grid>
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn-cancel"
          onClick={() => {
            submitForm();
            setValStep(0);
          }}>
          Quay lại
        </button>
        <input
          value={"Tiếp tục"}
          aria-label="submit supplier form"
          form={"area-form"}
          type="submit"
          onClick={() => {
            submitForm();
            setValStep(2);
          }}
        />
      </div>

      <Dialog fullWidth maxWidth="sm" open={openCreateSite !== 0}>
        <div className="dialog-create-site">
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div className="title">Tạo mới thiết bị</div>
            <div onClick={() => setOpenCreateSite(0)}>
              <ClearIcon style={{ fontSize: "3rem", cursor: "pointer" }} />
            </div>
          </div>
          <Form onSubmit={async (values: any) => submitFormDevice(values)}>
            {({ handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit} id="area-form">
                  <Field name="code" validate={FormUtil.Rule.required("Xin nhập Mã thiết bị")}>
                    {({ input, meta, ...rest }) => {
                      return FormUIUtil.renderFormItem(
                        "Mã thiết bị",
                        <TextField
                          {...input}
                          {...rest}
                          fullWidth
                          placeholder={"Nhập Mã thiết bị..."}
                          color="success"
                          className="form-text-field"
                          inputProps={{ className: "input" }}
                          variant="outlined"
                          onChange={(e) => input.onChange(e.target.value)}
                          helperText={meta.touched ? meta.error : ""}
                          error={meta.error && meta.touched}
                        />
                      );
                    }}
                  </Field>
                  {FormUIUtil.renderFormItem(
                    "Loại thiết bị",
                    <FormControl fullWidth>
                      <Select
                        fullWidth
                        value={deviceTypeId}
                        onChange={(e) => {
                          setDeviceTypeId(e.target.value as string);
                        }}
                        input={<OutlinedInput className="form-text-field" color="success" />}
                        renderValue={
                          deviceTypeId !== ""
                            ? undefined
                            : () => (
                                <span className="form__placeholder">- Chọn Loại thiết bị - </span>
                              )
                        }
                        variant="outlined"
                        className="form-select-field"
                        displayEmpty
                        MenuProps={{
                          className: "select-menu-list select-menu-list-border-item",
                        }}>
                        {deviceTypeData?.length &&
                          deviceTypeData.map((d: TypeDevice) => {
                            return (
                              <MenuItem key={d.id} value={d.id}>
                                <img src={checkLinkImage(d?.avatar as string)} alt="icon" />
                                &ensp;
                                {d.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  )}
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <button className="btn-cancel-style" onClick={() => setOpenCreateSite(0)}>
                      Huỷ
                    </button>
                    <button className="btn-confirm-style">Tạo mới</button>
                  </div>
                </form>
              );
            }}
          </Form>
        </div>
      </Dialog>
    </div>
  );
};

export default AreaDevice;
