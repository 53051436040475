/*eslint-disable */
import { Clear as ClearIcon, Search as SearchIcon } from "@mui/icons-material";
import {
  Checkbox,
  Dialog,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Field, Form } from "react-final-form";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Pig from "../../../../assets/images/pig.png";
import { useFetchWithoutPagination } from "../../../../hooks/useFetchWithoutPagination";
import useNoti from "../../../../hooks/useNoti";
import {
  AccountInputModel,
  AccountStatus,
  AppNotifications,
  FormUploadImageModel,
  RoleOutputModel,
  SiteOutputModel,
  UploadedImageModel,
} from "../../../../models";
import { Nullable } from "../../../../models/shared";
import { AccountService } from "../../../../services";
import { SiteService } from "../../../../services/site.service";
import { FormUtil } from "../../../../utils/form.util";
import { FormUIUtil } from "../../../../utils/formUI.util";
import { checkLinkImage, renderErrorImage } from "../../../../utils/other.util";
import ImageUpload from "../../../components/ImageUpload";
import ModalFooter from "../../../components/ModalFooter";
import SelectInput from "../../../components/SelectInput";
import SimpleModalHeader from "../../../components/SimpleModalHeader";
import DefaultSwitch from "../../../components/Switch";
import { REGEX } from "../../../constants";
import { APP_ROUTES } from "../../../constants/module";
import { TEXT_HOLDER } from "../../../constants/utilities";
import "./index.scss";

type P_Props = {
  closeForm: () => void;
  data?: any;
  pending?: boolean;
};

const AccountModal: React.FC<P_Props> = ({ closeForm, data, pending }) => {
  const [pushNoti] = useNoti();
  const history = useHistory();
  const mounted = useRef(false);
  const [receiveNotify, setReceiveNotify] = useState<boolean>(data ? data.receiveNotify : true);
  const [status, setStatus] = useState<boolean>(
    data && data.status === AccountStatus.INACTIVE ? false : true
  );
  const [role, setRole] = useState<string>(data && data.role?.id ? data.role?.id : "");
  const [siteData, setSiteData] = useState<Nullable<any>>(null);
  const [siteDataListValue, setSiteDataListValue] = useState<number[]>(
    data?.sites
      ? data.sites?.map((site: SiteOutputModel) => {
          return site.id;
        })
      : []
  );
  const [submitErrorRole, setSubmitErrorRole] = useState<boolean>(false);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const { data: roleData } = useFetchWithoutPagination(AccountService.getRoleList);

  const fetchDataSiteList = async (query: any) => {
    try {
      const result = await SiteService.getAllSite(query);
      if (mounted.current) {
        setSiteData(result.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchDataSiteList({
      page: 1,
      limit: 500,
    });
    // eslint-disable-next-line
  }, []);

  const [avatar, setAvatar] = useState<Array<FormUploadImageModel & { file: any }>>([]);

  useEffect(() => {
    setAvatar(
      data?.avatar
        ? [
            {
              id: "",
              src: `${checkLinkImage(data?.avatar)}`,
              alt: "",
              file: null,
            },
          ]
        : []
    );
  }, [data]);

  const createAccount = (data: any) => {
    AccountService.createAccount(data)
      .then(async (res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          pushNoti(AppNotifications.SUCCESS, "Tạo tài khoản thành công!");
          history.push(APP_ROUTES.ACCOUNT_DETAIL.replace(":accountId", res.data?.id.toString()));
        } else if (res && res.status === 400) {
          if (res?.data.message === "EMAIL_ALREADY_IN_USE") {
            pushNoti(AppNotifications.ERROR, "Email đã được sử dụng");
          } else if (res?.data.message === "ACCOUNT_HAS_BEEN_BLOCKED_OR_DELETED") {
            pushNoti(AppNotifications.ERROR, "Tài khoản tồn tại");
          } else if (res?.data.message === "PHONE_NUMBER_ALREADY_IN_USE") {
            pushNoti(AppNotifications.ERROR, "Số điện thoại đã được sử dụng");
          } else if (res?.data.message === "CODE_ALREADY_IN_USE") {
            pushNoti(AppNotifications.ERROR, "Mã đăng nhập đã được sử dụng");
          } else if (res?.data.message === "ROLE_NOT_FOUND") {
            pushNoti(AppNotifications.ERROR, "Không tìm thấy vai trò");
          } else if (res?.data.message === "SITE_NOT_FOUND") {
            pushNoti(AppNotifications.ERROR, "Không tìm thấy trại");
          } else {
            pushNoti(AppNotifications.ERROR, "Tạo tài khoản thất bại!");
          }
        }
      })
      .catch((err) => {
        pushNoti(AppNotifications.ERROR, "Tạo tài khoản thất bại!");
        console.log(err);
      });
  };

  const updateAccount = (data: any) => {
    AccountService.updateAccount(data)
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          pushNoti(
            AppNotifications.SUCCESS,
            pending ? "Phê duyệt tài khoản thành công!" : "Chỉnh sửa tài khoản thành công!"
          );
          pending
            ? history.push(
                APP_ROUTES.ACCOUNT_DETAIL.replace(":accountId", res.data?.data?.id.toString())
              )
            : setTimeout(() => {
                window.location.href = window.location.href;
              }, 500);
        } else if (res && res.status === 400) {
          if (res?.data.message === "EMAIL_ALREADY_IN_USE") {
            pushNoti(AppNotifications.ERROR, "Email đã được sử dụng");
          } else if (res?.data.message === "ACCOUNT_HAS_BEEN_BLOCKED_OR_DELETED") {
            pushNoti(AppNotifications.ERROR, "Tài khoản tồn tại");
          } else if (res?.data.message === "PHONE_NUMBER_ALREADY_IN_USE") {
            pushNoti(AppNotifications.ERROR, "Số điện thoại đã được sử dụng");
          } else if (res?.data.message.includes("CODE_ALREADY_IN_USE")) {
            pushNoti(AppNotifications.ERROR, "Mã đăng nhập đã được sử dụng");
          } else {
            pushNoti(
              AppNotifications.ERROR,
              pending ? "Phê duyệt tài khoản thất bại!" : "Chỉnh sửa tài khoản thất bại!"
            );
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const submitForm = async (values: any) => {
    if (role === "") {
      return;
    }
    const val = {
      ...values,
      id: data ? data?.id : undefined,
      avatar: avatar[0] && avatar[0].file ? avatar[0].file : undefined,
      password: data ? undefined : values.password,
      confirmPassword: data ? undefined : values.confirmPassword,
      status: !status ? AccountStatus.INACTIVE : AccountStatus.ACTIVE,
      receiveNotify: receiveNotify,
      roleId: role,
      siteIds: siteDataListValue,
    } as any;

    const formData = new FormData();
    Object.keys(val).forEach((key) => {
      if (key === "avatar") {
        // console.log(key, val[key], val[key] instanceof File);
      }
      let value;
      if (typeof val[key] === "string") {
        value = val[key];
      } else if (typeof val[key] === "number") {
        value = +val[key];
      } else if (Array.isArray(val[key])) {
        value = JSON.stringify(val[key]);
      } else if (val[key] instanceof File) {
        value = val[key];
      } else if (typeof val[key] === "boolean") {
        value = receiveNotify === true ? "true" : "false";
      }
      if (value !== undefined) {
        formData.append(key, value);
      }
    });

    if (data && data?.id) return updateAccount(formData);

    return createAccount(formData);
  };

  return (
    <Dialog fullScreen open={true}>
      <div className="account-modal">
        <SimpleModalHeader
          title={data ? (pending ? "Phê duyệt tài khoản" : "Chỉnh sửa tài khoản") : "Cấp tài khoản"}
          onClose={() => (data ? closeForm() : history.push(APP_ROUTES.ACCOUNT))}
        />
        <div className="content">
          <ImageUpload
            className="product-form-avatar"
            fileList={avatar}
            max={1}
            onChange={(data: UploadedImageModel) => {
              setAvatar([
                {
                  id: uuidv4(),
                  src: data.fileStr,
                  alt: data.alt || "",
                  file: data.file,
                },
              ]);
            }}
            onDelete={() => {
              setAvatar([]);
            }}
            config={{ uploadModal: true }}
          />
          <Form
            onSubmit={async (values: AccountInputModel) => submitForm(values)}
            initialValues={data}>
            {({ handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit} id="account-form" className="account-modal-form">
                  <Grid container>
                    <Grid item xs={6} className="title">
                      Thông tin chung
                    </Grid>
                    {!pending && (
                      <Grid
                        item
                        xs={6}
                        style={{ color: status === true ? "#41AD49" : "#ADACAC" }}
                        className="status">
                        <DefaultSwitch
                          checked={status}
                          onChange={() => {
                            setStatus(!status);
                          }}
                        />
                        {status == true ? "Kích hoạt" : "Vô hiệu hóa"}
                      </Grid>
                    )}
                  </Grid>
                  <Grid container spacing={6}>
                    <Grid item xs={6}>
                      <Field name="code">
                        {({ input, meta, ...rest }) => {
                          return FormUIUtil.renderFormItem(
                            "Mã nhân viên",
                            <TextField
                              {...input}
                              {...rest}
                              fullWidth
                              placeholder={"Nhập mã nhân viên.."}
                              color="success"
                              className="form-text-field"
                              inputProps={{ className: "input" }}
                              variant="outlined"
                              onChange={(e) => input.onChange(e.target.value)}
                              helperText={meta.touched ? meta.error : ""}
                              error={meta.error && meta.touched}
                            />
                          );
                        }}
                      </Field>
                      <Field
                        name="email"
                        validate={FormUtil.composeValidators([
                          FormUtil.Rule.required("Xin nhập Địa chỉ E-mail "),
                          FormUtil.Rule.pattern(REGEX.EMAIL, {
                            errorMessage: "E-mail không đúng định dạng",
                          }),
                        ])}>
                        {({ input, meta, ...rest }) => {
                          return FormUIUtil.renderFormItem(
                            "Địa chỉ E-mail ",
                            <TextField
                              {...input}
                              {...rest}
                              fullWidth
                              placeholder={"Nhập email.."}
                              color="success"
                              className="form-text-field"
                              inputProps={{ className: "input" }}
                              variant="outlined"
                              onChange={(e) => input.onChange(e.target.value)}
                              helperText={meta.touched ? meta.error : ""}
                              error={meta.error && meta.touched}
                            />
                          );
                        }}
                      </Field>

                      {FormUIUtil.renderFormItem(
                        "Vai trò",
                        <FormControl fullWidth>
                          <Select
                            fullWidth
                            value={role}
                            onChange={(e) => {
                              setRole(e.target.value as string);
                            }}
                            input={<OutlinedInput className="form-text-field" color="success" />}
                            renderValue={
                              role !== ""
                                ? undefined
                                : () => (
                                    <span className="form__placeholder">
                                      Chọn vai trò cho người dùng
                                    </span>
                                  )
                            }
                            onBlur={(e) => {
                              setSubmitErrorRole(role === "");
                            }}
                            variant="outlined"
                            className="form-select-field"
                            error={submitErrorRole}
                            displayEmpty
                            MenuProps={{
                              className: "select-menu-list select-menu-list-border-item",
                            }}>
                            {roleData?.length &&
                              roleData?.map((r: RoleOutputModel, index: number) => {
                                return (
                                  <MenuItem key={index} value={r.id}>
                                    {r.name}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                          <FormHelperText>
                            {submitErrorRole && "Chọn vai trò cho người dùng"}
                          </FormHelperText>
                        </FormControl>
                      )}
                      {(!data || (data && pending)) && (
                        <div className="email">
                          <Checkbox
                            className="app-check-box"
                            checked={receiveNotify}
                            onChange={() => setReceiveNotify(!receiveNotify)}
                            sx={{ "& .MuiSvgIcon-root": { fontSize: 24, color: "#41AD49" } }}
                          />
                          <div className="email-text">Gửi email thông báo cấp tài khoản</div>
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        name="name"
                        validate={FormUtil.Rule.required("Xin nhập Tên tài khoản ")}>
                        {({ input, meta, ...rest }) => {
                          return FormUIUtil.renderFormItem(
                            "Tên tài khoản ",
                            <TextField
                              {...input}
                              {...rest}
                              fullWidth
                              placeholder={"Nhập tên tài khoản..."}
                              color="success"
                              className="form-text-field"
                              inputProps={{ className: "input" }}
                              variant="outlined"
                              onChange={(e) => input.onChange(e.target.value)}
                              helperText={meta.touched ? meta.error : ""}
                              error={meta.error && meta.touched}
                            />
                          );
                        }}
                      </Field>
                      <Field
                        name="phoneNumber"
                        validate={FormUtil.composeValidators([
                          FormUtil.Rule.required("Xin nhập số điện thoại "),
                          FormUtil.Rule.pattern(REGEX.PHONE_NUMBER, {
                            errorMessage: "Số điện thoại không đúng định dạng",
                          }),
                        ])}>
                        {({ input, meta, ...rest }) => {
                          return FormUIUtil.renderFormItem(
                            "Số điện thoại",
                            <TextField
                              {...input}
                              {...rest}
                              fullWidth
                              placeholder={"Nhập số điện thoại..."}
                              color="success"
                              className="form-text-field"
                              inputProps={{ className: "input" }}
                              variant="outlined"
                              onChange={(e) => input.onChange(e.target.value)}
                              helperText={meta.touched ? meta.error : ""}
                              error={meta.error && meta.touched}
                            />
                          );
                        }}
                      </Field>
                      {FormUIUtil.renderFormItem(
                        "Phân bổ trại",
                        <SelectInput<SiteOutputModel>
                          className={"item-textfield"}
                          icon={<SearchIcon />}
                          onChange={(value) => {}}
                          placeholder="Chọn trại được truy cập..."
                          getOptionLabel={(opt: SiteOutputModel) => ""}
                          renderOption={(option: SiteOutputModel) => (
                            <>
                              <button
                                disabled={siteDataListValue?.some((e) => e === option.id)}
                                key={option.id}
                                className="site-item-menu-list"
                                onClick={() => {
                                  setSiteDataListValue([...siteDataListValue, option.id]);
                                }}>
                                <img
                                  src={checkLinkImage(option?.avatar as string) || Pig}
                                  alt="avt"
                                  onError={renderErrorImage()}
                                />
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    fontFamily: "SVN-Gotham",
                                  }}>
                                  {option.name}
                                  <div className="code">{option.code}</div>
                                </div>
                              </button>
                              <Divider style={{ margin: "1rem 0" }} />
                            </>
                          )}
                          actionConfig={{
                            action: SiteService.getAllSite,
                          }}
                        />
                      )}
                      {siteDataListValue?.map((site: number) => {
                        const siteVal =
                          !!siteData &&
                          siteData.find((value: SiteOutputModel) => value.id === site);
                        return (
                          <div key={site} className="site-item">
                            <div className="name">
                              <img
                                src={checkLinkImage(siteVal?.avatar as string) || Pig}
                                alt="avt"
                                onError={renderErrorImage()}
                              />
                              <div>
                                <div>{siteVal?.name || TEXT_HOLDER}</div>
                                <div className="code">{siteVal?.code || TEXT_HOLDER}</div>
                              </div>
                            </div>
                            <ClearIcon
                              onClick={() =>
                                setSiteDataListValue(
                                  siteDataListValue.filter((e) => e !== siteVal?.id)
                                )
                              }
                              style={{ fontSize: "1.8rem", fontWeight: "bold", cursor: "pointer" }}
                            />
                          </div>
                        );
                      })}
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          </Form>
        </div>
        {pending ? (
          <div className="footer-pending">
            <input
              value={"Vô hiệu hóa"}
              aria-label="submit supplier form"
              form="account-form"
              type="submit"
              className="btn-delete"
              onClick={() => {
                setStatus(false);
                setSubmitErrorRole(role === "");
              }}
            />
            <input
              value={"Phê duyệt"}
              aria-label="submit supplier form"
              form="account-form"
              type="submit"
              onClick={() => {
                setStatus(true);
                setSubmitErrorRole(role === "");
              }}
            />
          </div>
        ) : (
          <ModalFooter
            formId="account-form"
            showDeleteButton={false}
            handleDeleteAction={() => {}}
            handleCancelAction={() => (data ? closeForm() : history.push(APP_ROUTES.ACCOUNT))}
            title={data ? "Lưu" : "Cấp tài khoản"}
            handleSubmit={() => {
              setSubmitErrorRole(role === "");
            }}
          />
        )}
      </div>
    </Dialog>
  );
};

export default AccountModal;
