import React from "react";
import { RouteProps } from "react-router-dom";
import { AccountIcon } from "../../components/Icons/AccountIcon";
import { AlarmIcon } from "../../components/Icons/AlarmNoti";
import { AreaIcon } from "../../components/Icons/AreaIcon";
import { DeviceIcon } from "../../components/Icons/DeviceIcon";
import { ReportIcon } from "../../components/Icons/ReportIcon";
import { SiteIcon } from "../../components/Icons/SiteIcon";
import { APP_ROUTES } from "../../constants/module";
import Account from "../../containers/Account";
import AccountDetail from "../../containers/Account/AccountDetail";
import AccountModal from "../../containers/Account/AccountModal";
import Alarm from "../../containers/Alarm";
// import AlarmModal from "../../containers/Alarm/AlarmModal";
import Area from "../../containers/Area";
import AreaDetail from "../../containers/Area/AreaDetail";
import AreaModal from "../../containers/Area/AreaModal";
import Device from "../../containers/Device";
import DeviceModal from "../../containers/Device/DeviceModal";
import Profile from "../../containers/Profile";
import ProfileReport from "../../containers/Profile/Report";
import Report from "../../containers/Report";
import Site from "../../containers/Site";
import SiteDetail from "../../containers/Site/SiteDetail";
import SiteModal from "../../containers/Site/SiteModal";

export const DEFAULT_COLOR = "#6C778D";
export const ACTIVE_COLOR = "#FFF";
export const DEFAULT_SIZE: [number, number] = [28, 28];

export type ModuleItem = {
  title: string;
  label: string;
  defaultIcon: React.ReactElement;
  activeIcon: React.ReactElement;
  path: string;
  subRoutes: Array<ModuleItem>;
  enabled: boolean;
};

const moduleList: ModuleItem[] = [
  {
    title: "Quản lý trại",
    label: "Trại",
    defaultIcon: <SiteIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
    activeIcon: <SiteIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
    path: APP_ROUTES.SITE,
    enabled: true,
    subRoutes: [],
  },
  {
    title: "Quản lý chuồng",
    label: "Chuồng",
    defaultIcon: <AreaIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
    activeIcon: <AreaIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
    path: APP_ROUTES.AREA,
    enabled: true,
    subRoutes: [],
  },
  {
    title: "Danh sách thiết bị",
    label: "Thiết bị",
    defaultIcon: <DeviceIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
    activeIcon: <DeviceIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
    path: APP_ROUTES.DEVICE,
    enabled: true,
    subRoutes: [],
  },
  {
    title: "Tài khoản và phân quyền",
    label: "Tài khoản",
    defaultIcon: <AccountIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
    activeIcon: <AccountIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
    path: APP_ROUTES.ACCOUNT,
    enabled: true,
    subRoutes: [],
  },
  {
    title: "Danh sách cảnh báo",
    label: "Cảnh báo",
    defaultIcon: <AlarmIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
    activeIcon: <AlarmIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
    path: APP_ROUTES.ALARM,
    enabled: true,
    subRoutes: [],
  },
  {
    title: "Báo cáo",
    label: "Báo cáo",
    defaultIcon: <ReportIcon color={DEFAULT_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
    activeIcon: <ReportIcon color={ACTIVE_COLOR} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />,
    path: APP_ROUTES.REPORT,
    enabled: true,
    subRoutes: [],
  },
];

export const appRoutes: Array<RouteProps> = [
  {
    exact: true,
    path: APP_ROUTES.SITE,
    component: Site,
  },
  {
    exact: true,
    path: APP_ROUTES.SITE_CREATE,
    component: SiteModal,
  },
  { exact: true, path: APP_ROUTES.SITE_DETAIL, component: SiteDetail },
  {
    exact: true,
    path: APP_ROUTES.AREA,
    component: Area,
  },
  {
    exact: true,
    path: APP_ROUTES.AREA_CREATE,
    component: AreaModal,
  },
  { exact: true, path: APP_ROUTES.AREA_DETAIL, component: AreaDetail },
  {
    exact: true,
    path: APP_ROUTES.DEVICE,
    component: Device,
  },
  {
    exact: true,
    path: APP_ROUTES.DEVICE_CREATE,
    component: DeviceModal,
  },
  {
    exact: true,
    path: APP_ROUTES.ACCOUNT,
    component: Account,
  },
  {
    exact: true,
    path: APP_ROUTES.ACCOUNT_DETAIL,
    component: AccountDetail,
  },
  {
    exact: true,
    path: APP_ROUTES.ACCOUNT_CREATE,
    component: AccountModal,
  },
  {
    exact: true,
    path: APP_ROUTES.ALARM,
    component: Alarm,
  },
  {
    exact: true,
    path: APP_ROUTES.PROFILE,
    component: Profile,
  },
  {
    exact: true,
    path: APP_ROUTES.PROFILE_REPORT,
    component: ProfileReport,
  },
  // {
  //   exact: true,
  //   path: APP_ROUTES.ALARM_CREATE,
  //   component: AlarmModal,
  // },
  {
    exact: true,
    path: APP_ROUTES.REPORT,
    component: Report,
  },
];

export default moduleList;
