/* eslint-disable */
export const FormUIUtil = {
  renderFormItem,
};

function renderFormItem(label: string, value: any, withoutIcon = false) {
  // const icon = !withoutIcon ? (
  //   <InfoOutlinedIcon style={{ marginLeft: "0.5rem" }} />
  // ) : (
  //   ""
  // );
  return (
    <div className="form-item">
      <div className="form-label">
        {label}
        {/* {icon} */}
      </div>
      {value}
    </div>
  );
}
