import { REQUEST_URL } from "../app/constants";
import { ReceiveStatisticInput } from "../models";
import { RequestUtil } from "../utils";

export const StatisticService = {
  receiveStatistic: (data: ReceiveStatisticInput) => {
    const url = REQUEST_URL.RECEIVE_STATISTIC;

    return RequestUtil.put({ url, data: data });
  },
};
