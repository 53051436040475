/*eslint-disable */
import { assign } from "lodash";
import { useEffect, useReducer, useRef } from "react";
import { Action, FetchAction, PaginationQueryModel } from "../models";
import { PaginateFetchReducer } from "../models/shared";

const initialState = {
  page: 0,
  totalPages: 0,
  limit: 0,
  totalRecords: 0,
  data: [],
  fetching: false,
  errorMessage: "",
  count: {},
  loading: true,
};

function reducer(state: PaginateFetchReducer<any>, action: FetchAction) {
  switch (action.type) {
    case Action.FETCH:
      return { ...state, fetching: true };
    case Action.SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: [...state.data, ...action.payload.data],
        loading: false,
      };
    case Action.RESET:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: action.payload.data,
        loading: false,
      };

    case Action.FAILED:
      return { ...state, fetching: false, failMessage: action.payload };

    default:
      return { ...state };
  }
}

//Hook nay dung cho nhung API co phan trang
const useFetch = (
  action: any,
  query: PaginationQueryModel,
  loadMore?: number,
  params?: any,
  deps?: any
) => {
  const mounted = useRef(false);
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const fetchData = async (query: any, isReset?: boolean) => {
    if (state.fetching) return;
    dispatch({ type: Action.FETCH, payload: {} });
    try {
      const result = await action(query);
      if (isReset)
        dispatch({
          type: Action.RESET,
          payload: result.data,
        });
      else if (mounted.current)
        dispatch({
          type: Action.SUCCESS,
          payload: result.data,
        });
    } catch (err) {
      if (mounted)
        dispatch({
          type: Action.FAILED,
          payload: "Đã có lỗi xảy ra, hãy thử lại sau.",
        });
    }
  };

  const paramsData =
    params &&
    Object.keys(params)
      .filter((f: any) => params[f] !== undefined && params[f]?.length !== 0)
      .map((key) => params[key])
      .toString();

  useEffect(() => {
    fetchData({ ...query, ...params }, true);
  }, [query.searchString, state.loading, deps, paramsData]);

  useEffect(() => {
    if (loadMore) {
      fetchData({
        page: loadMore,
        limit: query.limit,
        searchString: query.searchString,
        ...params,
      });
    }
  }, []);

  return state;
};

export default useFetch;
