import React from "react";
import { IconBase, IconComponent } from "../../core/Icons";

export const SiteIcon: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <path
        d="M16.2554 15.2598H9.62847C9.32702 15.261 9.03836 15.3817 8.82565 15.5953C8.61295 15.8089 8.49353 16.0981 8.49353 16.3995V23.3053H10.4172V17.1834H16.2554V23.3053H18.179V16.3995C18.179 16.0981 18.0596 15.8089 17.8469 15.5953C17.6342 15.3817 17.3455 15.261 17.0441 15.2598H16.2554Z"
        fill={props.color}
      />
      <path
        d="M26.4217 10.5517L20.9202 4.46823L20.1699 3.64107L14.0143 0.16411C13.8261 0.056567 13.6131 0 13.3964 0C13.1796 0 12.9666 0.056567 12.7784 0.16411L6.60356 3.79015L5.91587 4.54998C5.89117 4.56825 5.86859 4.58922 5.84854 4.6125L0.255594 10.5421C0.0946736 10.7161 0.00369301 10.9435 0.00010999 11.1805C-0.00347303 11.4175 0.080594 11.6475 0.23618 11.8263C0.391766 12.0052 0.607912 12.1202 0.843143 12.1495C1.07837 12.1787 1.31612 12.1201 1.51076 11.9848V22.0454C1.51203 22.6925 1.76998 23.3126 2.228 23.7697C2.68601 24.2268 3.30667 24.4836 3.95377 24.4836H22.839C23.4861 24.4836 24.1067 24.2268 24.5647 23.7697C25.0228 23.3126 25.2807 22.6925 25.282 22.0454V12.0569C25.454 12.1422 25.6476 12.174 25.8379 12.1483C26.0282 12.1226 26.2064 12.0405 26.3496 11.9127C26.4439 11.8282 26.5206 11.7259 26.5753 11.6117C26.63 11.4975 26.6616 11.3736 26.6683 11.2472C26.675 11.1207 26.6567 10.9942 26.6144 10.8748C26.5721 10.7555 26.5066 10.6457 26.4217 10.5517ZM23.3584 22.0454C23.3571 22.1823 23.3018 22.3132 23.2045 22.4095C23.1073 22.5059 22.9759 22.5599 22.839 22.5599H3.95377C3.81685 22.5599 3.68547 22.5059 3.5882 22.4095C3.49094 22.3132 3.43566 22.1823 3.43439 22.0454V10.0227L7.81545 5.30019L13.2786 2.09255C13.3142 2.07138 13.3549 2.06021 13.3964 2.06021C13.4378 2.06021 13.4785 2.07138 13.5142 2.09255L18.9533 5.16073L19.4919 5.75706L21.5357 8.01732L23.3584 10.0275V22.0454Z"
        fill={props.color}
      />
      <path
        d="M13.3361 13.1437C12.6256 13.1437 11.9311 12.933 11.3403 12.5383C10.7496 12.1436 10.2891 11.5825 10.0172 10.9261C9.74533 10.2697 9.67419 9.54737 9.8128 8.85052C9.95142 8.15367 10.2936 7.51357 10.796 7.01117C11.2984 6.50877 11.9385 6.16663 12.6353 6.02801C13.3322 5.8894 14.0545 5.96054 14.7109 6.23244C15.3673 6.50434 15.9284 6.96478 16.3231 7.55554C16.7178 8.14631 16.9285 8.84086 16.9285 9.55136C16.9273 10.5037 16.5484 11.4167 15.8749 12.0901C15.2015 12.7636 14.2885 13.1425 13.3361 13.1437ZM13.3361 7.88742C13.0059 7.88742 12.6831 7.98542 12.4085 8.16899C12.134 8.35257 11.9201 8.61348 11.794 8.91868C11.6678 9.22389 11.635 9.55966 11.6998 9.8835C11.7646 10.2073 11.924 10.5047 12.1579 10.7379C12.3917 10.971 12.6895 11.1296 13.0136 11.1934C13.3376 11.2573 13.6733 11.2235 13.9781 11.0965C14.2829 10.9695 14.5432 10.7548 14.726 10.4798C14.9088 10.2047 15.0058 9.88161 15.0049 9.55136C15.0036 9.10961 14.8272 8.6864 14.5144 8.37449C14.2016 8.06257 13.7779 7.88742 13.3361 7.88742Z"
        fill={props.color}
      />
    </IconBase>
  );
};
