/*eslint-disable */
import { MenuItem, OutlinedInput, Select } from "@mui/material";
import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";

type TAction = (...K: any[]) => Promise<void | AxiosResponse<any>>;

type Props<T, P extends TAction = any> = {
  value: T | undefined;
  renderValue?: (opt: T) => React.ReactNode;
  renderItem: (item: T) => React.ReactNode;
  actionConfig: {
    action: P;
    getAll?: boolean;
    noAutoLoadOnFirst?: boolean;
    pageSize?: number;
    handleBuildActionParams?: (query: {
      page: number;
      limit: number;
      searchString?: string;
    }) => Parameters<P>;
  };
  onChange?: (opt: any) => void;
  onBlur?: (opt: T | null) => void;
  error?: boolean;
  placeholder?: string;
  dropdownBoxMaxHeight?: React.CSSProperties["maxHeight"];
  icon?: any;
  menuPropsClassName?: string;
  valueItem: string | number | readonly string[] | undefined;
  queryParams?: any;
  onClick?: () => void;
  disabled?: boolean;
};

type FectchedListState<Item> = {
  data: Item[];
  page: number;
  totalRecords: number;
  loading: boolean;
  limit: number;
  lastPageRequest: number;
};

const EMPTY_LIST_STATE: FectchedListState<any> = {
  data: [],
  page: 1,
  totalRecords: 1,
  loading: false,
  limit: 10,
  lastPageRequest: 0,
};

function SelectPagination<T, P extends TAction = any>(props: Props<T, P>): JSX.Element {
  const [listState, setListState] = useState<FectchedListState<T>>(EMPTY_LIST_STATE);

  const buildActionParams = (query: any) => {
    return [query];
  };

  const handleLoadData = (loadMore = false) => {
    if (listState.loading) return;
    if (listState.data.length >= listState.totalRecords && loadMore) return;

    const pageSize: number = props.actionConfig.pageSize || 12;
    const currentPage = Math.ceil(listState.data.length / pageSize);
    let page = loadMore ? currentPage + 1 : 1;

    if (page !== 1) {
      if (listState.lastPageRequest === page) {
        return;
      } else if (page - listState.lastPageRequest > 1) {
        page = listState.lastPageRequest ? listState.lastPageRequest + 1 : 2;
      }
    }

    const data = loadMore ? listState.data : [];
    setListState({ ...listState, loading: true, data, page, limit: pageSize });
  };

  //get value from params
  const paramsData = props.queryParams && Object.values(props.queryParams).toString();

  useEffect(() => {
    if (listState.loading) {
      const loadMore = listState.page !== 1;
      const tempQuery = {
        page: listState.page,
        limit: listState.limit,
      };

      const handleBuildActionParams =
        props.actionConfig.handleBuildActionParams || buildActionParams;
      props.actionConfig
        .action(...handleBuildActionParams!({ ...tempQuery, ...props.queryParams }))
        .then((res) => {
          if (res && (res.status === 200 || res.status === 201)) {
            let data = res.data.data;

            if (loadMore) {
              data = [...listState.data, ...data];
            }

            let totalRecords: number = res.data?.totalRecords || 1;
            if (!data.length) {
              totalRecords = 1;
            }

            setListState({
              ...listState,
              loading: false,
              data: data,
              limit: data,
              totalRecords,
              lastPageRequest: listState.page,
            });
          } else {
            setListState(EMPTY_LIST_STATE);
          }
        })
        .catch((err) => {
          setListState(EMPTY_LIST_STATE);
        });
    }
    // eslint-disable-next-line
  }, [listState.loading, paramsData]);

  useEffect(() => {
    handleLoadData(false);
    // eslint-disable-next-line
  }, [paramsData]);

  useEffect(() => {
    if (!props.actionConfig.noAutoLoadOnFirst) {
      handleLoadData(false);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Select
      fullWidth
      disabled={props.disabled}
      value={props.value}
      onChange={props.onChange}
      input={<OutlinedInput className="form-text-field" color="success" />}
      renderValue={props.renderValue}
      onBlur={() => props.onBlur}
      variant="outlined"
      className="form-select-field"
      error={props.error}
      displayEmpty
      MenuProps={{
        PaperProps: {
          onScroll: (e: any) => {
            const ulElement = e?.target;

            if (
              !props.actionConfig.getAll &&
              ulElement &&
              ulElement.scrollTop >= ulElement.scrollHeight - ulElement.clientHeight - 1
            ) {
              handleLoadData(true);
            }
          },
        },
        style: {
          maxHeight: props.dropdownBoxMaxHeight || "200px",
        },
        className: props.menuPropsClassName,
      }}>
      {listState.data?.length &&
        listState.data.map((item: any) => {
          return (
            <MenuItem key={item.id} value={item[`${props.valueItem}`]}>
              {props.renderItem(item)}
            </MenuItem>
          );
        })}
    </Select>
  );
}

export default SelectPagination;
