import React from "react";
import Banner from "../../../assets/images/responsiveMobile/banner.png";
import Icon2 from "../../../assets/images/responsiveMobile/ic-ap.png";
import Icon1 from "../../../assets/images/responsiveMobile/ic-gg.png";
import Image from "../../../assets/images/responsiveMobile/image.png";
import Logo from "../../../assets/images/mock/logo-title.png";
import "./index.scss";

const ReponsiveMobile: React.FC = () => {
  return (
    <div className="down-app">
      <div className="container">
        <img src={Logo} alt="logo" style={{ objectFit: "cover", height: "7rem" }} />
        <div className="img-lap">
          <img src={Image} alt="img" />
        </div>
        <div className="test-1">
          Truy cập Admin-DigiFarm thông
          <br />
          qua laptop hoặc máy tính bàn
          <br />
          để tối ưu trải nghiệm
        </div>
        <div className="test-2">
          Tiếp tục trải nghiệm ứng dụng giám
          <br />
          sát chuồng nuôi DigiFarm.
          <br />
          Ứng dụng sẵn có trên hệ điều hành
          <br />
          IOS và Android
        </div>
        <div style={{ display: "flex" }}>
          <button
            onClick={() =>
              (window.location.href =
                "https://play.google.com/store/apps/details?id=com.greenfeed.digifarm")
            }>
            <img src={Icon1} alt="ic1" style={{ width: "2.5rem", height: "2.9rem" }} />
            <div>
              GET IT ON
              <br />
              <span>Google Play</span>
            </div>
          </button>
          <button
            onClick={() =>
              (window.location.href = "https://apps.apple.com/vn/app/digifarm/id1629900099")
            }>
            <img src={Icon2} alt="ic2" style={{ width: "2.3rem", height: "2.8rem" }} />
            <div>
              Download on the
              <br />
              <span>App Store</span>
            </div>
          </button>
        </div>
        <img src={Banner} alt="banner" className="banner" />
      </div>
    </div>
  );
};

export default ReponsiveMobile;
