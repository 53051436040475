import React from "react";
import { IconBase, IconComponent } from "../../core/Icons";

export const DeviceIcon: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <path
        d="M8.38677 21.472L18.1367 21.4348M1.00929 4.43748C1.00683 3.79102 1.26127 3.17006 1.71664 2.7112C2.17202 2.25234 2.79102 1.99317 3.43748 1.99071L22.9373 1.91638C23.5838 1.91392 24.2048 2.16836 24.6636 2.62373C25.1225 3.07911 25.3817 3.69811 25.3841 4.34457L25.4259 15.3132C25.4284 15.9597 25.1739 16.5807 24.7186 17.0395C24.2632 17.4984 23.6442 17.7576 22.9977 17.76L3.49787 17.8343C2.85141 17.8368 2.23045 17.5824 1.77159 17.127C1.31273 16.6716 1.05356 16.0526 1.0511 15.4062L1.00929 4.43748Z"
        stroke={props.color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconBase>
  );
};
