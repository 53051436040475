/* eslint-disable */
import {
  Dialog,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Field, Form } from "react-final-form";
import useNoti from "../../../../hooks/useNoti";
import { AppNotifications, ErrorMessage, OPCTagOutPutModal } from "../../../../models";
import { AlarmInputModal, UpdateAlarmInputModal } from "../../../../models/alarm.model";
import { Nullable } from "../../../../models/shared";
import { AlarmService, OPCTagService } from "../../../../services";
import { FormUtil } from "../../../../utils/form.util";
import { FormUIUtil } from "../../../../utils/formUI.util";
import ModalFooter from "../../../components/ModalFooter";
import SimpleModalHeader from "../../../components/SimpleModalHeader";
import DefaultSwitch from "../../../components/Switch";
import { REGEX } from "../../../constants";
import {
  AlarmFilterAlarmType,
  AlarmFilterSendOptionCreate,
  ConditionAlarm,
} from "../../../constants/mock";
import { TEXT_HOLDER } from "../../../constants/utilities";
import "./index.scss";

type P_Props = {
  site: { id: number | undefined; name: string | undefined };
  area: { id: number | undefined; name: string | undefined };
  // eslint-disable-next-line
  data: any;
  closeForm: () => void;
};

const AlarmModal: React.FC<P_Props> = ({ site, area, data, closeForm }) => {
  const [pushNoti] = useNoti();
  const mounted = useRef(false);
  const [status, setStatus] = useState<boolean>(data ? data?.isActive : true);
  const [OPC, setOPC] = useState<number>(0);
  const [OPCTag, setOPCTag] = useState<Nullable<OPCTagOutPutModal[]>>(null);
  const [alarmType, setAlarmType] = useState<string>(
    data ? data?.alarmSettings?.[0].alarmType : ""
  );
  const [sendOptions, setSendOptions] = useState<any>("");
  const [submitErrorOPCTag, setSubmitErrorOPCTag] = useState<boolean>(false);
  const [condition, setCondition] = useState<
    {
      id?: number;
      label?: string;
      text?: string;
      conditionValue?: string;
      value1: string;
      value2: string;
      alarmType: string;
      sendOptions: string[];
      //eslint-disable-next-line
      condition: any;
    }[]
  >(
    data
      ? //eslint-disable-next-line
        data?.alarmSettings.map((alarm: any, index: number) => {
          const checkCondition = alarm?.condition?.match(REGEX.NUMBER_AND_DOT).length > 1;
          return {
            index: index,
            id: alarm?.id,
            value1: checkCondition ? alarm?.condition?.match(REGEX.NUMBER_AND_DOT)[0] : 0,
            value2: checkCondition
              ? alarm?.condition?.match(REGEX.NUMBER_AND_DOT)[1]
              : alarm?.condition?.match(REGEX.NUMBER_AND_DOT)[0],
            alarmType: alarm?.alarmType,
            sendOptions: alarm?.sendOptions,
            condition: alarm?.condition,
          };
        })
      : ConditionAlarm
  );

  // const [err, setError] = useState<boolean | undefined>(undefined);

  // eslint-disable-next-line
  const sendOpt: any = {
    notification: "Gửi thông báo",
    sms: "SMS",
  };

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  // eslint-disable-next-line
  const fetchDataOPCTag = async (query: any) => {
    try {
      const result = await OPCTagService.getAllOPCTag(query);
      if (mounted.current) {
        setOPCTag(result.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchDataOPCTag({
      page: 1,
      limit: 500,
    });
    // eslint-disable-next-line
  }, []);

  const createAlarm = (data: AlarmInputModal) => {
    AlarmService.createAlarm(data)
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          pushNoti(AppNotifications.SUCCESS, "Tạo cảnh báo thành công!");
          closeForm();
        } else {
          if (res?.data.message === ErrorMessage.OPC_TAG_NOT_FOUND) {
            pushNoti(AppNotifications.ERROR, "Không tìm thấy OPC tag");
          } else if (res?.data.message === ErrorMessage.ALARM_CODE_ALREADY_IN_USE) {
            pushNoti(AppNotifications.ERROR, "Mã cảnh báo đã được sử dụng");
          } else if (res?.data.message === ErrorMessage.OPC_TAG_HAS_ASSIGNED_IN_ALARM) {
            pushNoti(AppNotifications.ERROR, "OPC Tag đã được gắn và cảnh báo này");
          } else if (
            res?.data.message ===
            ErrorMessage.CONDITION_IS_NECCESSARY_WITH_OPC_TAG_HAVE_VALUE_TYPE_IS_VALUE
          ) {
            pushNoti(AppNotifications.ERROR, "Bạn chưa nhập điều kiện");
          } else {
            pushNoti(AppNotifications.ERROR, "Tạo cảnh báo thất bại!");
          }
        }
      })
      .catch((err) => {
        pushNoti(AppNotifications.ERROR, "Tạo cảnh báo thất bại!");
        console.log(err);
      });
  };

  const updateAlarm = (data: UpdateAlarmInputModal) => {
    AlarmService.updateAlarm(data)
      .then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          pushNoti(AppNotifications.SUCCESS, "Chỉnh sửa cảnh báo thành công!");
          closeForm();
        } else {
          pushNoti(AppNotifications.ERROR, "Chỉnh sửa cảnh báo thất bại!");
        }
      })
      .catch((err) => {
        pushNoti(AppNotifications.ERROR, "Chỉnh sửa cảnh báo thất bại!");
        console.log(err);
      });
  };

  const submitForm = async (values: AlarmInputModal | UpdateAlarmInputModal) => {
    const val = {
      id: data ? data?.id : undefined,
      code: values.code,
      isActive: status,
      opcTagId: data ? undefined : OPC,
      siteId: data ? undefined : site.id,
      areaId: data ? undefined : area.id,
      alarmSettings:
        valueType !== "value"
          ? [
              {
                id: data?.alarmSettings[0]?.id,
                sendOptions: sendOptions !== "" ? sendOptions : data?.alarmSettings[0]?.sendOptions,
                alarmType: alarmType,
              },
            ]
          : condition.map((c) => {
              return {
                id: c.id,
                alarmType: c.alarmType,
                sendOptions: c.sendOptions,
                condition: data
                  ? (c.value1 ? c.value1 : "") +
                    c.condition.replace(REGEX.NUMBER_AND_DOT, "") +
                    c.value2
                  : ((c.value1 ? c.value1 : "") + c.conditionValue + c.value2).trim(),
              };
            }),
      // eslint-disable-next-line
    } as any;

    if (data && data?.id) return updateAlarm(val);

    return createAlarm(val);
  };

  const valueType = data
    ? data?.opcTag?.valueType
    : OPCTag?.find((tag: OPCTagOutPutModal) => tag.id === OPC)?.valueType;

  const onUpdateCondition = (
    index: number,
    alarmType: string,
    sendOptions: string[],
    conditionValue: string,
    value1: string,
    value2: string
  ) => {
    const data = [...condition];

    if (index !== -1) {
      // update
      data[index] = {
        ...data[index],
        value1: value1,
        value2: value2,
        alarmType: alarmType,
        sendOptions: sendOptions,
        condition: conditionValue,
      };
    } else {
      // thêm mới

      data.push({ value1, value2, alarmType, sendOptions, condition });
    }

    setCondition(data);
  };

  const conditionLabel: any = {
    ">=": "Lớn hơn hoặc bằng",
    "<=": "Bé hơn hoặc bằng",
    "<=&&<": "Trong khoảng cao",
    "<&&<=": "Trong khoảng thấp",
  };

  const converConditionToText = (condition: string) => {
    if (condition?.includes("&&")) {
      return condition
        ?.replace(/&&/gi, " X ")
        .replace(/>=/gi, " ≥ ")
        .replace(/<=/gi, " ≤ ")
        .replace(/>/gi, " > ")
        .replace(/</gi, " < ")
        .replace(REGEX.CHARACTER_ONLY, "");
    }
    return condition?.replace(/<=/gi, "X≤").replace(/>=/gi, "X≥").replace(REGEX.CHARACTER_ONLY, "");
  };

  return (
    <Dialog fullScreen open={true}>
      <div className="alarm-modal">
        <SimpleModalHeader
          title={data ? "Chỉnh sửa cảnh báo" : "Tạo mới cảnh báo"}
          onClose={closeForm}
        />
        <div className="content">
          <Form
            initialValues={data}
            onSubmit={async (values: AlarmInputModal) => {
              submitForm(values);
            }}>
            {({ handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit} id="alarm-form" className="alarm-modal-form">
                  <Grid container>
                    <Grid item xs={12} className="title title-site">
                      {`Trại ${site.name} - ${area.name}`}
                    </Grid>
                    <Grid item xs={6} className="title">
                      Thông tin chung
                    </Grid>
                    <Grid item xs={6} display="flex" justifyContent="flex-end" alignItems="center">
                      <DefaultSwitch
                        checked={status}
                        onChange={() => {
                          setStatus(!status);
                        }}
                      />
                      {status ? (
                        <span style={{ fontSize: "1.6rem", color: "#41AD49" }}>Đang hoạt động</span>
                      ) : (
                        <span style={{ fontSize: "1.6rem", color: "#ADACAC" }}>
                          Ngừng hoạt động
                        </span>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={4}>
                    <Grid item xs={6}>
                      <Field name="code" validate={FormUtil.Rule.required("Xin nhập Mã cảnh báo")}>
                        {({ input, meta, ...rest }) => {
                          return FormUIUtil.renderFormItem(
                            "Mã cảnh báo",
                            <TextField
                              {...input}
                              {...rest}
                              fullWidth
                              placeholder={"Nhập mã cảnh báo"}
                              color="success"
                              className="form-text-field"
                              inputProps={{ className: "input" }}
                              variant="outlined"
                              onChange={(e) => input.onChange(e.target.value)}
                              helperText={meta.touched ? meta.error : ""}
                              error={meta.error && meta.touched}
                            />
                          );
                        }}
                      </Field>
                      {valueType !== "value" &&
                        FormUIUtil.renderFormItem(
                          "Hình thức cảnh báo",
                          <FormControl fullWidth>
                            <Select
                              fullWidth
                              value={sendOptions}
                              onChange={(e) => {
                                setSendOptions(e.target.value as string);
                              }}
                              input={<OutlinedInput className="form-text-field" color="success" />}
                              renderValue={
                                sendOptions !== ""
                                  ? undefined
                                  : () =>
                                      data ? (
                                        data?.alarmSettings[0]?.sendOptions
                                          ?.sort()
                                          .map((so: any) => sendOpt[so])
                                          .join(" & ") || "Không gửi thông báo"
                                      ) : (
                                        <span className="form__placeholder">
                                          Chọn hình thức cảnh báo
                                        </span>
                                      )
                              }
                              variant="outlined"
                              className="form-select-field"
                              displayEmpty
                              MenuProps={{
                                className: "select-menu-list select-menu-list-border-item",
                              }}>
                              {AlarmFilterSendOptionCreate.map((r) => {
                                return (
                                  <MenuItem key={r.id} value={r.value}>
                                    {r.label}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        )}
                    </Grid>
                    <Grid item xs={6}>
                      {FormUIUtil.renderFormItem(
                        "OPC Tag",
                        <FormControl fullWidth>
                          <Select
                            fullWidth
                            value={OPC === 0 ? "" : OPC}
                            onChange={(e) => {
                              setOPC(e.target.value as number);
                            }}
                            disabled={data ? true : false}
                            input={<OutlinedInput className="form-text-field" color="success" />}
                            renderValue={
                              OPC !== 0
                                ? undefined
                                : () =>
                                    data ? (
                                      data?.opcTag?.code
                                    ) : (
                                      <span className="form__placeholder">Chọn opc tag</span>
                                    )
                            }
                            onBlur={(e) => {
                              setSubmitErrorOPCTag(OPC === 0);
                            }}
                            error={submitErrorOPCTag}
                            variant="outlined"
                            className="form-select-field"
                            displayEmpty
                            MenuProps={{
                              className: "select-menu-list select-menu-list-border-item",
                            }}>
                            {OPCTag?.length &&
                              OPCTag.map((r: OPCTagOutPutModal) => {
                                return (
                                  <MenuItem key={r.id} value={r.id}>
                                    {r.code || TEXT_HOLDER}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                          <FormHelperText>{submitErrorOPCTag && "Chọn OPC Tag"}</FormHelperText>
                        </FormControl>
                      )}

                      {valueType !== "value" &&
                        FormUIUtil.renderFormItem(
                          "Loại cảnh báo",
                          <FormControl fullWidth>
                            <Select
                              fullWidth
                              value={alarmType}
                              onChange={(e) => {
                                setAlarmType(e.target.value as string);
                              }}
                              input={<OutlinedInput className="form-text-field" color="success" />}
                              renderValue={
                                alarmType !== ""
                                  ? undefined
                                  : () => (
                                      <span className="form__placeholder">Chọn loại cảnh báo</span>
                                    )
                              }
                              variant="outlined"
                              className="form-select-field"
                              displayEmpty
                              MenuProps={{
                                className: "select-menu-list select-menu-list-border-item",
                              }}>
                              {AlarmFilterAlarmType.map((r) => {
                                return (
                                  <MenuItem key={r.id} value={r.value}>
                                    {r.label}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        )}
                    </Grid>
                  </Grid>
                  {valueType === "value" && (
                    <>
                      <div className="title">Điều kiện</div>
                      <Grid container>
                        <Grid item xs={12}>
                          <div className="condition">
                            <div className="condition-header">
                              <div className="label-condition"></div>
                              <div className="value">Giá trị</div>
                              <div className="alarm-type">Hình thức cảnh báo</div>
                              <div className="send-options">Loại cảnh báo</div>
                            </div>
                            {condition.map((cond, index: number) => {
                              const label =
                                conditionLabel[cond?.condition?.replace(REGEX.CHARACTER_ONLY, "")];
                              // eslint-disable-next-line
                              const text = converConditionToText(cond?.condition as any);
                              const conditionNumber = cond?.condition?.match(REGEX.NUMBER_AND_DOT);

                              return (
                                <div className="condition-form" key={index}>
                                  <div className="content-condition">
                                    <Grid container>
                                      <Grid item xs={3}>
                                        <div className="label-condition">
                                          &#9679;&ensp;{data ? label : cond.label}
                                        </div>
                                      </Grid>
                                      <Grid item xs={3}>
                                        <div
                                          className={
                                            // err ? "cond cond-err" :
                                            "cond"
                                          }>
                                          {(
                                            data
                                              ? conditionNumber.length > 1
                                              : cond.id === 1 || cond.id === 2
                                          ) ? (
                                            <input
                                              onChange={(e) => {
                                                onUpdateCondition(
                                                  index,
                                                  cond.alarmType,
                                                  cond.sendOptions,
                                                  cond?.condition,
                                                  e.target.value,
                                                  cond.value2
                                                );
                                              }}
                                              defaultValue={data ? conditionNumber[0] : ""}
                                              type="number"
                                              step="0.01"
                                              autoComplete="off"
                                              placeholder="00"
                                              className="cond-input"
                                            />
                                          ) : (
                                            <input disabled className="cond-input" />
                                          )}
                                          <span className="cond-text">
                                            {data ? text : cond.text}
                                          </span>
                                          <input
                                            onChange={(e) => {
                                              onUpdateCondition(
                                                index,
                                                cond.alarmType,
                                                cond.sendOptions,
                                                cond?.condition,
                                                cond.value1,
                                                e.target.value
                                              );
                                            }}
                                            defaultValue={
                                              data
                                                ? conditionNumber.length > 1
                                                  ? conditionNumber[1]
                                                  : conditionNumber[0]
                                                : ""
                                            }
                                            type="number"
                                            step="0.01"
                                            autoComplete="off"
                                            placeholder="00"
                                            className="cond-input"
                                          />
                                        </div>
                                        {/* {err && (
                                          <div
                                            style={{
                                              color: "#E53935",
                                              fontSize: "1.2rem",
                                              marginTop: "0.5rem",
                                            }}>
                                            Giá trị không phù hợp!
                                          </div>
                                        )} */}
                                      </Grid>
                                      <Grid item xs={3}>
                                        <FormControl fullWidth>
                                          <Select
                                            fullWidth
                                            value={cond.sendOptions || [""]}
                                            onChange={(e) => {
                                              onUpdateCondition(
                                                index,
                                                cond.alarmType,
                                                e.target.value as string[],
                                                cond?.condition,
                                                cond.value1,
                                                cond.value2
                                              );
                                            }}
                                            input={
                                              <OutlinedInput
                                                className="form-text-field"
                                                color="success"
                                              />
                                            }
                                            renderValue={
                                              cond.sendOptions
                                                ? data
                                                  ? () => (
                                                      <>
                                                        {cond.sendOptions
                                                          ?.sort()
                                                          .map((so: any) => sendOpt[so])
                                                          .join(" & ") || "Không gửi thông báo"}
                                                      </>
                                                    )
                                                  : undefined
                                                : () => (
                                                    <span className="form__placeholder">
                                                      Chọn hình thức cảnh báo
                                                    </span>
                                                  )
                                            }
                                            variant="outlined"
                                            className="form-select-field"
                                            displayEmpty
                                            MenuProps={{
                                              className:
                                                "select-menu-list select-menu-list-border-item",
                                            }}>
                                            {AlarmFilterSendOptionCreate.map((r) => {
                                              return (
                                                <MenuItem key={r.id} value={r.value}>
                                                  {r.label}
                                                </MenuItem>
                                              );
                                            })}
                                          </Select>
                                        </FormControl>
                                      </Grid>
                                      <Grid item xs={3}>
                                        <FormControl fullWidth>
                                          <Select
                                            fullWidth
                                            value={cond.alarmType || ""}
                                            onChange={(e) => {
                                              onUpdateCondition(
                                                index,
                                                e.target.value as string,
                                                cond.sendOptions,
                                                cond?.condition,
                                                cond.value1,
                                                cond.value2
                                              );
                                            }}
                                            input={
                                              <OutlinedInput
                                                className="form-text-field"
                                                color="success"
                                              />
                                            }
                                            renderValue={
                                              cond.alarmType
                                                ? undefined
                                                : () => (
                                                    <span className="form__placeholder">
                                                      Chọn loại cảnh báo
                                                    </span>
                                                  )
                                            }
                                            variant="outlined"
                                            className="form-select-field"
                                            displayEmpty
                                            MenuProps={{
                                              className:
                                                "select-menu-list select-menu-list-border-item",
                                            }}>
                                            {AlarmFilterAlarmType.map((r) => {
                                              return (
                                                <MenuItem key={r.id} value={r.value}>
                                                  {r.label}
                                                </MenuItem>
                                              );
                                            })}
                                          </Select>
                                        </FormControl>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </form>
              );
            }}
          </Form>
        </div>
        <ModalFooter
          formId="alarm-form"
          showDeleteButton={false}
          handleDeleteAction={() => {
            // toggleDeleteModal();
          }}
          handleCancelAction={closeForm}
          title={data ? "Chỉnh sửa" : "Tạo mới "}
          handleSubmit={() => {
            !data && setSubmitErrorOPCTag(OPC === 0);
          }}
        />
      </div>
    </Dialog>
  );
};

export default AlarmModal;
