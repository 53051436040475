import { createTheme } from "@mui/material/styles";
import { AccountStatus } from "../models";

export const StatusUIUtil = { renderStatusAccount, stringAvatarSite };

function renderStatusAccount(value: string) {
  switch (value) {
    case AccountStatus.ACTIVE:
      return (
        <span className="status" style={{ color: "#1AB759" }}>
          &#9679;&ensp;Kích hoạt
        </span>
      );
    case AccountStatus.INACTIVE:
      return (
        <span className="status" style={{ color: "#E93C3C" }}>
          &#9679;&ensp;Vô hiệu hoá
        </span>
      );
    case AccountStatus.PENDING:
      return (
        <span className="status" style={{ color: "#838283" }}>
          &#9679;&ensp;Chờ phê duyệt
        </span>
      );
    default:
      return (
        <span className="status" style={{ color: "#B8292F" }}>
          &#9679;&ensp;Đã hủy
        </span>
      );
  }
}

// style ava site
function stringAvatarSite(name: string) {
  return {
    sx: {
      bgcolor: "#41AD49",
      fontSize: "1.6rem",
      fontWeight: "bold",
    },
    children: name.split(" ")?.[1]?.[0]
      ? `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`.toUpperCase()
      : `${name.split(" ")[0][0]}`.toUpperCase(),
  };
}

export const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "1.3rem",
          lineHeight: "1.9rem",
          color: " #FFFFFF",
          backgroundColor: "#312F30",
          fontFamily: "SVN-Gotham",
          fontWeight: 400,
          boxShadow: "0rem 0.4rem 1.2rem rgba(111, 125, 145, 0.16)",
          borderRadius: "0.4rem",
        },
      },
    },
  },
});
