import React from "react";
import { IconBase, IconComponent } from "../../core/Icons";

export const AreaIcon: React.FC<IconComponent> = (props) => {
  return (
    <IconBase {...props}>
      <path
        d="M5.88889 9.77778H8.66667C9.28032 9.77778 9.77778 9.28032 9.77778 8.66667V3.11111C9.77778 2.49746 9.28032 2 8.66667 2H3.11111C2.49746 2 2 2.49746 2 3.11111V8.66667C2 9.28032 2.49746 9.77778 3.11111 9.77778H5.88889ZM5.88889 9.77778V14.5"
        stroke={props.color}
        strokeWidth="2.1"
        strokeLinejoin="round"
      />
      <path
        d="M9.77778 18.1111V15.3333C9.77778 14.7196 9.28032 14.2222 8.66667 14.2222H3.11111C2.49746 14.2222 2 14.7196 2 15.3333V20.8888C2 21.5025 2.49746 21.9999 3.11111 21.9999H8.66667C9.28032 21.9999 9.77778 21.5025 9.77778 20.8888V18.1111ZM9.77778 18.1111H14"
        stroke={props.color}
        strokeWidth="2.1"
        strokeLinejoin="round"
      />
      <path
        d="M18.1111 9.77778C18.6218 9.77778 19.1274 9.67719 19.5993 9.48175C20.0711 9.28632 20.4998 8.99986 20.8609 8.63875C21.222 8.27763 21.5085 7.84892 21.7039 7.3771C21.8994 6.90528 21.9999 6.39958 21.9999 5.88889C21.9999 5.37819 21.8994 4.8725 21.7039 4.40068C21.5085 3.92885 21.222 3.50015 20.8609 3.13903C20.4998 2.77791 20.0711 2.49146 19.5993 2.29602C19.1274 2.10059 18.6218 2 18.1111 2C17.0797 2 16.0905 2.40972 15.3612 3.13903C14.6319 3.86834 14.2222 4.85749 14.2222 5.88889C14.2222 6.92029 14.6319 7.90944 15.3612 8.63875C16.0905 9.36806 17.0797 9.77778 18.1111 9.77778Z"
        stroke={props.color}
        strokeWidth="2.1"
        strokeLinejoin="round"
      />
      <path
        d="M18.1111 14.2222H15.3333C14.7196 14.2222 14.2222 14.7197 14.2222 15.3333V20.8889C14.2222 21.5025 14.7196 22 15.3333 22H20.8888C21.5025 22 21.9999 21.5025 21.9999 20.8889V15.3333C21.9999 14.7197 21.5025 14.2222 20.8888 14.2222H18.1111ZM18.1111 14.2222V9.5"
        stroke={props.color}
        strokeWidth="2.1"
        strokeLinejoin="round"
      />
    </IconBase>
  );
};
