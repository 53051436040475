/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import { WithChildren } from "../../../models/shared";
import { loadState } from "../../../utils";
import { APP_ROUTES } from "../../constants/module";

const PrivateRoute = ({ children }: WithChildren<any>) => {
  const token = loadState();

  return <Route exact render={() => (token ? children : <Redirect to={APP_ROUTES.LOGIN} />)} />;
};

export default React.memo(PrivateRoute);
