export const REQUEST_URL = {
  //authen
  LOGIN: "/auth/login",
  LOG_OUT: "/auth/logout",
  RESET_PASSWORD: "/account/reset-password",
  CHANGE_PASSWORD: "/account/change-password",
  FORGOT_PASSWORD: "account/forgot-password",
  VERIFY_FORGOT_PASSWORD: "account/verify-forgot-password",
  UPDATE_PASSWORD: "account/update-password",
  DESTROY_TOKEN: "/auth/destroy-all-token",

  //account
  ACCOUNT_LIST: "/account",
  ROLE: "/role",
  ACCOUNT_ACTIVATE: "/account/activate",
  ACCOUNT_ACTIVATE_NOTIFY: "/account/activate-notify",
  DELETE_ACCOUNT: "/account/{id}",
  RECOVER_ACCOUNT: "/account/recover",

  //site
  SITE: "/site",
  DELETE_SITE: "/site/{id}",
  TYPE: "/animal-type",
  SET_SITE_POSITION: "site/set-position",

  //area
  AREA: "/area",
  AREA_DETAIL: "/area/detail",
  AREA_WITHOUT_SITE: "/area/without-site",
  AREA_TYPE: "/area/area-type",
  SET_AREA_POSITION: "area/set-position",
  DELETE_AREA: "/area/{id}",

  //profile
  AUTH_PROFILE: "/auth/profile",

  //device
  DEVICE: "/device",
  DELETE_DEVICE: "/device/{id}",
  TYPE_DEVICE: "/device/device-type",
  DEVICE_WITHOUT_AREA: "/device/without-area",
  SET_TIME: "/report/set-time",
  GET_TIME: "/report/get-time",

  //alarm
  ALARM: "/alarm",
  DELETE_ALARM: "/alarm/{id}",

  //opc-tag
  OPC_TAG: "opc-tag",

  //statistic
  RECEIVE_STATISTIC: "/account/enable-receive-statistic",

  //report
  REPORT_TYPE: "/report/type",
  REPORT_IMPORT_TEMPLATE: "/report/import-template",
  REPORT_SITE_TEMPLATES: "/site/templates",
  REPORT_SEND_MAIL: "/report/send-mail",
  REPORT_CHECK_SAMPLE: "/report/check-sample",
} as const;
