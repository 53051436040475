/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Clear as ClearIcon,
  Search as SearchIcon,
  TuneRounded as TuneRoundedIcon,
} from "@mui/icons-material";
import {
  Checkbox,
  Dialog,
  Divider,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  Popover,
  TextField,
} from "@mui/material";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useHistory, useLocation } from "react-router-dom";
import Avatar from "../../../assets/images/avt.png";
import Search from "../../../assets/images/img-search.png";
import Ord from "../../../assets/images/ordnull.png";
import { withProfile } from "../../../hoc/withProfile";
import { AccountDetailOutputModel, AccountOutputModel } from "../../../models";
import { Nullable } from "../../../models/shared";
import { AccountService } from "../../../services";
import { StatusUIUtil } from "../../../utils";
import { autoLoadingPagination, checkLinkImage, renderErrorImage } from "../../../utils/other.util";
import EmptyList from "../../components/EmptyList";
import FloatingBtn from "../../components/FloatingBtn";
import Loading from "../../components/Loading";
import { NavHeaderAccount } from "../../constants/mock";
import { APP_ROUTES } from "../../constants/module";
import { TEXT_HOLDER } from "../../constants/utilities";
import AccountModal from "./AccountModal";
import "./index.scss";
import { useFetchWithoutPagination } from "../../../hooks/useFetchWithoutPagination";

const Account: React.FC = () => {
  const history = useHistory();
  // eslint-disable-next-line
  const location = useLocation<any>();
  const mounted = useRef(false);
  const [selectTab, setSelectTab] = useState<string | undefined>(undefined);
  const [anchorFilterEl, setAnchorFilterEl] = useState<HTMLElement | null>(null);
  const [checkedFilter, setCheckedFilter] = useState<number[]>([]);
  const [roleFilter, setRoleFilter] = useState<number[]>([]);
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const [visibleSearch, setVisibleSearch] = useState<boolean>(false);
  // eslint-disable-next-line
  const [dataAccount, setDataAccount] = useState<any>();
  const [openAccountModal, setOpenAccountModal] = useState<number>(0);
  const [accountData, setAccountData] = useState<Nullable<AccountDetailOutputModel>>(null);
  const { data: RoleList } = useFetchWithoutPagination(AccountService.getRoleList);

  const loadData = async (page: number, limit: number) => {
    // eslint-disable-next-line
    const resultAccount: any = await autoLoadingPagination(
      AccountService.getAccount,
      {
        filter: {
          searchString: searchValue,
          status: selectTab,
          roleIds: roleFilter.length > 0 ? new Array(roleFilter) : undefined,
        },
      },
      page,
      limit,
      dataAccount && dataAccount?.returnData
    );

    if (resultAccount?.returnData?.length) setDataAccount(resultAccount);
    if (resultAccount?.returnData?.length === 0) setDataAccount({ ...resultAccount });
  };

  const hasMore =
    dataAccount?.payload?.data?.length < dataAccount?.payload?.totalRecords &&
    dataAccount?.payload?.page < dataAccount?.payload?.totalPages;

  useEffect(() => {
    loadData(1, 10);
    // eslint-disable-next-line
  }, [selectTab, roleFilter, searchValue, location]);

  useEffect(() => {
    location.state?.status !== undefined && setSelectTab(location.state?.status);
  }, [location]);

  const onSearch = _.debounce((value) => {
    setSearchValue(value);

    value ? setVisibleSearch(true) : setVisibleSearch(false);
  }, 300);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const fetchData: () => Promise<void> = async () => {
    try {
      const result = await AccountService.getAccountDetailById(openAccountModal);
      if (mounted.current) {
        setAccountData(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openAccountModal]);

  return (
    <div className="account">
      <div className="account-content">
        <div className="action">
          <div className="account__nav-header">
            {NavHeaderAccount.map((o, idx: number) => {
              return (
                <div
                  key={o.id + idx}
                  className={o.value === selectTab ? "tab tab-active btn--shiny" : "tab tab-hover"}
                  onClick={() => {
                    setSelectTab(o.value);
                  }}>
                  {o.label}
                  <span>{dataAccount?.payload.count[`${o.title}`]}</span>
                </div>
              );
            })}
          </div>
          <div style={{ display: "flex" }}>
            <TextField
              placeholder="Tìm theo tên, mã nhân viên, vai trò...."
              variant="outlined"
              className={"search-style"}
              inputProps={{ className: "input" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="large" />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                onSearch(e.target.value);
              }}
            />
            <button
              className="btn-white"
              onClick={(event: React.MouseEvent<HTMLElement>) =>
                setAnchorFilterEl(event.currentTarget)
              }>
              <TuneRoundedIcon style={{ fontSize: "2.2rem", marginRight: "1rem" }} />
              Chọn lọc
            </button>
          </div>
        </div>

        <div className="list">
          <div className="list-header">
            <div className="id">Mã nhân viên</div>
            <div className="act">Tên tài khoản</div>
            <div className="email">Email</div>
            <div className="phone">Số điện thoại</div>
            <div className="role">Vai trò</div>
            <div className="status">Trạng thái</div>
          </div>

          {dataAccount?.loading ? (
            <Loading style={{ margin: "2rem 0", height: "60vh" }} />
          ) : dataAccount?.returnData ? (
            dataAccount?.returnData?.length > 0 ? (
              <InfiniteScroll
                useWindow={false}
                pageStart={0}
                initialLoad={false}
                hasMore={hasMore}
                loadMore={() => loadData(dataAccount.payload.page + 1, 10)}>
                {dataAccount.fetching && searchValue && <Loading />}
                {dataAccount?.returnData?.map((account: AccountOutputModel, index: number) => {
                  return (
                    <div
                      key={index}
                      className="item-list-page"
                      onClick={() => {
                        account.role
                          ? history.push({
                              pathname: APP_ROUTES.ACCOUNT_DETAIL.replace(
                                ":accountId",
                                account.id.toString()
                              ),
                              state: {
                                status: selectTab,
                              },
                            })
                          : setOpenAccountModal(account.id);
                      }}>
                      <div className="id">{account.code || TEXT_HOLDER}</div>
                      <div className="act">
                        <img
                          src={checkLinkImage(account?.avatar as string) || Avatar}
                          alt="avt"
                          onError={renderErrorImage("avatar")}
                        />
                        {account?.name || TEXT_HOLDER}
                      </div>
                      <div className="email">{account.email || TEXT_HOLDER}</div>
                      <div className="phone">{account.phoneNumber || TEXT_HOLDER}</div>
                      <div className="role">{account.role?.name || TEXT_HOLDER}</div>
                      <div className="status">
                        {StatusUIUtil.renderStatusAccount(account?.status)}
                      </div>
                    </div>
                  );
                })}
              </InfiniteScroll>
            ) : visibleSearch === true ? (
              <EmptyList img={Search} title="Không tìm thấy kết quả phù hợp" />
            ) : (
              <EmptyList img={Ord} title="Chưa có dữ liệu" />
            )
          ) : (
            <EmptyList img={Ord} title="Chưa có dữ liệu" />
          )}
        </div>
      </div>

      <FloatingBtn
        visible={false}
        onClickPrimary={() => {
          history.push(APP_ROUTES.ACCOUNT_CREATE);
        }}
        btns={[]}
      />

      <Popover
        open={Boolean(anchorFilterEl)}
        anchorEl={anchorFilterEl}
        onClose={() => {
          setAnchorFilterEl(null);
          setCheckedFilter(roleFilter);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}>
        <div className="filter-style">
          <div className="title">Chọn lọc</div>
          <div
            className="close-icon"
            onClick={() => {
              setAnchorFilterEl(null);
              setCheckedFilter(roleFilter);
            }}>
            <ClearIcon style={{ fontSize: "2.2rem", cursor: "pointer" }} />
          </div>
          <div className="lable">Vai trò</div>
          <FormControlLabel
            control={
              <Checkbox
                checked={checkedFilter.length === RoleList?.length ? true : false}
                value={checkedFilter}
                onChange={() => {
                  checkedFilter.length > 0
                    ? setCheckedFilter([])
                    : setCheckedFilter(
                        RoleList?.map((r: any) => {
                          return r.id;
                        })
                      );
                }}
                sx={{ "& .MuiSvgIcon-root": { fontSize: 28, color: "#41AD49" } }}
              />
            }
            label="Tất cả"
          />
          <Divider style={{ margin: "1rem 0" }} />
          <FormGroup>
            {RoleList?.map((r: any) => {
              return (
                <FormControlLabel
                  key={r.id}
                  control={
                    <Checkbox
                      checked={checkedFilter.some((e) => e === r.id)}
                      value={r.id}
                      onChange={() => {
                        checkedFilter.some((e) => e === r.id)
                          ? setCheckedFilter(checkedFilter.filter((e) => e !== r.id))
                          : setCheckedFilter([...checkedFilter, r.id]);
                      }}
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 28, color: "#41AD49" } }}
                    />
                  }
                  label={r.name}
                />
              );
            })}
          </FormGroup>
          <div style={{ display: "flex", justifyContent: "space-between", marginTop: "2rem" }}>
            <button
              className="btn-cancel-style"
              onClick={() => {
                setCheckedFilter([]);
                setRoleFilter([]);
                setAnchorFilterEl(null);
              }}>
              Xóa lọc
            </button>
            <button
              className="btn-confirm-style"
              style={{ width: "18.1rem" }}
              onClick={() => {
                setRoleFilter(checkedFilter);
                setAnchorFilterEl(null);
              }}>
              Áp dụng
            </button>
          </div>
        </div>
      </Popover>

      <Dialog fullScreen open={openAccountModal !== 0}>
        <AccountModal closeForm={() => setOpenAccountModal(0)} data={accountData} pending={true} />
      </Dialog>
    </div>
  );
};

export default withProfile(Account);
