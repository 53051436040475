import { Avatar, Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFetchWithoutPagination } from "../../../../hooks/useFetchWithoutPagination";
import { ReportService } from "../../../../services";
import { StatusUIUtil } from "../../../../utils";
import "./index.scss";

type Props = {
  onChangeTab: (tab: string) => void;
};

const LeftReportFilter: React.FC<Props> = ({ onChangeTab }) => {
  const { data: typeData } = useFetchWithoutPagination(ReportService.getType);
  const [tab, setTab] = useState<string>("alarm");

  useEffect(() => {
    tab && onChangeTab(tab);
    // eslint-disable-next-line
  }, [tab]);

  return (
    <div className="left-report-filter">
      <div className="title">Báo cáo</div>
      <Divider style={{ width: "100%", marginBottom: "0.8rem" }} />
      {typeData && (
        <div className="left-report-filter-body">
          <div
            className={tab === "alarm" ? "active item" : "item"}
            onClick={() => {
              setTab("alarm");
            }}>
            <Avatar alt={(1).toString() as string} src="">
              <Avatar {...StatusUIUtil.stringAvatarSite((1).toString() as string)} />
            </Avatar>
            <div className="name">Báo cáo thống kê lỗi theo tháng</div>
          </div>
          <div
            className={tab === "ambient" ? "active item" : "item"}
            onClick={() => {
              setTab("ambient");
            }}>
            <Avatar alt={(2).toString() as string} src="">
              <Avatar {...StatusUIUtil.stringAvatarSite((2).toString() as string)} />
            </Avatar>
            <div className="name">Báo cáo thống kê dữ liệu môi trường theo tháng</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LeftReportFilter;
