import {
  CheckRounded as CheckRoundedIcon,
  Clear as ClearIcon,
  ClearRounded as ClearRoundedIcon,
  VisibilityOffOutlined as VisibilityOffOutlinedIcon,
  VisibilityOutlined as VisibilityOutlinedIcon,
} from "@mui/icons-material";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import useNoti from "../../../../hooks/useNoti";
import { AppNotifications } from "../../../../models";
import { AuthService } from "../../../../services";
import { FormUtil, loadState, RequestUtil } from "../../../../utils";
import { FormUIUtil } from "../../../../utils/formUI.util";
import NotificationModal from "../../../components/NotificationModal";
import { REGEX } from "../../../constants";
import { APP_ROUTES } from "../../../constants/module";
import "./index.scss";

type P_Props = {
  onClose: () => void;
};

const ChangePassword: React.FC<P_Props> = ({ onClose }) => {
  const [pushNoti] = useNoti();
  const [checkPassword, setCheckPassword] = useState<boolean>(false);
  const [checkCurrentPw, setCheckCurrentPw] = useState<boolean>(false);
  const [showPw, setShowPw] = useState<boolean>(false);
  const [showNewPw, setShowNewPw] = useState<boolean>(false);
  const [showConfirmPw, setShowConfirmPw] = useState<boolean>(false);
  const [value, setValue] = useState<string>("");
  const [notiLogOut, setNotiLogOut] = useState<boolean>(false);
  const token = loadState();

  const destroyToken = async () => {
    await AuthService.destroyToken({
      authorization: `Bearer ${token}`,
    });
  };

  const onLogout = () => {
    if (token) {
      destroyToken();
      localStorage.removeItem("accessToken");
      RequestUtil.removeAuthHeader();
    }

    window.location.href = `${APP_ROUTES.LOGIN}`;
  };

  // eslint-disable-next-line
  const submitForm = React.useCallback(async (values: any) => {
    try {
      await AuthService.changePassword({
        password: values.password,
        oldPassword: values.oldPassword,
      })
        .then((res) => {
          if (res && (res.status === 200 || res.status === 201)) {
            setNotiLogOut(true);
          } else if (res.status === 400) {
            if (res.data.message === "THE_NEW_PASSWORD_IS_THE_SAME_AS_THE_OLD_PASSWORD") {
              setCheckPassword(true);
            } else if (res.data.message === "WRONG_OLD_PASSWORD") {
              setCheckCurrentPw(true);
            }
          } else pushNoti(AppNotifications.ERROR, "Đổi mật khẩu thất bại");
        })
        .catch((err) => {
          pushNoti(AppNotifications.ERROR, "Đổi mật khẩu thất bại");
        });
    } catch (err) {
      pushNoti(AppNotifications.ERROR, "Đổi mật khẩu thất bại");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="cus-reset">
      <ClearIcon
        style={{
          fontSize: "2.5rem",
          cursor: "pointer",
          position: "absolute",
          top: "3rem",
          right: "3rem",
          color: "#272B2F",
        }}
        onClick={() => onClose()}
      />
      <div className="title">Đổi mật khẩu</div>
      <div className="des">
        Vui lòng nhập những thông tin dưới đây để có thể thực hiện đổi mật khẩu
      </div>
      <Form
        validate={(values) => {
          const errors: Record<string, unknown> = {};
          if (values.confirmPassword !== values.password) {
            errors.confirmPassword = "Mật khẩu không trùng khớp";
          } else if (values.password?.length <= 5) {
            errors.password = "Mật khẩu phải nhiều hơn 5 ký tự";
          } else if (values.oldPassword?.length <= 5) {
            errors.oldPassword = "Mật khẩu cũ phải nhiều hơn 5 ký tự";
          } else if (checkPassword === true) {
            errors.password = "Mật khẩu không trùng mật khẩu cũ";
          } else if (checkCurrentPw === true) {
            errors.oldPassword = "Mật khẩu cũ không chính xác";
          }
          return errors;
        }}
        onSubmit={async (values) => submitForm(values)}>
        {({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit} id="reset-pw">
              <Field
                name="oldPassword"
                validate={FormUtil.Rule.required("Bạn chưa nhập mật khẩu cũ")}>
                {({ input, meta, ...rest }) => {
                  return FormUIUtil.renderFormItem(
                    "Mật khẩu cũ",
                    <TextField
                      {...input}
                      {...rest}
                      color="success"
                      placeholder={"Mật khẩu cũ"}
                      className={"form-text-field"}
                      inputProps={{ className: "input" }}
                      InputProps={{
                        type: showPw ? "text" : "password",
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setShowPw((show) => !show)}>
                              {showPw ? (
                                <VisibilityOutlinedIcon
                                  style={{ fontSize: "2rem", color: "#000000" }}
                                />
                              ) : (
                                <VisibilityOffOutlinedIcon
                                  style={{ fontSize: "2rem", color: "#000000" }}
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) => {
                        input.onChange(e.target.value);
                        setCheckCurrentPw(false);
                      }}
                      helperText={meta.touched ? meta.error || meta.submitError : ""}
                      error={(meta.error || meta.submitError) && meta.touched}
                    />
                  );
                }}
              </Field>
              <Field
                name="password"
                validate={FormUtil.Rule.required("Bạn chưa nhập mật khẩu mới")}>
                {({ input, meta, ...rest }) => {
                  return FormUIUtil.renderFormItem(
                    "Mật khẩu mới",
                    <TextField
                      {...input}
                      {...rest}
                      color="success"
                      placeholder={"Mật khẩu mới"}
                      className={"form-text-field"}
                      inputProps={{ className: "input" }}
                      InputProps={{
                        type: showNewPw ? "text" : "password",
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setShowNewPw((show) => !show)}>
                              {showNewPw ? (
                                <VisibilityOutlinedIcon
                                  style={{ fontSize: "2rem", color: "#000000" }}
                                />
                              ) : (
                                <VisibilityOffOutlinedIcon
                                  style={{ fontSize: "2rem", color: "#000000" }}
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) => {
                        input.onChange(e.target.value);
                        setValue(e.target.value);
                        setCheckPassword(false);
                      }}
                      helperText={meta.touched ? meta.error : ""}
                      error={meta.error && meta.touched}
                    />
                  );
                }}
              </Field>
              <Field name="confirmPassword">
                {({ input, meta, ...rest }) => {
                  return FormUIUtil.renderFormItem(
                    "Nhập lại mật khẩu mới",
                    <TextField
                      {...input}
                      {...rest}
                      color="success"
                      placeholder={"Nhập lại mật khẩu"}
                      className={"form-text-field"}
                      inputProps={{ className: "input" }}
                      InputProps={{
                        type: showConfirmPw ? "text" : "password",
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setShowConfirmPw((show) => !show)}>
                              {showConfirmPw ? (
                                <VisibilityOutlinedIcon
                                  style={{ fontSize: "2rem", color: "#000000" }}
                                />
                              ) : (
                                <VisibilityOffOutlinedIcon
                                  style={{ fontSize: "2rem", color: "#000000" }}
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      helperText={meta.touched ? meta.error : ""}
                      error={meta.error && meta.touched}
                    />
                  );
                }}
              </Field>
              <div className="cus-reset-validate">
                <div className="validate-title">Yêu cầu mật khẩu có :</div>
                <div className="validate-content">
                  <div className="item">
                    <div
                      className="ic"
                      style={{
                        background:
                          value !== ""
                            ? REGEX.CHARACTER_LENGTH.test(value) === true
                              ? "#2ac769"
                              : "#e93c3c"
                            : "rgba(42, 199, 105, 0.24)",
                      }}>
                      <CheckRoundedIcon style={{ fontSize: "0.8rem", color: "#fff" }} />
                    </div>
                    <div>&ensp;Tối thiểu 8 ký tự</div>
                  </div>
                  <div className="item">
                    <div
                      className="ic"
                      style={{
                        background:
                          value !== ""
                            ? REGEX.UPPERCASE.test(value) === true
                              ? "#2ac769"
                              : "#e93c3c"
                            : "rgba(42, 199, 105, 0.24)",
                      }}>
                      <CheckRoundedIcon style={{ fontSize: "0.8rem", color: "#fff" }} />
                    </div>
                    <div>&ensp;Ít nhất 1 ký tự viết in hoa</div>
                  </div>
                  <div className="item">
                    <div
                      className="ic"
                      style={{
                        background:
                          value !== ""
                            ? REGEX.DIGITS.test(value) === true
                              ? "#2ac769"
                              : "#e93c3c"
                            : "rgba(42, 199, 105, 0.24)",
                      }}>
                      <CheckRoundedIcon style={{ fontSize: "0.8rem", color: "#fff" }} />
                    </div>
                    <div>&ensp;Ít nhất 1 chữ số</div>
                  </div>
                  <div className="item">
                    <div
                      className="ic"
                      style={{
                        background:
                          value !== ""
                            ? REGEX.SPECIAL_CHARACTER.test(value) === true
                              ? "#2ac769"
                              : "#e93c3c"
                            : "rgba(42, 199, 105, 0.24)",
                      }}>
                      <CheckRoundedIcon style={{ fontSize: "0.8rem", color: "#fff" }} />
                    </div>
                    <div>&ensp;Ít nhất 1 ký tự đặc biệt như @#$%^& ...</div>
                  </div>
                </div>
              </div>
              <button className="btn-confirm-style " type="submit">
                Đặt lại mật khẩu
              </button>
            </form>
          );
        }}
      </Form>

      <NotificationModal
        visible={notiLogOut}
        icon={
          <div className={`notification-icon-box ${notiLogOut ? "green" : "red"}`}>
            {notiLogOut ? (
              <CheckRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
            ) : (
              <ClearRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
            )}
          </div>
        }
        title={notiLogOut ? "Đổi mật khẩu thành công!" : "Đổi mật khẩu thất bại!"}
        subTitle={
          notiLogOut ? (
            <span>
              <br />
              Mật khẩu mới cập nhật thành công
              <br />
              Quý khách có muốn đăng xuất khỏi các thiết bị khác
            </span>
          ) : (
            <span>Vui lòng đặt lại mật khẩu</span>
          )
        }
        actions={
          <>
            <button
              className="btn-confirm-style"
              style={{
                background: "#fff",
                color: "#0A6836",
                border: "0.1rem solid #0A6836",
                width: "12.6rem",
                marginRight: "2rem",
                borderRadius: "4rem",
              }}
              onClick={() => {
                setNotiLogOut(false);
                onClose();
              }}>
              Không
            </button>
            <button
              className="btn-confirm-style"
              style={{
                borderRadius: "4rem",
              }}
              onClick={() => {
                notiLogOut ? onLogout() : setNotiLogOut(false);
              }}>
              {notiLogOut ? " Đồng ý" : "Thử lại"}
            </button>
          </>
        }
        onClose={() => {
          setNotiLogOut(false);
          onClose();
        }}
      />
    </div>
  );
};

export default ChangePassword;
