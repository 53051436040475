import { REQUEST_URL } from "../app/constants";
import { AreaInputModel, PaginationQueryModel, SetAreaPosition } from "../models";
import { RequestUtil } from "../utils";

export const AreaService = {
  getAllArea: (
    query: PaginationQueryModel & {
      areaTypeIds?: number[];
      siteId?: number;
      searchString?: string;
      isAdmin: boolean;
    }
  ) => {
    const url = REQUEST_URL.AREA;

    return RequestUtil.get({ url, query: query });
  },

  getAreaWithoutSite: (
    query: PaginationQueryModel & {
      searchString?: string;
      id?: number;
    }
  ) => {
    const url = REQUEST_URL.AREA_WITHOUT_SITE;

    return RequestUtil.get({ url, query: query });
  },

  getAreaType: () => {
    const url = REQUEST_URL.AREA_TYPE;
    return RequestUtil.get({ url });
  },

  createArea: (data: AreaInputModel) => {
    const url = REQUEST_URL.AREA;

    return RequestUtil.post({ url, data: data });
  },

  updateArea: (data: AreaInputModel) => {
    const url = REQUEST_URL.AREA;

    return RequestUtil.put({ url, data: data });
  },

  getAreaDetailById: (areaId: string) => {
    const url = `${REQUEST_URL.AREA_DETAIL}/${areaId}`;
    return RequestUtil.get({ url });
  },

  setAreaPosition: (data: SetAreaPosition) => {
    const url = REQUEST_URL.SET_AREA_POSITION;
    return RequestUtil.post({ url, data });
  },

  deleteArea: (id: number) => {
    const url = REQUEST_URL.DELETE_AREA.replace("{id}", id.toString());

    return RequestUtil.delete({ url, query: id });
  },
};
