export const APP_ROUTES = {
  HOME: "/",

  LOGIN: "/sign-in",
  FORGET_PASSWORD: "/forget-password",
  RESET_PASSWORD: "/reset-password",
  OTP: "/otp",

  PROFILE: "/profile",
  PROFILE_REPORT: "/profile-report",

  ACCOUNT: "/account",
  ACCOUNT_DETAIL: "/account-detail/:accountId",
  ACCOUNT_CREATE: "/account-create",

  SITE: "/site",
  SITE_CREATE: "/site-create",
  SITE_DETAIL: "/site-detail/:siteId",

  AREA: "/area",
  AREA_CREATE: "/area-create",
  AREA_DETAIL: "/area-detail/:areaId",

  DEVICE: "/device",
  DEVICE_CREATE: "/device-create",

  ALARM: "/alarm",
  ALARM_CREATE: "/alarm-create",

  REPORT: "/report",
} as const;
