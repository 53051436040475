/* eslint-disable */
import { useState, useEffect } from "react";

export type TApiResponse = {
  data: any;
  error: any;
  loading: boolean;
};

export const useFetchWithoutPagination = (actionParams: any): TApiResponse => {
  const [data, setData] = useState<any>();
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await actionParams();
      setData(response.data.data || response.data);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { data, error, loading };
};
