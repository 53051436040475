/* eslint-disable */
import {
  Clear as ClearIcon,
  MoreHoriz as MoreHorizIcon,
  Search as SearchIcon,
  TuneRounded as TuneRoundedIcon,
} from "@mui/icons-material";
import {
  Checkbox,
  Dialog,
  Divider,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  Popover,
  TextField,
  Tooltip,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import Search from "../../../assets/images/img-search.png";
import Ord from "../../../assets/images/ordnull.png";
import { withProfile } from "../../../hoc/withProfile";
import useNoti from "../../../hooks/useNoti";
import { AppNotifications } from "../../../models";
import { AlarmOutputModal } from "../../../models/alarm.model";
import { AlarmService } from "../../../services";
import { autoLoadingPagination, theme } from "../../../utils";
import EmptyList from "../../components/EmptyList";
import FloatingBtn from "../../components/FloatingBtn";
import { DeleteIcon } from "../../components/Icons/DeleteIcon";
import { EditIcon } from "../../components/Icons/EditIcon";
import Loading from "../../components/Loading";
import { AlarmFilterAlarmType, AlarmFilterSendOption, NavHeaderAlarm } from "../../constants/mock";
import { TEXT_HOLDER } from "../../constants/utilities";
import { DEFAULT_SIZE } from "../../core/Layout/routes";
import AlarmModal from "./AlarmModal";
import "./index.scss";
import LeftSiteArea from "./LeftSiteArea";

const Alarm: React.FC = () => {
  const [pushNoti] = useNoti();
  const [getIndex, setGetIndex] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const [visibleSearch, setVisibleSearch] = useState<boolean>(false);
  const [anchorFilterEl, setAnchorFilterEl] = useState<HTMLElement | null>(null);
  const [checkedSendOptionsFilter, setCheckedSendOptionsFilter] = useState<string[]>([]);
  const [checkedAlarmTypeFilter, setCheckedAlarmTypeFilter] = useState<string[]>([]);
  const [site, setSite] = useState<{
    id: number | undefined;
    name: string | undefined;
  }>({
    id: undefined,
    name: undefined,
  });
  const [area, setArea] = useState<{
    id: number | undefined;
    name: string | undefined;
  }>({
    id: undefined,
    name: undefined,
  });
  const [selectTab, setSelectTab] = useState<string | undefined>(undefined);
  const [checkSumit, setCheckSumit] = useState<{ alarmType: string[]; sendOptions: string[] }>({
    alarmType: [],
    sendOptions: [],
  });
  const [openAlarmModal, setOpenAlarmModal] = useState<string | null>(null);
  const [anchorUserEl, setAnchorUserEl] = useState<HTMLElement | null>(null);
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const handleCloseUserMenu = () => {
    setAnchorUserEl(null);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorUserEl(event.currentTarget);
  };

  // eslint-disable-next-line
  const sendOptions: any = {
    notification: "Gửi thông báo",
    sms: "SMS",
  };

  const loadData = async (page: number, limit: number) => {
    setLoading(true);
    const resultAlarm: any = await autoLoadingPagination(
      AlarmService.getAllAlarm,
      {
        filter: {
          searchString: searchValue,
          areaId: area.id,
          alarmType:
            checkedAlarmTypeFilter?.length > 0 ? new Array(checkSumit.alarmType) : undefined,
          sendOptions:
            checkedSendOptionsFilter?.length > 0 ? new Array(checkSumit.sendOptions) : [],
          status: selectTab,
        },
      },
      page,
      limit,
      data && data?.returnData
    );

    if (resultAlarm?.returnData?.length) setData(resultAlarm);
    if (resultAlarm?.returnData?.length === 0) setData({ ...resultAlarm });
    setLoading(false);
  };

  const hasMore =
    data?.payload?.data?.length < data?.payload?.totalRecords &&
    data?.payload?.page < data?.payload?.totalPages;

  useEffect(() => {
    area.id !== undefined && loadData(1, 10);
  }, [searchValue, area.id, checkSumit, selectTab, openAlarmModal]);

  const onSearch = _.debounce((value) => {
    setSearchValue(value);
    value ? setVisibleSearch(true) : setVisibleSearch(false);
  }, 300);

  // eslint-disable-next-line
  const condition: any = {
    ">=": "Lớn hơn hoặc bằng",
    "<=": "Bé hơn hoặc bằng",
    "<=&&<": "Trong khoảng cao",
    "<&&<=": "Trong khoảng thấp",
  };

  const item = data?.returnData[getIndex];

  const deleteAlarm = async (id: number) => {
    try {
      const res = await AlarmService.deleteAlarm(id);
      if (res.status === 200 || res.status === 201) {
        pushNoti(AppNotifications.SUCCESS, "Xóa cảnh báo thành công");
        setTimeout(() => loadData(1, 10));
      }
    } catch (error) {
      pushNoti(AppNotifications.ERROR, "Xóa cảnh báo thất bại");
      console.log(error);
    }
  };

  return (
    <div className="alarm">
      <div className="alarm-content">
        <LeftSiteArea
          onChangeArea={(areaId: number | undefined, areaName: string | undefined) =>
            setArea({ id: areaId, name: areaName })
          }
          onChangeSite={(siteId: number | undefined, siteName: string | undefined) =>
            setSite({ id: siteId, name: siteName })
          }
        />
        <div className="content">
          <div className="action">
            <div className="alarm__nav-header">
              {NavHeaderAlarm.map((o, idx: number) => {
                return (
                  <div
                    key={o.id + idx}
                    className={
                      o.value === selectTab
                        ? "tab tab-active btn--shiny"
                        : `tab tab-hover ${o.value}`
                    }
                    onClick={() => {
                      setSelectTab(o.value);
                    }}>
                    {o.label}
                    <span>{data?.payload[`${o.quan}`]}</span>
                  </div>
                );
              })}
            </div>
            <div style={{ display: "flex" }}>
              <TextField
                placeholder="Tìm theo tên, mã cảnh báo...."
                variant="outlined"
                className={"search-style"}
                inputProps={{ className: "input" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="large" />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  onSearch(e.target.value);
                }}
              />
              <button
                className="btn-white"
                onClick={(event: React.MouseEvent<HTMLElement>) =>
                  setAnchorFilterEl(event.currentTarget)
                }>
                <TuneRoundedIcon style={{ fontSize: "2.2rem", marginRight: "1rem" }} />
                Chọn lọc
              </button>
            </div>
          </div>
          <div className="title">{site?.name}</div>
          <div className="list">
            <div className="list-header">
              <div className="ic"></div>
              <div className="id">Mã cảnh báo</div>
              <div className="opc">OPC Tag</div>
              <div className="alarm-type">Loại cảnh báo</div>
              <div className="condition-value">Điều kiện và giá trị</div>
              <div className="alarm">Hình thức cảnh báo</div>
            </div>
            {loading ? (
              <Loading style={{ margin: "2rem 0", height: "60vh" }} />
            ) : data?.returnData ? (
              data?.returnData?.length > 0 ? (
                <InfiniteScroll
                  useWindow={false}
                  pageStart={0}
                  initialLoad={false}
                  hasMore={hasMore}
                  loadMore={() => loadData(data.payload.page + 1, 10)}>
                  {data.fetching && searchValue && <Loading />}
                  {data.returnData?.map((alarm: AlarmOutputModal, index: number) => {
                    return (
                      <div
                        className={`item-list-page ${
                          alarm.isActive === true ? "active" : "inactive"
                        }`}
                        key={index}>
                        <div
                          className="ic"
                          onClick={(e) => {
                            handleOpenUserMenu(e);
                            setGetIndex(index);
                          }}>
                          <MoreHorizIcon fontSize="large" />
                        </div>
                        <div className="id">
                          <ThemeProvider theme={theme}>
                            <Tooltip title={<span>{alarm.code || TEXT_HOLDER}</span>}>
                              <span>{alarm.code || TEXT_HOLDER}</span>
                            </Tooltip>
                          </ThemeProvider>
                        </div>
                        <div className="opc">
                          <ThemeProvider theme={theme}>
                            <Tooltip title={<span>{alarm?.opcTag?.code || TEXT_HOLDER}</span>}>
                              <span>{alarm?.opcTag?.code || TEXT_HOLDER}</span>
                            </Tooltip>
                          </ThemeProvider>
                        </div>
                        <div className="alarm-type">
                          {/* {AlarmFilterAlarmType.find(
                          (f) => f.value === alarm.alarmSettings[0].alarmType
                        ) || TEXT_HOLDER} */}
                          {alarm.alarmSettings.map((a, index) => {
                            return (
                              <span key={index}>
                                &#9679;&ensp;{" "}
                                {AlarmFilterAlarmType.find((f) => f.value === a.alarmType)?.label}
                              </span>
                            );
                          }) || TEXT_HOLDER}
                        </div>
                        <div className="condition-value">
                          {alarm.alarmSettings.map((a, index) => {
                            return (
                              <span key={index}>
                                &#9679;&ensp; {/* eslint-disable-next-line*/}
                                {(condition[a.condition?.replace(/[0-9\.]/g, "")] || TEXT_HOLDER) +
                                  " " +
                                  (a.condition?.includes("&&")
                                    ? a.condition
                                        ?.replace(/&&/gi, " X ")
                                        .replace(/>=/gi, " ≥ ")
                                        .replace(/<=/gi, " ≤ ")
                                        .replace(/>/gi, " > ")
                                        .replace(/</gi, " < ")
                                    : a.condition?.replace(/<=|>=/gi, "") || TEXT_HOLDER)}
                              </span>
                            );
                          }) || TEXT_HOLDER}
                        </div>
                        <div className="alarm">
                          {alarm.alarmSettings.map((a, index) => {
                            return (
                              <span key={index}>
                                &#9679;&ensp;{" "}
                                {a.sendOptions
                                  ?.sort()
                                  .map((so) => sendOptions[so])
                                  .join(" & ") || "Không gửi thông báo"}
                              </span>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </InfiniteScroll>
              ) : visibleSearch === true ? (
                <EmptyList img={Search} title="Không tìm thấy kết quả phù hợp" />
              ) : (
                <EmptyList img={Ord} title="Chưa có dữ liệu" />
              )
            ) : (
              <EmptyList img={Ord} title="Chưa có dữ liệu" />
            )}
          </div>
        </div>
      </div>
      <FloatingBtn
        visible={false}
        onClickPrimary={() => {
          setOpenAlarmModal("create");
        }}
        btns={[]}
      />

      <Dialog fullScreen open={openAlarmModal !== null}>
        <AlarmModal
          closeForm={() => setOpenAlarmModal(null)}
          //eslint-disable-next-line
          site={{ id: site.id, name: site?.name }}
          area={area}
          data={openAlarmModal === "update" ? item : null}
        />
      </Dialog>

      <Popover
        open={Boolean(anchorFilterEl)}
        anchorEl={anchorFilterEl}
        onClose={() => {
          setAnchorFilterEl(null);
          setCheckedAlarmTypeFilter(checkSumit.alarmType as string[]);
          setCheckedSendOptionsFilter(checkSumit.sendOptions as string[]);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}>
        <div className="filter-style">
          <div className="title">Chọn lọc</div>
          <div
            className="close-icon"
            onClick={() => {
              setAnchorFilterEl(null);
              setCheckedAlarmTypeFilter(checkSumit.alarmType as string[]);
              setCheckedSendOptionsFilter(checkSumit.sendOptions as string[]);
            }}>
            <ClearIcon style={{ fontSize: "2.2rem", cursor: "pointer" }} />
          </div>
          <div className="lable">Loại cảnh báo</div>
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  checkedAlarmTypeFilter?.length === AlarmFilterAlarmType?.length ? true : false
                }
                value={checkedAlarmTypeFilter}
                onChange={() => {
                  checkedAlarmTypeFilter?.length > 0
                    ? setCheckedAlarmTypeFilter([])
                    : setCheckedAlarmTypeFilter(
                        AlarmFilterAlarmType.map((r) => {
                          return r.value;
                        })
                      );
                }}
                sx={{ "& .MuiSvgIcon-root": { fontSize: 28, color: "#41AD49" } }}
              />
            }
            label="Tất cả"
          />
          <Divider style={{ margin: "1rem 0" }} />
          <FormGroup>
            {AlarmFilterAlarmType.map((r, index: number) => {
              return (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      checked={checkedAlarmTypeFilter?.some((e) => e === r.value)}
                      value={r.value}
                      onChange={() => {
                        checkedAlarmTypeFilter?.some((e) => e === r.value)
                          ? setCheckedAlarmTypeFilter(
                              checkedAlarmTypeFilter.filter((e) => e !== r.value)
                            )
                          : setCheckedAlarmTypeFilter([...checkedAlarmTypeFilter, r.value]);
                      }}
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 28, color: "#41AD49" } }}
                    />
                  }
                  label={r.label}
                />
              );
            })}
          </FormGroup>
          <div className="lable">Hình thức cảnh báo</div>
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  checkedSendOptionsFilter?.length === AlarmFilterSendOption?.length ? true : false
                }
                value={checkedSendOptionsFilter}
                onChange={() => {
                  checkedSendOptionsFilter?.length > 0
                    ? setCheckedSendOptionsFilter([])
                    : setCheckedSendOptionsFilter(
                        AlarmFilterSendOption?.map((r) => {
                          return r.value;
                        })
                      );
                }}
                sx={{ "& .MuiSvgIcon-root": { fontSize: 28, color: "#41AD49" } }}
              />
            }
            label="Tất cả"
          />
          <FormGroup>
            {AlarmFilterSendOption?.map((r) => {
              return (
                <FormControlLabel
                  key={r.id}
                  control={
                    <Checkbox
                      checked={checkedSendOptionsFilter.some((e) => e === r.value)}
                      value={r.id}
                      onChange={() => {
                        checkedSendOptionsFilter?.some((e) => e === r.value)
                          ? setCheckedSendOptionsFilter(
                              checkedSendOptionsFilter.filter((e) => e !== r.value)
                            )
                          : setCheckedSendOptionsFilter([...checkedSendOptionsFilter, r.value]);
                      }}
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 28, color: "#41AD49" } }}
                    />
                  }
                  label={r.label}
                />
              );
            })}
          </FormGroup>
          <div style={{ display: "flex", justifyContent: "space-between", marginTop: "2rem" }}>
            <button
              className="btn-cancel-style"
              onClick={() => {
                setCheckedAlarmTypeFilter([]);
                setCheckedSendOptionsFilter([]);
                setCheckSumit({ alarmType: [], sendOptions: [] });
                setAnchorFilterEl(null);
              }}>
              Xóa lọc
            </button>
            <button
              className="btn-confirm-style"
              style={{ width: "18.1rem" }}
              onClick={() => {
                setCheckSumit({
                  alarmType: checkedAlarmTypeFilter,
                  sendOptions: checkedSendOptionsFilter,
                });
                setAnchorFilterEl(null);
              }}>
              Áp dụng
            </button>
          </div>
        </div>
      </Popover>

      <Popover
        open={Boolean(anchorUserEl)}
        anchorEl={anchorUserEl}
        onClose={handleCloseUserMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}>
        <div className="popover-menu-edit">
          <button
            className="btn-white"
            onClick={() => {
              handleCloseUserMenu();
              setOpenAlarmModal("update");
            }}>
            <EditIcon color={"#312F30"} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />
            &ensp;Chỉnh sửa
          </button>
          <Divider />
          <button
            className="btn-white"
            onClick={() => {
              handleCloseUserMenu();
              deleteAlarm(item?.id);
            }}>
            <DeleteIcon color={"#312F30"} size={DEFAULT_SIZE} viewBox={DEFAULT_SIZE} />
            &ensp;Xóa
          </button>
        </div>
      </Popover>
    </div>
  );
};

export default withProfile(Alarm);
