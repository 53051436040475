import {
  Clear as ClearIcon,
  DragIndicator as DragIndicatorIcon,
  Search as SearchIcon,
  TuneRounded as TuneRoundedIcon,
} from "@mui/icons-material";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  Popover,
  TextField,
  Tooltip,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useHistory } from "react-router-dom";
import Search from "../../../assets/images/img-search.png";
import Ord from "../../../assets/images/ordnull.png";
import Pig from "../../../assets/images/pig.png";
import { withProfile } from "../../../hoc/withProfile";
import { useFetchWithoutPagination } from "../../../hooks/useFetchWithoutPagination";
import { SetSitePosition, SiteStatus, TypeAnimal } from "../../../models";
import { SiteService } from "../../../services/site.service";
import { theme } from "../../../utils";
import { autoLoadingPagination, checkLinkImage, renderErrorImage } from "../../../utils/other.util";
import DndList from "../../components/DndList";
import EmptyList from "../../components/EmptyList";
import FloatingBtn from "../../components/FloatingBtn";
import Loading from "../../components/Loading";
import { SiteFilterStatus } from "../../constants/mock";
import { APP_ROUTES } from "../../constants/module";
import { TEXT_HOLDER } from "../../constants/utilities";
import "./index.scss";

const Site: React.FC = () => {
  const history = useHistory();
  const [anchorFilterEl, setAnchorFilterEl] = useState<HTMLElement | null>(null);
  const [checkedPetFilter, setCheckedPetFilter] = useState<number[]>([]);
  const [checkedStatusFilter, setCheckedStatusFilter] = useState<string[]>([]);
  const [checkSumit, setCheckSumit] = useState<{ status?: string[]; animalTypeIds?: number[] }>();
  const [searchValue, setSearchValue] = useState<string>("");
  const [visibleSearch, setVisibleSearch] = useState<boolean>(false);
  //eslint-disable-next-line
  const [dataSite, setDataSite] = useState<any>();

  const loadData = async (page: number, limit: number) => {
    // eslint-disable-next-line
    const resultAccount: any = await autoLoadingPagination(
      SiteService.getAllSite,
      {
        filter: {
          searchString: searchValue,
          status: checkedStatusFilter?.length > 0 ? new Array(checkSumit?.status) : undefined,
          animalTypeIds:
            checkedPetFilter?.length > 0 ? new Array(checkSumit?.animalTypeIds) : undefined,
          isAdmin: true,
        },
      },
      page,
      limit,
      dataSite && dataSite?.returnData
    );

    if (resultAccount?.returnData?.length) setDataSite(resultAccount);
    if (resultAccount?.returnData?.length === 0) setDataSite({ ...resultAccount });
  };

  const hasMore =
    dataSite?.payload?.data?.length < dataSite?.payload?.totalRecords &&
    dataSite?.payload?.page < dataSite?.payload?.totalPages;

  useEffect(() => {
    loadData(1, 10);
    // eslint-disable-next-line
  }, [searchValue, checkSumit?.status, checkSumit?.animalTypeIds]);

  const onSearch = _.debounce((value) => {
    setSearchValue(value);

    value ? setVisibleSearch(true) : setVisibleSearch(false);
  }, 300);

  const { data: animalTypeData } = useFetchWithoutPagination(SiteService.getType);

  const siteSetPosition = async (data: SetSitePosition) => {
    try {
      await SiteService.setSitePosition(data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="site">
      <div className="site-content">
        <div className="action">
          <div className="title">Tất cả ({dataSite?.payload?.totalRecords})</div>
          <div style={{ display: "flex" }}>
            <TextField
              placeholder="Tìm theo tên, mã trại...."
              variant="outlined"
              className={"search-style"}
              inputProps={{ className: "input" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="large" />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                onSearch(e.target.value);
              }}
            />
            <button
              className="btn-white"
              onClick={(event: React.MouseEvent<HTMLElement>) =>
                setAnchorFilterEl(event.currentTarget)
              }>
              <TuneRoundedIcon style={{ fontSize: "2.2rem", marginRight: "1rem" }} />
              Chọn lọc
            </button>
          </div>
        </div>
        <div className="list">
          <div className="list-header">
            <div className="ic"></div>
            <div className="id">OPC ID</div>
            <div className="name">Tên trại</div>
            <div className="pet">Loại vật nuôi</div>
            <div className="status">Tình trạng</div>
            <div className="address">Địa chỉ</div>
            <div className="size">Quy mô</div>
            <div className="quantity">Chuồng</div>
          </div>
          {dataSite?.loading ? (
            <Loading style={{ margin: "2rem 0", height: "60vh" }} />
          ) : dataSite?.returnData ? (
            dataSite?.returnData?.length > 0 ? (
              <InfiniteScroll
                useWindow={false}
                pageStart={0}
                initialLoad={false}
                hasMore={hasMore}
                loadMore={() => loadData(dataSite.payload.page + 1, 10)}>
                {dataSite.fetching && searchValue && <Loading />}
                <DndList
                  data={dataSite?.returnData}
                  //eslint-disable-next-line
                  renderItem={(item: any) => (
                    <div
                      className="item-list-page"
                      onClick={() => {
                        history.push(APP_ROUTES.SITE_DETAIL.replace(":siteId", item.id.toString()));
                      }}>
                      <div className="ic">
                        <DragIndicatorIcon fontSize="large" />
                      </div>
                      <div className="id">{item.code || TEXT_HOLDER}</div>
                      <div className="name">
                        <img
                          src={checkLinkImage(item?.avatar as string) || Pig}
                          alt="avt"
                          onError={renderErrorImage()}
                        />
                        {item.name || TEXT_HOLDER}
                      </div>
                      <div className="pet">{item.animaltypename || TEXT_HOLDER}</div>
                      <div className="status">
                        {item?.status === SiteStatus.OFFICIAL ? (
                          <span className="status" style={{ color: "#41AD49" }}>
                            &#9679;&ensp;Chính thức
                          </span>
                        ) : (
                          <span className="status" style={{ color: "#B8292F" }}>
                            &#9679;&ensp;Chưa chính thức
                          </span>
                        )}
                      </div>
                      <div className="address">
                        <ThemeProvider theme={theme}>
                          <Tooltip title={<span>{item.address || TEXT_HOLDER}</span>}>
                            <span>{item.address || TEXT_HOLDER}</span>
                          </Tooltip>
                        </ThemeProvider>
                      </div>
                      <div className="size">{item.volume ? item.volume + " con" : TEXT_HOLDER}</div>
                      <div className="quantity">
                        {/*eslint-disable-next-line*/}
                        {item.quantities.reduce((sum: any, val: any) => {
                          return (sum += val.quantity);
                        }, 0)}
                      </div>
                    </div>
                  )}
                  //eslint-disable-next-line
                  onChange={() => {}}
                  itemPositionChange={(data) =>
                    siteSetPosition({ position: data.position, siteId: +data.id, isAdmin: true })
                  }
                />
              </InfiniteScroll>
            ) : visibleSearch === true ? (
              <EmptyList img={Search} title="Không tìm thấy kết quả phù hợp" />
            ) : (
              <EmptyList img={Ord} title="Chưa có dữ liệu" />
            )
          ) : (
            <EmptyList img={Ord} title="Chưa có dữ liệu" />
          )}
        </div>
      </div>

      <FloatingBtn
        visible={false}
        onClickPrimary={() => {
          history.push(APP_ROUTES.SITE_CREATE);
        }}
        btns={[]}
      />

      <Popover
        open={Boolean(anchorFilterEl)}
        anchorEl={anchorFilterEl}
        onClose={() => {
          setAnchorFilterEl(null);
          setCheckedStatusFilter((checkSumit?.status as string[]) || []);
          setCheckedPetFilter((checkSumit?.animalTypeIds as number[]) || []);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}>
        <div className="filter-style">
          <div className="title">Chọn lọc</div>
          <div
            className="close-icon"
            onClick={() => {
              setAnchorFilterEl(null);
              setCheckedStatusFilter(checkSumit?.status as string[]);
              setCheckedPetFilter(checkSumit?.animalTypeIds as number[]);
            }}>
            <ClearIcon style={{ fontSize: "2.2rem", cursor: "pointer" }} />
          </div>
          <div className="lable">Tình trạng</div>
          <FormControlLabel
            control={
              <Checkbox
                checked={checkedStatusFilter?.length === SiteFilterStatus?.length ? true : false}
                value={checkedStatusFilter}
                onChange={() => {
                  checkedStatusFilter?.length > 0
                    ? setCheckedStatusFilter([])
                    : setCheckedStatusFilter(
                        SiteFilterStatus.map((r) => {
                          return r.value;
                        })
                      );
                }}
                sx={{ "& .MuiSvgIcon-root": { fontSize: 28, color: "#41AD49" } }}
              />
            }
            label="Tất cả"
          />
          <Divider style={{ margin: "1rem 0" }} />
          <FormGroup>
            {SiteFilterStatus.map((r) => {
              return (
                <FormControlLabel
                  key={r.id}
                  control={
                    <Checkbox
                      checked={checkedStatusFilter?.some((e) => e === r.value)}
                      value={r.value}
                      onChange={() => {
                        checkedStatusFilter?.some((e) => e === r.value)
                          ? setCheckedStatusFilter(
                              checkedStatusFilter?.filter((e) => e !== r.value)
                            )
                          : setCheckedStatusFilter([...checkedStatusFilter, r.value]);
                      }}
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 28, color: "#41AD49" } }}
                    />
                  }
                  label={r.label}
                />
              );
            })}
          </FormGroup>
          <div className="lable">Loại vật nuôi</div>
          <FormControlLabel
            control={
              <Checkbox
                checked={checkedPetFilter?.length === animalTypeData?.length ? true : false}
                value={checkedPetFilter}
                onChange={() => {
                  checkedPetFilter?.length > 0
                    ? setCheckedPetFilter([])
                    : setCheckedPetFilter(
                        animalTypeData?.map((r: TypeAnimal) => {
                          return r.id;
                        })
                      );
                }}
                sx={{ "& .MuiSvgIcon-root": { fontSize: 28, color: "#41AD49" } }}
              />
            }
            label="Tất cả"
          />
          <Divider style={{ margin: "1rem 0" }} />
          <FormGroup>
            {animalTypeData?.map((r: TypeAnimal) => {
              return (
                <FormControlLabel
                  key={r.id}
                  control={
                    <Checkbox
                      checked={checkedPetFilter?.some((e) => e === r.id)}
                      value={r.id}
                      onChange={() => {
                        checkedPetFilter?.some((e) => e === r.id)
                          ? setCheckedPetFilter(checkedPetFilter.filter((e) => e !== r.id))
                          : setCheckedPetFilter([...checkedPetFilter, r.id]);
                      }}
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 28, color: "#41AD49" } }}
                    />
                  }
                  label={r.name}
                />
              );
            })}
          </FormGroup>
          <div style={{ display: "flex", justifyContent: "space-between", marginTop: "2rem" }}>
            <button
              className="btn-cancel-style"
              onClick={() => {
                setCheckedStatusFilter([]);
                setCheckedPetFilter([]);
                setCheckSumit({ status: undefined, animalTypeIds: undefined });
                setAnchorFilterEl(null);
              }}>
              Xóa lọc
            </button>
            <button
              className="btn-confirm-style"
              style={{ width: "18.1rem" }}
              onClick={() => {
                setCheckSumit({ status: checkedStatusFilter, animalTypeIds: checkedPetFilter });
                setAnchorFilterEl(null);
              }}>
              Áp dụng
            </button>
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default withProfile(Site);
