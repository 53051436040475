import {
  Clear as ClearIcon,
  DragIndicator as DragIndicatorIcon,
  Search as SearchIcon,
  TuneRounded as TuneRoundedIcon,
} from "@mui/icons-material";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  Popover,
  TextField,
} from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useHistory, useLocation } from "react-router-dom";
import Search from "../../../assets/images/img-search.png";
import Ord from "../../../assets/images/ordnull.png";
import Pig from "../../../assets/images/pig.png";
import { withProfile } from "../../../hoc/withProfile";
import { useFetchWithoutPagination } from "../../../hooks/useFetchWithoutPagination";
import { SetAreaPosition, TypeArea } from "../../../models";
import { AreaService } from "../../../services";
import { autoLoadingPagination, checkLinkImage, renderErrorImage } from "../../../utils/other.util";
import DndList from "../../components/DndList";
import EmptyList from "../../components/EmptyList";
import FloatingBtn from "../../components/FloatingBtn";
import Loading from "../../components/Loading";
import { APP_ROUTES } from "../../constants/module";
import { TEXT_HOLDER } from "../../constants/utilities";
import "./index.scss";
import LeftSite from "./LeftSite";

const Area: React.FC = () => {
  //eslint-disable-next-line
  const location = useLocation<any>();
  const history = useHistory();
  const [anchorFilterEl, setAnchorFilterEl] = useState<HTMLElement | null>(null);
  const [checkedFilter, setCheckedFilter] = useState<number[]>([]);
  const [siteId, setSiteId] = useState<number | undefined>(undefined);
  const [searchValue, setSearchValue] = useState<string>("");
  const [areaTypeIds, setAreaTypeIds] = useState<number[]>([]);
  const [visibleSearch, setVisibleSearch] = useState<boolean>(false);
  //eslint-disable-next-line
  const [dataArea, setDataArea] = useState<any>();

  const loadData = async (page: number, limit: number) => {
    // eslint-disable-next-line
    const resultAccount: any = await autoLoadingPagination(
      AreaService.getAllArea,
      {
        filter: {
          searchString: searchValue,
          siteId: siteId !== undefined ? siteId : undefined,
          areaTypeIds: areaTypeIds?.length > 0 ? new Array(areaTypeIds) : undefined,
          isAdmin: true,
        },
      },
      page,
      limit,
      dataArea && dataArea?.returnData
    );

    if (resultAccount?.returnData?.length) setDataArea(resultAccount);
    if (resultAccount?.returnData?.length === 0) setDataArea({ ...resultAccount });
  };

  //@TODO Chưa lấy lại danh sách mới khi sắp xếp xong
  const hasMore =
    dataArea?.payload?.data?.length < dataArea?.payload?.totalRecords &&
    dataArea?.payload?.page < dataArea?.payload?.totalPages;

  useEffect(() => {
    loadData(1, 10);
    // eslint-disable-next-line
  }, [searchValue, siteId, areaTypeIds]);

  useEffect(() => {
    location.state?.siteId !== undefined && setSiteId(location.state?.siteId);
  }, [location]);

  const onSearch = _.debounce((value) => {
    setSearchValue(value);

    value ? setVisibleSearch(true) : setVisibleSearch(false);
  }, 300);

  const { data: typeAreaData } = useFetchWithoutPagination(AreaService.getAreaType);

  const setAreaPosition = async (data: SetAreaPosition) => {
    try {
      await AreaService.setAreaPosition(data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="area">
      <div className="area-content">
        <LeftSite
          siteId={siteId}
          onChangeSite={(siteId: number | undefined) => setSiteId(siteId)}
        />
        <div className="content">
          <div className="action">
            <div className="title">Tất cả ({dataArea?.payload?.totalRecords})</div>
            <div style={{ display: "flex" }}>
              <TextField
                placeholder="Tìm theo tên, mã chuồng...."
                variant="outlined"
                className={"search-style"}
                inputProps={{ className: "input" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="large" />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  onSearch(e.target.value);
                }}
              />
              <button
                className="btn-white"
                onClick={(event: React.MouseEvent<HTMLElement>) =>
                  setAnchorFilterEl(event.currentTarget)
                }>
                <TuneRoundedIcon style={{ fontSize: "2.2rem", marginRight: "1rem" }} />
                Chọn lọc
              </button>
            </div>
          </div>
          <div className="list">
            <div className="list-header">
              <div className="ic"></div>
              <div className="area">Chuồng</div>
              <div className="type">Loại chuồng</div>
              <div className="slot">OPC Slot</div>
              <div className="device">Thiết bị</div>
              <div className="pet">Số lượng</div>
              <div className="status">Trạng thái</div>
            </div>
            {dataArea?.loading ? (
              <Loading style={{ margin: "2rem 0", height: "60vh" }} />
            ) : dataArea?.returnData ? (
              dataArea?.returnData?.length > 0 ? (
                <InfiniteScroll
                  useWindow={false}
                  pageStart={0}
                  initialLoad={false}
                  hasMore={hasMore}
                  loadMore={() => loadData(dataArea?.payload.page + 1, 10)}>
                  {dataArea?.fetching && searchValue && <Loading />}

                  <DndList
                    data={dataArea?.returnData}
                    //eslint-disable-next-line
                    renderItem={(item: any) => (
                      <div
                        className="item-list-page"
                        onClick={() => {
                          history.push({
                            pathname: APP_ROUTES.AREA_DETAIL.replace(":areaId", item.id.toString()),
                            state: {
                              siteId: siteId,
                            },
                          });
                        }}>
                        <div className="ic">
                          <DragIndicatorIcon fontSize="large" />
                        </div>
                        <div className="area">
                          <img
                            src={checkLinkImage(item.avatar as string) || Pig}
                            alt="avt-pig"
                            onError={renderErrorImage()}
                          />
                          <div>
                            {item.name}
                            <div className="code">{item.code || TEXT_HOLDER}</div>
                          </div>
                        </div>
                        <div className="type">{item.areaType.name || TEXT_HOLDER}</div>
                        <div className="slot">{item.OPCSlot || TEXT_HOLDER}</div>
                        <div className="device">{item.devices || TEXT_HOLDER}</div>
                        <div className="pet">{item.volume || TEXT_HOLDER}</div>
                        <div className="status">
                          {item?.status === "active" ? (
                            <span className="status" style={{ color: "#41AD49" }}>
                              &#9679;&ensp;Đang hoạt động
                            </span>
                          ) : (
                            <span className="status" style={{ color: "#B8292F" }}>
                              &#9679;&ensp;Ngưng hoạt động
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                    //eslint-disable-next-line
                    onChange={(data) =>
                      setAreaPosition({
                        areaIds: Array.from(new Set(data.map((item) => +item.id))),
                        siteId: siteId,
                        isAdmin: true,
                      })
                    }
                  />
                </InfiniteScroll>
              ) : visibleSearch === true ? (
                <EmptyList img={Search} title="Không tìm thấy kết quả phù hợp" />
              ) : (
                <EmptyList img={Ord} title="Chưa có dữ liệu" />
              )
            ) : (
              <EmptyList img={Ord} title="Chưa có dữ liệu" />
            )}
          </div>
        </div>
      </div>

      <FloatingBtn
        visible={false}
        onClickPrimary={() => {
          history.push(APP_ROUTES.AREA_CREATE);
        }}
        btns={[]}
      />

      <Popover
        open={Boolean(anchorFilterEl)}
        anchorEl={anchorFilterEl}
        onClose={() => {
          setAnchorFilterEl(null);
          setCheckedFilter(areaTypeIds || []);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}>
        <div className="filter-style">
          <div className="title">Chọn lọc</div>
          <div
            className="close-icon"
            onClick={() => {
              setAnchorFilterEl(null);
              setCheckedFilter(areaTypeIds);
            }}>
            <ClearIcon style={{ fontSize: "2.2rem", cursor: "pointer" }} />
          </div>
          <div className="lable">Loại chuồng</div>
          <FormControlLabel
            control={
              <Checkbox
                checked={checkedFilter?.length === typeAreaData?.length ? true : false}
                value={checkedFilter}
                onChange={() => {
                  checkedFilter?.length > 0
                    ? setCheckedFilter([])
                    : setCheckedFilter(
                        typeAreaData?.map((r: TypeArea) => {
                          return r.id;
                        })
                      );
                }}
                sx={{ "& .MuiSvgIcon-root": { fontSize: 28, color: "#41AD49" } }}
              />
            }
            label="Tất cả"
          />
          <Divider style={{ margin: "1rem 0" }} />
          <FormGroup>
            {typeAreaData?.map((r: TypeArea, idx: number) => {
              return (
                <FormControlLabel
                  key={idx}
                  control={
                    <Checkbox
                      checked={checkedFilter.some((e) => e === r.id)}
                      value={r.id}
                      onChange={() => {
                        checkedFilter.some((e) => e === r.id)
                          ? setCheckedFilter(checkedFilter.filter((e) => e !== r.id))
                          : setCheckedFilter([...checkedFilter, r.id]);
                      }}
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 28, color: "#41AD49" } }}
                    />
                  }
                  label={r.name}
                />
              );
            })}
          </FormGroup>
          <div style={{ display: "flex", justifyContent: "space-between", marginTop: "2rem" }}>
            <button
              className="btn-cancel-style"
              onClick={() => {
                setCheckedFilter([]);
                setAreaTypeIds([]);
                setAnchorFilterEl(null);
              }}>
              Xóa lọc
            </button>
            <button
              className="btn-confirm-style"
              style={{ width: "18.1rem" }}
              onClick={() => {
                setAreaTypeIds(checkedFilter);
                setAnchorFilterEl(null);
              }}>
              Áp dụng
            </button>
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default withProfile(Area);
