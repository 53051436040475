import React from "react";
import { Route, Switch } from "react-router";
import { BrowserRouter as Router, Redirect } from "react-router-dom";
import Loading from "./app/components/Loading";
import ReponsiveMobile from "./app/components/ReponsiveMobile";
import { APP_ROUTES } from "./app/constants/module";
import { authRoute } from "./app/containers/Authentication/authRoutes";
import NotFound from "./app/containers/NotFound";
import PrivateRoute from "./app/core/auth/PrivateRoute";
import Layout from "./app/core/Layout";
import { appRoutes } from "./app/core/Layout/routes";
import { useBreakpoints } from "./hooks/useBreakpoints";

const LoginForm = React.lazy(() => import("./app/containers/Authentication/SignInForm"));

function App() {
  const breakpoints = useBreakpoints();
  return (
    <React.Suspense fallback={<Loading />}>
      {breakpoints.md && (
        <Router>
          <Switch>
            <Route exact path={APP_ROUTES.LOGIN} component={LoginForm} />
            <Switch>
              {authRoute.map((route, index) => {
                return <Route key={index} {...route} />;
              })}
              <PrivateRoute>
                <Layout>
                  <Route
                    exact
                    path={APP_ROUTES.HOME}
                    render={() => <Redirect to={APP_ROUTES.SITE} />}
                  />
                  <Switch>
                    {appRoutes.map((route, index) => {
                      return <Route key={index} {...route} />;
                    })}
                    <Route component={NotFound} />
                  </Switch>
                </Layout>
              </PrivateRoute>
            </Switch>
          </Switch>
        </Router>
      )}
      {breakpoints.xs && !!breakpoints.md !== true ? <ReponsiveMobile /> : ""}
    </React.Suspense>
  );
}

export default App;
